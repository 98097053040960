import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import "./coursedetails.css";
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
const CourseDetails = () => {
  const navigate = useNavigate();
  const [enrollment, setenrollment] = useState('');
  let token = localStorage.getItem('accessToken') ?? false;
    const courseenroll =(e) =>{
      e.preventDefault();

    let getRes = (res) => {
      if (res.statusCode === 200) {
        setenrollment(res.data);
        // console.log(res.data.clientSecret);
        // console.log(res.data.paymentIntentId);
        navigate('/courseenrollpayment', { state: { clientSecret: res.data.clientSecret, paymentIntent: res.data.paymentIntentId } });
      } else{
        alert(res?.message);
      }
    };

    callApi(
      'POST',
      routes.courseEnrollment+'/'+courseId,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log('error', error);
      }
    );
  };
  const [isloading, setIsLoading] = useState(false);
  const [coursedetail, setcoursedetail] = useState([]);
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get("courseId");
    const getCourseDetail = () => {
      
      let getRes = (res) => {
        //  localStorage.setItem("userData", res?.data?.user);
        setcoursedetail(res.courseInfo);
        // setproducts(res.data.data.products);
      //   console.log(productId)
      };
  
      callApi("GET", routes.coursedetails+'/'+courseId, null, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
    };
    useEffect(() => {
      if (courseId) {
          getCourseDetail();
      }
    }, [courseId]);
      // const getSocialMediaIcon = (url) => {
      //   if (url.includes('facebook.com')) {
      //     return <i className="fa-brands fa-facebook fa-xl" style={{ marginRight: '15px' }}></i>;
      //   } else if (url.includes('twitter.com')) {
      //     return <i className="fa-brands fa-twitter fa-xl" style={{ marginRight: '15px' }}></i>;
      //   } else if (url.includes('instagram.com')) {
      //     return <i className="fa-brands fa-instagram fa-xl" style={{ marginRight: '15px' }}></i>;
      //   } else if (url.includes('whatsapp.com')) {
      //     return <i className="./img/courses/coursedetails/Frame (2).png" style={{ marginRight: '15px' }}></i>;
      //   } else {
      //     return <i className="fa-solid fa-globe fa-xl" style={{ marginRight: '15px' }}></i>;
      //   }
      // };
      
      // const SocialMediaLinks = ({ links }) => (
      //   <div>
      //     {links?.map((link, index) => (
      //       <p key={index}>
      //         {getSocialMediaIcon(link)}
      //         {link}
      //       </p>
      //     ))}
      //   </div>
      // );
      const splitTitle = (title, charLimit) => {
        if (!title) return ['', ''];
      
        // Find the position to split the title without cutting words
        let splitIndex = charLimit;
      
        if (title.length > charLimit) {
          while (splitIndex > 0 && title[splitIndex] !== ' ') {
            splitIndex--;
          }
      
          if (splitIndex === 0) {
            // If no space is found, fall back to the charLimit
            splitIndex = charLimit;
          }
        }
      
        const firstPart = title.substring(0, splitIndex);
        const secondPart = title.substring(splitIndex).trim();
      
        return [firstPart, secondPart];
      };
      const [firstPart, secondPart] = splitTitle(coursedetail?.title, 25);
      function decodeHtml(html) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
      }
  return (
    <>
    <div className="container-fluid">
    <div className="row my-4">
    <div className="col-md-5 col-12 ">
    <div className="banner-container">
      <h1 className="banner-employes">{firstPart}</h1>
      <div className="banner-employes-underline"></div>
    </div>
    <h5 className="banner-employes">{secondPart}</h5>
  </div>
<div className="col-md-5 col-12 mx-auto mt-4">
<span className="text-subheading "  dangerouslySetInnerHTML={{ __html: decodeHtml(coursedetail?.description) }}></span>

</div>
</div>

</div>
<div  className="blog-page">
    <div className="container-fluid">
        <div className="row clearfix">
            <div className="col-lg-7 col-md-7 col-12 left-box">
                <div className=" single_post ">
                    <div className="">
                    <div className="px-md-4 coursedetailsmidborder">
                    <div className="img-post">
    <img className="d-block img-fluid w-100 rounded-4" src={coursedetail?.thumbnail} style={{height:'300px'}}/>
</div>
<div className="d-flex justify-content-between eighteenfont my-3 "><span  style={{ textDecoration: 'none',color:'black' }}><b>{coursedetail?.title}</b></span>
<div>
<span className="badge py-2" style={{ fontSize: '14px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {coursedetail?.level}
                                        </span>
</div>
                                        </div>
                                        <div className="d-flex justify-content-between p-price mt-3"><span><b>${coursedetail?.price}</b></span><span><b>{coursedetail?.courseDuration}</b></span></div>
                                        <div className="d-flex flex-wrap justify-content-start my-4 border-bottom">
      <div className='col-md-5  my-2'>
        <div className="d-flex justify-content-start">
        <i className="fa-solid fa-users fa-lg mt-2"></i>
        <p className='mx-3 text-low'>{coursedetail?.totalStudents} Students</p>
        </div>
      </div>
      <div className='col-md-5  my-2'>
        <div className="d-flex justify-content-start">
        <i className="fa-solid fa-clock fa-lg  mt-2"></i>
        <p className='mx-3 text-low'>{coursedetail?.courseDuration}</p>
        </div>
      </div>
    </div>
    <div className='border-bottom my-2'>
        <h6 className='eighteenfont'><b>Course Description</b></h6>
        <p className='text-low my-3' dangerouslySetInnerHTML={{ __html: decodeHtml(coursedetail?.description) }}></p>
    </div>
    <div className='border-bottom my-2'>
        <h6 className='eighteenfont my-3'><b>Course Objective</b></h6>
       <p className='text-low ' dangerouslySetInnerHTML={{ __html: decodeHtml(coursedetail?.objective) }}></p>
    </div>
    <div className='border-bottom my-4 pb-2'>
        <h6 className='eighteenfont'><b>Digital Book</b></h6>
        {coursedetail?.books && coursedetail?.books.map((book, index) => (<div className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
                      <a className="me-3" href="#">
                        <img className="rounded shadow-1-strong mb-3"
                          src="./img/courses/coursedetails/digitalbook.png" alt="avatar"
                          width="60" height="60" />
                      </a>
                      <div className="flex-grow-1 flex-shrink-1">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1">
                            <b>{book?.bookTitle}</b>
                            </p>
                          </div>
                          <span><b>${book?.bookPrice}</b></span>
                        </div>
                      </div>
                    </div>
                    <div className='mt-1'>
                    {/* <button className='btn rounded-pill px-5 py-2 shadow' onClick={courseenroll} style={{backgroundColor:'#1D191C', color:'white'}}>Buy Now</button> */}
                    </div>
            </div>))}

    </div>
                    </div>
    <div className='border-bottom my-3'>
        <h6 className='eighteenfont'><b>Course Instructor</b></h6>
        <div className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
                      <a className="me-3" href="#">
                        <img className="rounded-circle shadow-1-strong mb-3"
                          src={coursedetail?.instructor?.avatar} alt="avatar"
                          width="60" height="60" />
                      </a>
                      <div className="flex-grow-1 flex-shrink-1">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1">
                            <b>{coursedetail?.instructor?.fullName}</b>
                            </p>
                          </div>
                          <span className='text-low'>{coursedetail?.instructor?.description}</span>
                        </div>
                      </div>
                    </div>
                    {/* <div className='mt-2 mx-4'>
                    <i className="fa-solid fa-comment-dots fa-xl"></i>
                    </div> */}
            </div>

    </div>
    <div className='border-bottom my-3'>
        <h6 className='eighteenfont'><b>Course Assignment</b></h6>
        <div className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
                      <a className="me-3" href="#">
                        <img className="rounded shadow-1-strong mb-3"
                          src="./img/courses/coursedetails/courseassignment.png" alt="avatar"
                          width="60" height="60" />
                      </a>
                      
                            <p className="my-3">
                            <b>{coursedetail?.title}</b>
                            </p>
                          
                    </div>
                    
            </div>

    </div>
    <div className='border-bottom my-3'>
        <h6 className='eighteenfont'><b>Course Quiz</b></h6>
        <div className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
                      <a className="me-3" href="#">
                        <img className="rounded shadow-1-strong mb-3"
                          src="./img/courses/coursedetails/coursequiz.png" alt="avatar"
                          width="60" height="60" />
                      </a>
                      
                            <p className="my-3">
                            <b>{coursedetail?.title}</b>
                            </p>
                          
                    </div>
                    
            </div>

    </div>
    <div className=' my-4'>
        <h6 className='eighteenfont'><b>Course Content</b></h6>
        {coursedetail?.content && coursedetail?.content.map((content, index) => (<div key={index} className="my-3 py-2 shadow rounded-2">
    <div className="d-flex justify-content-between align-items-center px-3 py-1">
        <div className="d-flex flex-start">
            <p className="m-0"><b>{content?.contentTitle}</b></p>
        </div>
        <div>
        <i className="fa-solid fa-circle-play fa-lg"></i>
        </div>
    </div>
</div>))}





    </div>
    {token ?<div className='mt-5 mb-5 col-md-6'>
                    <button className='btn rounded-pill px-5 py-3 shadow w-100' onClick={courseenroll} style={{backgroundColor:'#1D191C', color:'white'}}>Enroll Course</button>
                    </div> : <div className='mt-5 mb-5 col-md-6'>
                    <button className='btn btn-secondary rounded-pill px-5 py-3 shadow w-100'  style={{ color:'white'}}>Logged In to Enroll Course</button>
                    </div>}
                    </div>                        
                </div>
                
                
            </div>
        <div className="col-lg-5 col-md-5 col-12">
        <section className="" >
  <div className="container-fluid">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="">

        
          <div className=" text-center">
            <div className="mt-3 mb-2">
              <img src={coursedetail?.instructor?.avatar}
                className="rounded-circle img-fluid" height='80' width='80' />
            </div>
            <h6 className=""><b>{coursedetail?.instructor?.fullName}</b></h6>
            <p className='text-secondary'>{coursedetail?.instructor?.description}</p>
            <div className="d-flex justify-content-center text-center mt-3 mb-2 text-secondary">
              <div className="px-3 " style={{ borderRight:"1px solid #E2E2E2"}}>
                <p className="mb-2 h5">{coursedetail?.instructor?.totalCourses}</p>
                <p className="text-muted mb-0">Courses</p>
              </div>
              <div className="px-3">
                <p className="mb-2 h5">{coursedetail?.instructor?.totalStudentsInAllCourses}</p>
                <p className="text-muted mb-0">Students</p>
              </div>
             
            </div>
           
            <div className="col-md-7 mx-auto mb-3 ">
            {token ? <Link to={`/userinbox?instructorId=${coursedetail?.instructor?._id}`} type="button" className="btn btn-secondary rounded-pill btn-info btn-lg w-100 py-3 shadow" style={{ fontSize: '16px', fontWeight: '500' }}>
    Chat with Instructor
</Link> : <button type="button" className="btn btn-secondary rounded-pill  btn-lg w-100 py-3 shadow" style={{ fontSize: '16px', fontWeight: '500' }}>
    Logged In for Chat
</button>}
            </div>
            
          </div>
          <div className='mt-4'>
          <span className='eighteenfont '><b>About Instructor</b></span>
          </div>
          <p className='text-low my-3'>{coursedetail?.instructor?.description}</p>
          <div className='my-4' style={{color:'#1D191C'}}>
          {/* <SocialMediaLinks links={coursedetail?.instructor?.socialMediaLinks} /> */}
        {coursedetail?.instructor?.socialMediaLinks?.[0]?.whatsApp && <p> <img src="./img/courses/coursedetails/Frame (2).png" style={{ marginRight: '15px' }}></img> {coursedetail?.instructor?.socialMediaLinks?.[0]?.whatsApp}</p>}
        {coursedetail?.instructor?.socialMediaLinks?.[0]?.facebook && <p> <i className="fa-brands fa-facebook fa-xl" style={{ marginRight: '15px' }}></i> {coursedetail?.instructor?.socialMediaLinks?.[0]?.facebook}</p>}
        {coursedetail?.instructor?.socialMediaLinks?.[0]?.twitter && <p> <i className="fa-brands fa-twitter fa-xl" style={{ marginRight: '15px' }}></i> {coursedetail?.instructor?.socialMediaLinks?.[0]?.twitter}</p>}
        {coursedetail?.instructor?.socialMediaLinks?.[0]?.instagram && <p> <i className="fa-brands fa-instagram fa-xl" style={{ marginRight: '15px' }}></i> {coursedetail?.instructor?.socialMediaLinks?.[0]?.instagram}</p>}
        {coursedetail?.instructor?.socialMediaLinks?.[0]?.website && <p> <i className="fa-solid fa-globe fa-xl" style={{ marginRight: '15px' }}></i> {coursedetail?.instructor?.socialMediaLinks?.[0]?.website}</p>}

          </div>
          <span className='eighteenfont '><b>Courses</b></span>
          
            <div className=" my-3">
    <div className="col-md-10">
    {coursedetail?.instructor?.instructorCourses && coursedetail?.instructor?.instructorCourses.map((course, index) => (
    <div className="d-flex justify-content-center  mt-3 ">
        <div className="card p-3 bg-white border-0 shadow rounded-4 d-flex flex-row align-items-center w-100">
            <div className="about-product text-center">
            <img className=' rounded-4' src={course?.thumbnail} width="80" style={{height:'80px'}} />
            </div>
            <div className="stats  flex-grow-1">
                <div className="px-3">
                <div className="d-flex justify-content-between p-price ">
                    <Link to={{
            pathname: "/coursedetails",
            search: `?courseId=${course?._id}`, 
          }} style={{ textDecoration: 'none', color:'black' }}>
                        <h6 className="mb-0"><b>{course?.title}</b></h6>
                    </Link>
                    <div>
                    <span className="badge py-2" style={{ fontSize: '10px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', padding: '5px 10px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10px' }}>
                    {course?.level}
                    </span>
                    </div>
                    </div>
                    <div className="d-flex justify-content-between p-price mt-3">
                    {/* <del style={{fontSize:'12px'}}>${course?.price+10}</del> span */}
                        <span style={{fontSize:'13px'}}><b>${course?.price}</b></span>
                        <span style={{fontSize:'14px'}}><b>3 Month Course</b></span>
                    </div>
                </div>
            </div>
        </div>
    </div>))}
    
    </div>

          

        </div>

      </div>
    </div>
  </div>
</section>
        </div>
        </div>
    </div>
</div>

    </>
  )
}

export default CourseDetails