import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';

const PopularCourses = () => {
  const [courses, setcourses] = useState([]);
  const [isloading, setIsLoading] = useState(false);

    const getCourses = () => {
    
        let getRes = (res) => {
          //  localStorage.setItem("userData", res?.data?.user);
          setcourses(res.data.docs);
        //   console.log(res.data.docs)
        };
    
        callApi("GET", routes.courses, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
    
        getCourses();
      }, []);
      const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setFilteredProducts(courses);
  }, [courses]);
  const handleSearch = (query) => {
    if (query.trim() === '') {
      // If search query is empty, show all products
      setFilteredProducts(courses);
    } else {
      // Otherwise, filter products based on the search query
      const filtered = courses.filter(course =>
        course?.title?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };
  return (
    <>
    <div className='row'>
    <div className="col-md-6 col-12 mt-md-5 mx-md-4 px-md-2">
        <div className="banner-container">
            <h1 className="banner ">Popular Courses for you</h1>
            <div className="banner-underline"></div>
        </div>
<span className="text-subheading mt-4" >Explore our most sought-after courses, specially curated to meet the demands of today’s fast-paced digital landscape.  </span>
</div>
<div className="col-md-5 d-flex align-items-center justify-content-center my-4">
        <div className="input-group mt-md-5 mx-md-4" style={{ width: '100%', maxWidth: '400px' }}>
          <input
            type="text"
            value={searchQuery}
            onChange={handleChange}
            className="form-control rounded-pill px-3 py-2"
            placeholder="Search courses"
            style={{ borderColor: '#1D191C', paddingRight: '2.5rem' }}
          />
          <span className="position-absolute end-0 me-3 mt-2">
            <i className="fas fa-search"></i>
          </span>
        </div>
      </div>
    </div>




                {/* <div className="jumbotron">
  <div className="container text-center">
    <h1>My Portfolio</h1>      
    <p>Some text that represents "Me"...</p>
  </div>
</div>
   */}
                <div className=" bg-3 text-center">

                    <div className="row">
                    {filteredProducts && filteredProducts.map((course, index) => (
                    <div className="col-md-6 col-lg-3" key={index}>
                            <Link  className="d-flex justify-content-center container mt-4" style={{ textDecoration: 'none',color:'black'}} to={{
            pathname: "/coursedetails",
            search: `?courseId=${course?._id}`, 
          }}>
                                <div className="card p-3 bg-white border-0 shadow rounded-4">
                                    <div className="about-product text-center "><img className='rounded-4 w-100' src={course.thumbnail}  height='250' />
                                        {/* <div>
                    <h4>Believing is seeing</h4>
                    <h6 className="mt-0 text-black-50">Apple pro display XDR</h6>
                </div> */}
                                    </div>
                                    <div className="stats mt-2">
                                        <div className="d-flex justify-content-between p-price my-2"><div style={{ textDecoration: 'none',color:'black',textAlign:'left' }}><b>{course?.title}</b></div><span className="badge mx-1" style={{ fontSize: '11px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', padding: '5px 10px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {course?.level}
                                        </span>
                                        </div>
                                        <div className="d-flex justify-content-between p-price mt-3"><span><b>${course?.price}</b></span><span><b>{course?.courseDuration}</b></span></div>
                                    </div>
                                </div>
                            </Link>
                        </div>))}
                        

                    </div>
                </div><br /><br />
    </>
  )
}

export default PopularCourses