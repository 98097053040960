import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';

const ProgressTrackingWorkoutOngoing = () => {
    const [ongoingWorkouts, setongoingWorkouts] = useState([]);
  const [isloading, setIsLoading] = useState(false);

    const getWorkouts = () => {
    
        let getRes = (res) => {
          //  localStorage.setItem("userData", res?.data?.user);
          setongoingWorkouts(res.onGoingWorkoutsInfo);
        //   console.log(res.onGoingCoursesInfo)
        };
    
        callApi("GET", routes.ongoingWorkouts, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
    
        getWorkouts();
      }, []);
  return (
    <>

    <div className="container">
    <div className="row " style={{marginBottom:'10%'}}>
    {ongoingWorkouts && ongoingWorkouts.map((workout, index) => (<div className="col-md-4" key={index}>
        <div className="d-flex justify-content-center  mt-3 ">
        <div className="card   border-0 rounded-4 d-flex flex-row align-items-center w-100 shadow" >
            <div className="">
                <img className='img-fluid rounded-start-4' src={workout?.progressDoc?.workout?.workoutThumbnail} width="124" style={{height:'120px'}} />
            </div>
            <div className="stats  flex-grow-1">
                <div className="px-3">
                
                    {/* <Link to='/progresstrackingworkoutcontent' style={{ textDecoration: 'none', color:'black' }}>
                        <h5 className="my-3"><b>{workout?.progressDoc?.workout?.workoutTitle}</b></h5>
                    </Link> */}
                    <Link  to={{
            pathname: "/progresstrackingworkoutcontent",
            search: `?workoutId=${workout?.progressDoc?._id}`, 
          }} style={{ textDecoration: 'none', color:'black' }}>
                        <h6 className="mb-0"><b>{workout?.progressDoc?.workout?.workoutTitle}</b></h6>
                    </Link>
                    
                    <p className='text-secondary'>{workout?.progressDoc?.workout?.workoutDuration}</p>
                    <div className="d-flex justify-content-between p-price mt-3">
                    <div className="progress col-10 rounded-3">
  <div className="progress-bar bg-success rounded-3" role="progressbar" aria-valuenow="70"
  aria-valuemin="0" aria-valuemax="100" style={{width:`${(workout?.watchedVideos/workout?.totalVideos)* 100}%`}}>
    {/* 70% */}
  </div>
</div>
                        <span style={{fontSize:'14px',marginLeft:'5px'}}>{workout?.watchedVideos}/{workout?.totalVideos}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>))}
        
        
    </div>
    </div>
    
    </>
  )
}

export default ProgressTrackingWorkoutOngoing