import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ProgressTrackingQuizes = () => {
  const [showQuizStartModal, setShowQuizStartModal] = useState(false);
  const [QuizCorrectAnswer, setQuizCorrectAnswer] = useState('');
  const [QuizCorrectAnswerValue, setQuizCorrectAnswerValue] = useState('');
  const [QuizWrongAnswer, setQuizWrongAnswer] = useState('');
  const [QuizAnswerValue, setQuizAnswerValue] = useState('UnSelected');
  const [Quiznext, setQuiznext] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [coursedetail, setcoursedetail] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [showcourseSuccessfulModal, setShowcourseSuccessfulModal] = useState(false);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const progressId = queryParams.get("progressId");
  const courseId = queryParams.get("courseId");
  const [ongoingCourses, setongoingCourses] = useState([]);

  const getCourses = () => {
    const getRes = (res) => {
      setongoingCourses(res.courseProgress);
    };

    callApi("GET", routes.getOneOngoingCourses + '/' + progressId, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    if (progressId) {
      getCourses();
    }
  }, [progressId]);

  const handleVideoDoneButton = () => {
    navigate(-1);
  };
  const [quizId, setquizId] = useState('')
  const startQuiz = (index, id) => {
    setShowQuizStartModal(true);
    setCurrentQuizIndex(index);
    setCurrentQuestionIndex(0);
    setCorrectAnswersCount(0);  // Reset count when starting a new quiz
    resetQuizState();
    setquizId(id);
  };

  const isQuizCompleted = (quizId) => {
    if (!ongoingCourses?.quiz) return false;
    const quiz = ongoingCourses.quiz.find(quiz => quiz?.quizId === quizId);
    return quiz ? quiz?.completed : false;
    getCourses();
  };

  const handleQuizAnswerValue = (e) => {
    const clickedValue = e.target.value;
    setQuiznext(true);
  
    // Find the correct answer for the current question
    const correctOption = ongoingCourses.course.quiz[currentQuizIndex].questions[currentQuestionIndex].options.find(option => option.isOptionCorrect);
  
    if (clickedValue === correctOption.option) {
      setQuizCorrectAnswer(clickedValue);
      setQuizAnswerValue(clickedValue);
      setCorrectAnswersCount(prevCount => prevCount + 1);  // Increment only if correct
    } else {
      setQuizWrongAnswer(clickedValue);
      setQuizAnswerValue(clickedValue);
    }
  
    setQuizCorrectAnswerValue(correctOption.option);
  };
  

  const handlecourseSuccessful = () => {
    console.log(`Total Correct Answers: ${correctAnswersCount}`);
    
    setShowQuizStartModal(false);
   
        let getRes = (res) => {
            if (res.statusCode == 200) {
                setShowcourseSuccessfulModal(true);
                
            } else {
                toast.error(res.message, {
                    autoClose: 1500
                })
            }
        };
        let body = {
            correctAnswers: correctAnswersCount,
            
          };

        callApi("POST", routes.markQuizComplete+'/'+courseId+'/'+quizId ,body,  setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
  };

  const handleQuizContinue = () => {
    if (currentQuestionIndex < ongoingCourses.course.quiz[currentQuizIndex].questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      resetQuizState();  // Reset for the next question
    } else {
      handlecourseSuccessful();  // If the quiz is completed
    }
  };
  

  const resetQuizState = () => {
    setQuizCorrectAnswer('');
    setQuizWrongAnswer('');
    setQuizAnswerValue('UnSelected');
    setQuiznext(false);
    
    // Set the correct answer for the new question
    const correctOption = ongoingCourses.course.quiz[currentQuizIndex].questions[currentQuestionIndex].options.find(option => option.isOptionCorrect);
    setQuizCorrectAnswerValue(correctOption.option);
  };
  

  useEffect(() => {
    if (showcourseSuccessfulModal) {
      const timer = setTimeout(() => {
        setShowcourseSuccessfulModal(false);
        getCourses();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showcourseSuccessfulModal]);

  return (
    <>
      <div className="container-fluid" style={{ minHeight: '488px' }}>
        <div className='mt-5'>
          <h1 className="how-it-works-title">Progress Tracking</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto text-center my-4">
                <p className="text-subheading">Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className="col-md-9 mx-auto mb-5">
            <span className='eighteenfont fw-bold'>Course Content</span>
            {ongoingCourses?.course?.quiz && ongoingCourses?.course?.quiz.map((quiz, index) => (
              <div key={index} className="d-flex justify-content-start">
                <div className="py-4">
                  <input
                    className="form-check-input shadow rounded-2"
                    type="checkbox"
                    style={{ width: '24px', height: '24px' }}
                    checked={isQuizCompleted(quiz?._id)}
                    readOnly
                  />
                </div>
                <div className="my-3 py-2 shadow rounded-3 w-100" style={{ marginLeft: '15px' }}>
                  <div className="d-flex justify-content-between align-items-center px-3 py-1">
                    <div className="d-flex flex-start">
                      <p className="m-0"><b>Quiz {index + 1}</b></p>
                    </div>
                    <div>
                                        {isQuizCompleted(quiz?._id)?<i className="fa-solid fa-play fa-lg"  ></i>:<i className="fa-solid fa-play fa-lg" style={{cursor:'pointer'}} onClick={() => startQuiz(index, quiz?._id)}></i>}
                                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="container">
              <div className="row mt-5 mb-5">
                <div className="col-12 col-md-4 d-flex justify-content-center mb-3">
                  <button className="btn rounded-pill px-5 py-3 shadow w-100 mx-2" onClick={handleVideoDoneButton} style={{ backgroundColor: '#1D191C', color: 'white' }}>
                    Back
                  </button>
                </div>
                {/* <div className="col-12 col-md-4 d-flex justify-content-center mb-3">
                  <button className="btn rounded-pill px-5 py-3 shadow w-100 mx-2" onClick={() => setShowQuizStartModal(true)} style={{ backgroundColor: '#1D191C', color: 'white' }}>
                    View Quizzes
                  </button>
                </div> */}
              </div>
            </div>

            {showQuizStartModal && (
              <div className="modal show" tabIndex="-1" aria-labelledby="quizModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog" style={{ marginTop: '5%' }}>
                  <div className="modal-content my-1 container">
                    <div className="modal-body text-center">
                      <div className="container">
                        <div className="row justify-content-center mt-3">
                          <div className="col-1">
                            <span className='fw-bold'>{currentQuestionIndex + 1}/{ongoingCourses.course.quiz[currentQuizIndex].questions.length}</span>
                          </div>
                          <div className="col-10">
                            <span className='fw-bold' style={{ marginRight: '25px' }}>Quiz</span>
                          </div>
                        </div>
                        <div className="progress col-11 mx-auto rounded-3 my-3">
                          <div className="progress-bar rounded-3" role="progressbar" aria-valuenow={(currentQuestionIndex + 1) / ongoingCourses.course.quiz[currentQuizIndex].questions.length * 100} aria-valuemin="0" aria-valuemax="100" style={{ width: `${(currentQuestionIndex + 1) / ongoingCourses.course.quiz[currentQuizIndex].questions.length * 100}%`, backgroundColor: '#1D191C' }}>
                          </div>
                        </div>
                        <img src="./img/progresstracking/Rectangle 148289.png" alt="" className='img-fluid' height='200' width='390' />
                      </div>
                      <div className="container-fluid">
                        <h5 className='my-3'><b>{ongoingCourses.course.quiz[currentQuizIndex].questions[currentQuestionIndex].text}</b></h5>
                        <hr />
                        {ongoingCourses.course.quiz[currentQuizIndex].questions[currentQuestionIndex].options.map((option, idx) => (
                          <button
                          key={idx}
                          className={`btn ${QuizAnswerValue === option.option ? (QuizAnswerValue === QuizCorrectAnswerValue ? 'btn-success' : 'btn-danger') : 'btn-outline-dark'} mx-md-2 rounded-pill w-100 my-2 ${QuizAnswerValue !== 'UnSelected' ? 'disabled-button' : ''}`}
                          style={{ height: '48px', width: '100px' }}
                          value={option.option}
                          onClick={QuizAnswerValue === 'UnSelected' ? handleQuizAnswerValue : null}
                        >
                          {option.option}
                        </button>
                        
                        ))}
                        {Quiznext && (
                          <>
                          <p className='mt-2'>Correct Answer is: <b>{QuizCorrectAnswerValue}</b></p>
                          <div className="col-12 d-flex justify-content-center mt-4">
                            <button className="btn rounded-pill px-5 py-3 shadow w-100" onClick={handleQuizContinue} style={{ backgroundColor: '#1D191C', color: 'white' }}>
                              Next
                            </button>
                          </div></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showcourseSuccessfulModal && (
              <div className="modal show" tabIndex="-1" aria-labelledby="successModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog" style={{ marginTop: '10%' }}>
                  <div className="modal-content my-1 container">
                    <div className="modal-body text-center">
                      <div className="container">
                        <div className="row justify-content-center mt-3">
                          <img src="./img/progresstracking/Group 1540.png" alt="" className='img-fluid' />
                        </div>
                      </div>
                      <div className="container">
                        <div className="col-10 mx-auto">
                          <h2 className="fw-bold">Congratulations!</h2>
                          <p>You have successfully completed the Quiz.</p>
                          <button className="btn rounded-pill px-5 py-3 shadow w-100 my-3" onClick={() => setShowcourseSuccessfulModal(false)} style={{ backgroundColor: '#1D191C', color: 'white' }}>
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressTrackingQuizes;
