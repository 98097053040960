import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';

const ProgressTrackingCourseCompleted = () => {
    const [completeCourses, setcompleteCourses] = useState([]);
  const [isloading, setIsLoading] = useState(false);

    const getCourses = () => {
    
        let getRes = (res) => {
          //  localStorage.setItem("userData", res?.data?.user);
          setcompleteCourses(res.progress);
        //   console.log(res.progress)
        };
    
        callApi("GET", routes.completeCourses, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
    
        getCourses();
      }, []);
  return (
    <>

    <div className="container">
    <div className="row " style={{marginBottom:'10%'}}>
    {completeCourses && completeCourses.map((course, index) => (
    <div className="col-md-4" key={index}>
        <div className="d-flex justify-content-center  mt-3 mb-2">
        <div className="card p-3 bg-white border-0 shadow rounded-4 d-flex flex-row align-items-center w-100">
            <div className="about-product text-center">
                <img className='img-fluid rounded-4' src={course?.course?.thumbnail} width="80" style={{height:'80px'}} />
            </div>
            <div className="stats  flex-grow-1">
                <div className="px-3">
                <div className="d-flex justify-content-between p-price ">
                    <Link to='/coursedetails' style={{ textDecoration: 'none', color:'black' }}>
                        <h6 className="mb-0"><b>{course?.course?.title}</b></h6>
                    </Link>
                    <div>
                    <span className="badge py-2" style={{ fontSize: '10px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', padding: '5px 10px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10px' }}>
                    {course?.course?.level}
                    </span>
                    </div>
                    </div>
                    <div className="d-flex justify-content-between p-price mt-3">
                    <div className="progress col-10 rounded-3">
  <div className="progress-bar bg-primary rounded-3" role="progressbar" aria-valuenow="70"
  aria-valuemin="0" aria-valuemax="100" style={{width:'100%'}}>
    {/* 70% */}
  </div>
</div>
                        <span style={{fontSize:'14px',marginLeft:'5px'}}>{course?.videos?.length}/{course?.videos?.length}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>))}
        
        
    </div>
    </div>
    
    </>
  )
}

export default ProgressTrackingCourseCompleted