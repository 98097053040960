import React, { useState } from 'react'
import HomeCarousel from '../Home/HomeCarousel'
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const ContactUs = () => {
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [email, setemail] = useState('');
  const [message, setmessage] = useState('');
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleFirstNameChange = (e) => {
    setfirstname(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setlastname(e.target.value);
  };
  const handleEmailChange = (e) => {
    setemail(e.target.value);
  };
  const handleMessageChange = (e) => {
    setmessage(e.target.value);
  };

  const handleContactUs = (e) => {
    
    console.log(lastname); 
    console.log(firstname);
    console.log(email)
    console.log(message);
    e.preventDefault();
    let getRes = (res) => {
      if (res.statusCode == 200) {

        toast.success(res.message, {
                autoClose: 1500,
                onClose: () => {
					navigate(`/`)
				  }
              });
      } else {
        toast.error(res.message, {
              autoClose: 1500
              })
      }
    }; 
    let body = {
      firstName: firstname,
      lastName: lastname,
      email: email,
      message: message,
    };
    callApi("POST", routes.contactUs, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  return (
    <>
    <HomeCarousel/>
    <div className='container my-5'>
    <div className="">
  <div className="row">
  <div className="col-md-5">
  <div className=" position-relative">
    <img src="./img/home/3lines.png" alt="" className='position-absolute top-0 end-0 '/>
    <div className="text">
      <h1 style={{fontSize:'48px', fontWeight:600, fontFamily:'Poppins', color:'black',paddingTop:'40px'}}>We’re here to help you succeed.</h1>
    </div>
  </div>
  <div className='mt-3 mb-1'>
  <span className='text-subheading '>Get in touch with us for more information about our courses and workout programs. </span>
  </div>
  <h5 className='text-secondary '>company@info.pk.com</h5>
  <h5 className='text-secondary my-4'>+92-305-4597792</h5>
<u><h5><b>Contact Support</b></h5></u>
  <div className="">
    <div className="d-flex flex-wrap justify-content-start mt-5">
      <div className='col-md-5  my-2'>
        <h6><b>Contact Support</b></h6>
        <p className='text-low' style={{fontSize:'14px'}}>Our support team is here to help you! Whether you have questions about courses, workouts, or account issues, reach out to us anytime.</p>
      </div>
      <div className='col-md-5 mx-md-4 my-2'>
        <h6><b>Feedback & Suggestion</b></h6>
        <p className='text-low' style={{fontSize:'14px'}}>We value your input! Your feedback helps us improve our courses and services. If you have suggestions, comments, or ideas then share to us</p>
      </div>
    </div>
  </div>
</div>

    <div className="col-md-7">
    <div className="row">
    <div className="col-md-10 offset-md-3">
    <div className="form-container shadow p-4 rounded-5">
        <h4 className="mb-3"><b>Get In Touch</b></h4>
        <p className="text-secondary mb-4">Get in touch</p>
        <form>
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <input type="text" className="form-control rounded-5 py-3 px-2" id="firstName" onChange={handleFirstNameChange} placeholder="First Name"/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <input type="text" className="form-control rounded-5 py-3 px-2" id="lastName" onChange={handleLastNameChange} placeholder="Last Name"/>
                    </div>
                </div>
            </div>
            <div className="form-group mb-3">
    <div className="position-relative">
        <input type="email" className="form-control rounded-5 py-3 px-2" id="email" placeholder=" " onChange={handleEmailChange}/>
        {email === '' && (<label for="email" className="position-absolute top-0 start-0 bottom-0 py-3 px-2 text-secondary">
        <i className="fa-regular fa-envelope fa-lg mx-2"></i> Your Email
        </label>)}
    </div>
</div>

            <div className="form-group mb-3">
                <textarea className="form-control rounded-5 py-3 px-3" id="message" placeholder="Message" onChange={handleMessageChange} rows="9"></textarea>
            </div>
            <div className="text-center">
        <button type="button" onClick={handleContactUs} className="btn  rounded-pill py-3 px-2 w-100" style={{backgroundColor:'#1D191C', color:'white'}}>Submit</button>
    </div>
    <ToastContainer/>
            <div className=" mt-3 text-center mx-4">
                <label className="form-check-label text-low" for="terms">By contacting us, you agree to our <b className='text-default'>Terms of Service</b> and <b className='text-default'>Privacy Policy</b>.</label>
            </div>
        </form>
    </div>
</div>

        </div>
    </div>
  </div>
</div>
    </div>
    </>
  )
}

export default ContactUs