import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ProgressTrackingCourseContent = () => {
   
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');
    const [showVideodoneModal, setShowVideodoneModal] = useState(false);
    const [duration, setDuration] = useState(0);
    const [watchedTime, setWatchedTime] = useState(0);
    const playerRef = useRef(null);
    const [showuploadModal, setShowuploadModal] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [showAssignmentdoneModal, setShowAssignmentdoneModal] = useState(false);
    const [showQuizStartModal, setShowQuizStartModal] = useState(false);
    const [QuizCorrectAnswer, setQuizCorrectAnswer] = useState('');
    const [QuizCorrectAnswerValue, setQuizCorrectAnswerValue] = useState('How');
    const [QuizWrongAnswer, setQuizWrongAnswer] = useState('');
    const [QuizAnswerValue, setQuizAnswerValue] = useState('UnSelected');
    const [Quiznext, setQuiznext] = useState(false);
    const [isFigmaChecked, setIsFigmaChecked] = useState(false);
    const [isloading, setIsLoading] = useState(false);
  const [coursedetail, setcoursedetail] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get("courseId");
    const [openCourseId, setopenCourseId] = useState(0);
    const [completeCourse, setCompleteCourse] = useState(null);
  

    
    const [ongoingCourses, setongoingCourses] = useState([]);
    const getCourses = () => {
    
        let getRes = (res) => {
          //  localStorage.setItem("userData", res?.data?.user);
          setongoingCourses(res.courseProgress);
          setopenCourseId(res.courseProgress.course._id)
          setCompleteCourse(res)
        //   console.log(res.onGoingCoursesInfo)
        };
    
        callApi("GET", routes.getOneOngoingCourses+'/'+courseId, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
        if (courseId) {
            getCourses();
        }
      }, [courseId]);
    const handleVideoDoneButton = () => {
        navigate(-1);
    };
    const handleQuizAnswerValue = (e) => {
        const clickedValue = e.target.value;
    setQuiznext(true);
        // Check if the clicked answer is correct
        if (clickedValue === QuizCorrectAnswerValue) {
            // If correct, set the correct answer and value to highlight success
            setQuizCorrectAnswer(clickedValue);
            setQuizAnswerValue(clickedValue);
            
        } else {
            // If wrong, set the clicked answer as the wrong answer
            setQuizWrongAnswer(clickedValue);
            // Highlight the clicked answer as wrong
            setQuizAnswerValue(clickedValue);
            
        }
    };
    const handlecourseSuccessful = () => {
        setShowcourseSuccessfulModal(true);
        // Perform payment processing logic here
        setShowQuizStartModal(false);
    };
  
    const [showcourseSuccessfulModal, setShowcourseSuccessfulModal] = useState(false);
    
    // const handleQuizAnswer = () => {
    //     setFileSelected(true);
    // };
    const [selectedDocumentFileName, setSelectedDocumentFileName] = useState(null);
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
    setSelectedDocumentFileName(file.name);
        setFileSelected(true);
    };
    const [vedioId, setvedioId] = useState('')
    const startVideo = (url, id) => {
        setCurrentVideoUrl(url);
        setShowVideoModal(true);
        setvedioId(id); 
    };
    const handleVideodoneSuccessful = () => {
        setShowVideodoneModal(true);
        setShowVideoModal(false);
        let watchvedio = Math.round(watchedTime / 60);
        let totalvedio = Math.round(duration / 60)
        console.log(`Total video time: ${Math.round(duration / 60)} minutes`);
        console.log(`Watched time: ${Math.round(watchedTime / 60)} minutes`);
        let getRes = (res) => {
            if (res.statusCode == 200) {
                toast.success(res.message, {
                    autoClose: 1500
                })
                
            } else {
                toast.error(res.message, {
                    autoClose: 1500
                })
            }
        };
        let body = {
            watchedDuration: watchvedio,
            videoDuration: totalvedio,
          };

        callApi("POST", routes.markVideoComplete+'/'+openCourseId+'/'+vedioId ,body,  setIsLoading, getRes, (error) => {
            console.log("error", error);
          });
    };
    const handleDuration = (duration) => {
        setDuration(duration);
    };

    const handleProgress = (state) => {
        setWatchedTime(state.playedSeconds);
    };
    const isVideoCompleted = (videoId) => {
        if (!ongoingCourses?.videos) return false;
        const video = ongoingCourses.videos.find(video => video?.content === videoId);
        return video ? video?.completed : false;
        getCourses();
    };
    const checkIfCourseCompleted = () => {
        if (completeCourse && completeCourse.totalVideos === completeCourse.watchedVideos) {
            // console.log("Workout is completed");

            let getRes = (res) => {
                if (res.statusCode == 200) {
                    toast.success(res.message, {
                        autoClose: 1500
                    })
                    
                } else {
                    toast.error(res.message, {
                        autoClose: 1500
                    })
                }
            };
            
                callApi("POST", routes.markCourseComplete+'/'+openCourseId, null, setIsLoading, getRes, (error) => {
                  console.log("error", error);
                });
              
        }
    };

    useEffect(() => {
        checkIfCourseCompleted();
    }, [completeCourse]);
    const closeModal = () => {
        setShowVideodoneModal(false);
    };

    const handleuploadModal = () => {
        setShowuploadModal(true);
        setShowAssignmentdoneModal(false);
        // Perform payment processing logic here
    };
    const handleAssignmentdone = () => {
        setShowAssignmentdoneModal(true);
        setShowuploadModal(false);
        // Perform payment processing logic here
    };
    const handleQuizStart = () => {
        // setShowAssignmentdoneModal(false);
        setShowQuizStartModal(true);
    };
    useEffect(() => {
        let timer;
        if (showVideodoneModal ) {
            timer = setTimeout(() => {
                setShowVideodoneModal(false);
                // setShowuploadModal(true);
                setShowcourseSuccessfulModal(false);
            }, 2000);
        }
        if ( showcourseSuccessfulModal) {
            setIsFigmaChecked(true);
            timer = setTimeout(() => {
                setShowcourseSuccessfulModal(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [showVideodoneModal, showcourseSuccessfulModal]);
    
  return (
    <>
    <div className="container-fluid" style={{minHeight:'488px'}}>
    <div className='mt-5'>
    <h1 className="how-it-works-title">Progress Tracking</h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mx-auto text-center my-4">
                                <p className="text-subheading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, consectetur adipiscing elit, consectetur adipiscing elit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore</p>
                            </div>
                        </div>
                    </div>
    </div>
    <div className='container'>
    <div className="col-md-9 mx-auto mb-5">
    <span className='eighteenfont fw-bold'>Course Content</span>
    {ongoingCourses?.course?.content && ongoingCourses?.course?.content.map((content, index) => (<div key={index} className="d-flex justify-content-start">
                            <div className="py-4">
                                <input
                                    className="form-check-input shadow rounded-2"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    style={{ width: '24px', height: '24px' }}
                                    checked={isVideoCompleted(content?._id)} // Bind the checked state to the checkbox
                                    readOnly // Make the checkbox read-only
                                />
                            </div>
                            <div className="my-3 py-2 shadow rounded-3 w-100" style={{ marginLeft: '15px' }}>
                                <div className="d-flex justify-content-between align-items-center px-3 py-1">
                                    <div className="d-flex flex-start">
                                        <p className="m-0"><b>{content?.contentTitle}</b></p>
                                    </div>
                                    <div>
                                        {isVideoCompleted(content?._id)?<i className="fa-solid fa-circle-play fa-lg" ></i>:<i className="fa-solid fa-circle-play fa-lg" onClick={() => startVideo(content?.contentUrl, content?._id)}></i>}
                                    </div>
                                </div>
                            </div>
                        </div>))}
{/* <div className="d-flex justify-content-start">
    
        <div className='py-4 '>
        <input className="form-check-input shadow rounded-2" type="checkbox" value="" id="flexCheckDefault" style={{width:'24px', height:'24px'}}/>
        </div>
    
    
        <div className="my-3 py-2 shadow rounded-3 w-100" style={{marginLeft:'15px'}}>
            <div className="d-flex justify-content-between align-items-center px-3 py-1">
                <div className="d-flex flex-start">
                    <p className="m-0"><b>Why Using Figma</b></p>
                </div>
                <div>
                    <i className="fa-solid fa-circle-play fa-lg" onClick={() => startvedio()}></i>
                </div>
            </div>
        </div>
    
</div> */}




<div className="container">
  <div className="row mt-5 mb-5">
    <div className="col-12 col-md-4 d-flex justify-content-center mb-3">
      <button className="btn rounded-pill px-5 py-3 shadow w-100 mx-2" onClick={handleVideoDoneButton} style={{ backgroundColor: '#1D191C', color: 'white' }}>
        Videos Complete
      </button>
    </div>
    <div className="col-12 col-md-4 d-flex justify-content-center mb-3">
      <Link to={{
            pathname: "/progresstrackingassignments",
            search: `?courseId=${courseId}`, 
          }} className="btn rounded-pill px-5 py-3 shadow w-100 mx-2" style={{ backgroundColor: '#1D191C', color: 'white' }}>
        View Assignments
      </Link>
    </div>
    <div 
          className="col-12 col-md-4 d-flex justify-content-center mb-3">
      <Link to={{
            pathname: "/progresstrackingquizes",
            search: `?progressId=${courseId}&courseId=${ongoingCourses?.course?._id}`, 
          }}
           className="btn rounded-pill px-5 py-3 shadow w-100 mx-2" style={{ backgroundColor: '#1D191C', color: 'white' }}>
      View Quizes
      </Link>
    </div>
  </div>
</div>


{showVideoModal && (
                    <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog " style={{marginTop: '5%'}}>
                        <div className="modal-content my-1 w-100">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="paymentModalLabel">Payment Successful</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body ">
                                
                            <ReactPlayer
                                    ref={playerRef}
                                    url={currentVideoUrl}
                                    controls={true}
                                    width="100%"
                                    height="300px"
                                    onEnded={handleVideodoneSuccessful}
                                    onDuration={handleDuration}
                                    onProgress={handleProgress}
                                />

                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                
                )}
                {showVideodoneModal && (
                <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog " style={{marginTop: '5%'}}>
                        <div className="modal-content my-1">
                            <div className="modal-body text-center">
                                <img src="./img/courses/coursepayment/coursepaymentsuccessfull.png" alt="" height='100' width='100'/>
                                <h4 className='my-3'><b>Video Completed Successfully</b></h4>
                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showuploadModal && (
                    <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog " style={{marginTop: '5%'}}>
                        <div className="modal-content my-1">
                            
                        <div className="modal-body text-center">
                <div className="row h-100 justify-content-center align-items-center p-2">
                    <div className="dropzone text-center d-flex flex-column justify-content-center align-items-center">
                        <div>
                            <img src="./img/progresstracking/Vector.png" className="upload-icon" alt="Upload Icon" />
                            <span className="d-block">{selectedDocumentFileName ? selectedDocumentFileName :'Upload Document'}</span>
                        </div>
                        <input type="file" className="upload-input" accept=".pdf,.doc,.docx,.txt" onChange={handleFileSelect} />
                    </div>
                </div>
                <h6 className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.</h6>

                {fileSelected && (
                    <>
                        <div className="d-flex justify-content-center">
                            <button className="btn mx-2 rounded-pill my-1 w-100" style={{ height: '48px', width: '100px', backgroundColor:'#1D191C1A',color:'#1D191C' }} onClick={handleAssignmentdone}><b>Skip</b></button>
                            <button className="btn btn-dark rounded-pill mx-2 my-1 w-100" style={{ height: '48px', width: '100px' }}>Download</button>
                        </div>
                    </>
                )}
            </div>
                            
                        </div>
                    </div>
                </div>
                
                )}
{showAssignmentdoneModal && (
                    <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog " style={{marginTop: '5%'}}>
                        <div className="modal-content my-1">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="paymentModalLabel">Payment Successful</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body text-center">
                                
                                <img src="./img/courses/coursepayment/coursepaymentsuccessfull.png" alt="" height='100' width='100'/>
                                <h4 className='my-3'><b>Submit Assignment</b></h4>
                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.</h6>
                                <button className="btn btn-dark rounded-pill mx-2 my-1 w-50 " style={{ height: '48px', width: '100px' }} onClick={handleQuizStart}>Start Quiz</button>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                
                )}
                {showQuizStartModal && (
                    <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog " style={{marginTop: '5%'}}>
                        <div className="modal-content my-1 container">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="paymentModalLabel">Payment Successful</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body text-center">
                            <div className="container">
                            <div className="row justify-content-center mt-3">
    <div className="col-1">
        <span className='fw-bold'>1/10</span>
    </div>
    <div className="col-10" >
        <span className='fw-bold' style={{marginRight:'25px'}}>Quiz</span>
    </div>
</div>

                            <div className="progress col-11 mx-auto rounded-3 my-3">
  <div className="progress-bar  rounded-3" role="progressbar" aria-valuenow="70"
  aria-valuemin="0" aria-valuemax="100" style={{width:'75%', backgroundColor:'#1D191C'}}>
    {/* 70% */}
  </div>
</div>
                                <img src="./img/progresstracking/Rectangle 148289.png" alt="" className='img-fluid' height='200' width='390'/>
                            </div>
                                <div className="container-fluid">
                                <h5 className='my-3'><b>........ lorem ipsum dolor set amet?</b></h5>
                                <hr />
                                
                                <button
  className={`btn ${QuizAnswerValue === 'How' ? (QuizAnswerValue === QuizCorrectAnswer  ? 'btn-success' : 'btn-danger') : 'btn-outline-dark'} mx-md-2 rounded-pill w-100 my-2`}
  style={{height:'48px', width:'100px'}}
  value='How'
  onClick={handleQuizAnswerValue}
  disabled={QuizAnswerValue !== 'UnSelected'}
>
  How
</button>

<button
  className={`btn ${QuizAnswerValue === 'What' ? (QuizAnswerValue === QuizCorrectAnswer ? 'btn-success' : 'btn-danger') : 'btn-outline-dark'} mx-md-2 rounded-pill w-100 my-2`}
  style={{height:'48px', width:'100px'}}
  value='What'
  onClick={handleQuizAnswerValue}
  disabled={QuizAnswerValue !== 'UnSelected'}
>
  What
</button>

<button
  className={`btn ${QuizAnswerValue === 'Which' ? (QuizAnswerValue === QuizCorrectAnswer ? 'btn-success' : 'btn-danger') : 'btn-outline-dark'} mx-md-2 rounded-pill w-100 my-2`}
  style={{height:'48px', width:'100px'}}
  value='Which'
  onClick={handleQuizAnswerValue}
  disabled={QuizAnswerValue !== 'UnSelected'}
>
  Which
</button>

<button
  className={`btn ${QuizAnswerValue === 'Where' ? (QuizAnswerValue === QuizCorrectAnswer ? 'btn-success' : 'btn-danger') : 'btn-outline-dark'} mx-md-2 rounded-pill w-100 my-2`}
  style={{height:'48px', width:'100px'}}
  value='Where'
  onClick={handleQuizAnswerValue}
  disabled={QuizAnswerValue !== 'UnSelected'}
  >
    Where
  </button>

                                {Quiznext ? <>
                                <p className='mt-2'>Correct Answer is: <b>{QuizCorrectAnswerValue}</b></p>
                                    <button className="btn btn-dark mx-2 rounded-pill  w-50" style={{ height: '48px', width: '100px' }} onClick={handlecourseSuccessful}><b>Continue</b></button>
                                </>:<button className="btn mx-2 rounded-pill my-1 w-50" style={{ height: '48px', width: '100px', backgroundColor:'#1D191C1A',color:'#1D191C' }} ><b>Skip</b></button>}

                                </div>

                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                
                )}

{showcourseSuccessfulModal && (
                    <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog " style={{marginTop: '5%'}}>
                        <div className="modal-content my-1">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="paymentModalLabel">Payment Successful</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body text-center">
                                
                                <img src="./img/courses/coursepayment/coursepaymentsuccessfull.png" alt="" height='100' width='100'/>
                                <h4 className='my-3'><b>Congratulation! Course Completed Successfully!</b></h4>
                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.</h6>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                
                )}
    </div>
    </div>
    </div>
    </>
  )
}

export default ProgressTrackingCourseContent