import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../CourseInstructor/Dashboard/instructorhome.css';
import InstructorSidebar from '../../CourseInstructor/Dashboard/InstructorSidebar';
import InstructoreHeader from '../../CourseInstructor/Dashboard/InstructoreHeader';
import { S3 } from "aws-sdk";

const TrainerCreateWorkout = () => {
  const [workoutTitle, setWorkoutTitle] = useState("");
  const [workoutObjective, setWorkoutObjective] = useState("");
  const [totalWorkoutDuration, setTotalWorkoutDuration] = useState("");
  const [totalWorkoutCalories, setTotalWorkoutCalories] = useState("");

  const [workoutPrice, setWorkoutPrice] = useState("");
  const [workoutDuration, setWorkoutDuration] = useState("");
  const [workoutExerciseDuration, setWorkoutExerciseDuration] = useState("");

  const [selectedLevel, setSelectedLevel] = useState("");
  const [isDropdownLevelOpen, setIsDropdownLevelOpen] = useState(false);
  const [selectedDurationUnit, setSelectedDurationUnit] = useState("");
  const [isDropdownDurationUnitOpen, setIsDropdownDurationUnitOpen] = useState(false);
  const [selectedExerciseDurationUnit, setSelectedExerciseDurationUnit] = useState("");
  const [isDropdownExerciseDurationUnitOpen, setIsDropdownExerciseDurationUnitOpen] = useState(false);
  const handleLevelChange = (value) => {
    setSelectedLevel(value);
    setIsDropdownLevelOpen(false);
  };

  const toggleDropdownLevel = () => {
    setIsDropdownLevelOpen(!isDropdownLevelOpen);
  };
  const handleDurationUnitChange = (value) => {
    setSelectedDurationUnit(value);
    setIsDropdownDurationUnitOpen(false);
  };

  const toggleDropdownDurationUnit = () => {
    
    setIsDropdownDurationUnitOpen(!isDropdownDurationUnitOpen);
  };
  const handleExerciseDurationUnitChange = (value) => {
    setSelectedExerciseDurationUnit(value);
    setIsDropdownExerciseDurationUnitOpen(false);
  };

  const toggleDropdownExerciseDurationUnit = () => {
    
    setIsDropdownExerciseDurationUnitOpen(!isDropdownExerciseDurationUnitOpen);
  };
    const navigate = useNavigate();
    // const handlecontinue =() =>{
    //   navigate('/trainercreateworkoutdetails')
      
    // }
  const [completeWorkout, setCompleteWorkout] = useState('');

    const handlecontinue = () => {
      // console.log('Videos:', videos);
      const completeWorkoutData = {
        workoutThumbnail: imageURL,
        workoutTitle: workoutTitle,
        workoutLevel: selectedLevel,
        workoutActivity: videos,
        workoutPrice: workoutPrice,
        workoutDuration: totalWorkoutDuration,
        calories: totalWorkoutCalories,
      };
      if (!imageURL || !workoutTitle || !selectedLevel || !workoutPrice) {
        alert('Please fill in all the required details.');
        
      }
      else{
        setCompleteWorkout(completeWorkoutData);
      console.log(completeWorkoutData);
      navigate('/trainercreateworkoutdetails' , { state: { workoutDetail: completeWorkoutData } });
      }
      // setCompleteWorkout(completeWorkoutData);
      // console.log(completeWorkoutData);
    };
  const [imageURL, setImageURL] = useState(null);
  const [showDropZone, setShowDropZone] = useState(true);

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = await uploadImageOnS3(file);
      setImageURL(fileUrl);
      setShowDropZone(false);
      console.log(fileUrl);
    }
  };
  const uploadImageOnS3 = async (file) => {
    const s3bucket = new S3({
      region: 'us-east-2',
      accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        // setImageURL(reader.result);
        const params = {
          Bucket: 's3echobucket',
          Key: file.name,
          Body: arrayBuffer,
          ContentDisposition: `inline;filename="${file.name}"`,
          ContentType: file.type,
        };

        s3bucket.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
    const [videoURL, setVideoURL] = useState('');
    const [showUploader, setShowUploader] = useState(true);
    const [videos, setVideos] = useState([]);
    const [currentTitle, setCurrentTitle] = useState('');
    
    const handleVideoUpload = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const fileUrl = await uploadVideoOnS3(file);
        setVideoURL(fileUrl);
        setShowUploader(false);
        console.log(fileUrl);
      }
    };
    const uploadVideoOnS3 = async (file) => {
      const s3bucket = new S3({
        region: 'us-east-2',
        accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
        secretAccessKey: '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',
        signatureVersion: 'v4',
      });
  
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result;
          // setVideoURL(reader.result);
          const params = {
            Bucket: 's3echobucket',
            Key: file.name,
            Body: arrayBuffer,
            ContentDisposition: `inline;filename="${file.name}"`,
            ContentType: file.type,
          };
  
          s3bucket.upload(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data.Location);
            }
          });
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    };
    const handleAddVideo = () => {
      if (currentTitle && videoURL && workoutDuration && selectedDurationUnit) {
        setVideos([...videos, { title: currentTitle, url: videoURL, 
          activityDuration: {
            duration: workoutDuration,
            unit: selectedDurationUnit,
          },
          exerciseDuration: {
            duration: workoutExerciseDuration,
            unit: selectedExerciseDurationUnit,
          },
          
         }]);
        setCurrentTitle('');
        setVideoURL('');
        setWorkoutDuration('');
        setWorkoutExerciseDuration('');
        setSelectedDurationUnit('');
        setShowUploader(true);
      } else {
        alert('Please provide a details or wait to upload a video');
      }
    };
  
    const handleDeleteVideo = (index) => {
      const newVideos = [...videos];
      newVideos.splice(index, 1);
      setVideos(newVideos);
    };
  return (
    <>
    <div className="overflow-hidden">
      <div className="row " style={{marginBottom:'5%'}}>
        {/* Sidebar */}
        <InstructorSidebar/>
        {/* Main Content */}
        <div className="col-md-10">
          <InstructoreHeader/>
          <div className='container-fluid my-4'>
            <h3 className='fw-bold'>Create Workout Plan</h3>
            <div className='row'>
           <div className="col-md-6 col-12 ">
           <div className="row h-100  p-2">
           <div>
            {showDropZone && (
                <div className="instructordropzone text-center d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <img src="./img/courseinstructor/home/Camera.png" className="instructorupload-icon" alt="Upload Icon" />
                        <span className="d-block mt-2 fw-bold">Upload Image</span>
                    </div>
                    <input
                        type="file"
                        className="instructorupload-input"
                        accept="image/*"
                        onChange={handleFileSelect}
                    />
                </div>
            )}
            {imageURL && !showDropZone && (
                <>
                
                <div className="instructordropzoneuploadimg-container">
    <div className="instructordropzoneuploadimg">
        <img src={imageURL} alt="Uploaded" className="instructoruploaded-image" />
        <div>
        
        <img src="./img/courseinstructor/home/Group 1686554026.png" alt="" className="bottom-right-image" />
        <input
            type="file"
            className="instructoruploadimg-input rounded-circle"
            accept="image/*"
            onChange={handleFileSelect}
        /> 
        </div>
        {/* Uncomment the following input field if needed */}
        {/* <input
            type="file"
            className="instructoruploadimg-input"
            accept="image/*"
            onChange={handleFileSelect}
        /> */}
    </div>
</div>
                
                </>
            )}
        </div>
    <div className='formfieldwidth'>
    <div className="form-group my-3">
                                <label className="form-label fw-bold" htmlFor="workouttitle">Workout Title</label>
                                <input type="text" className="form-control rounded-4 py-2 px-2 border-0" id="workouttitle" placeholder="Workout Title" value={workoutTitle} onChange={(e) => setWorkoutTitle(e.target.value)} style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
                            <div className="form-group my-3 ">
                                <label className="form-label fw-bold" htmlFor="workoutlevel">Workout Level</label>
                                {/* <input type="text" className="form-control rounded-4 py-2 px-2 border-0" id="workoutlevel" placeholder="Workout Level" style={{ backgroundColor: '#FAFAFA', height: '52px' }}  /> */}
                                <div className="position-relative">
                          <div className="custom-dropdown">
                            <div
                              className=" rounded-4 py-3 px-2 border-0"
                              style={{
                                backgroundColor: "#FAFAFA",
                                height: "52px",
                              }}
                            >
                              {selectedLevel
                                ? selectedLevel
                                : <span className="text-low">Dropdown to select</span>}
                              <span
                                className="position-absolute top-50 end-0 translate-middle-y pe-3 dropdown-label"
                                onClick={toggleDropdownLevel}
                              >
                                <img
                                  src="./img/user/signup/Arrow - Down 2.png"
                                  alt=""
                                />
                              </span>
                            </div>
                            {isDropdownLevelOpen && (
                              <div className="options p-2">
                                <div className="row">
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Beginner</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Beginner"
                                        checked={selectedLevel === "beginner"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("beginner")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Intermediate</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Intermediate"
                                        checked={selectedLevel === "intermediate"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("intermediate")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Advance</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Advance"
                                        checked={selectedLevel === "advance"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("advance")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center ">
                                      <span>All</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="All"
                                        checked={selectedLevel === "all"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("all")
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                            </div>
                            
                            <div className="form-group my-3 border-bottom pb-4">
                                <label className="form-label fw-bold" htmlFor="totalworkout">Total Workout Duration</label>
                                <input type="text" className="form-control rounded-4 py-2 px-2 border-0" id="totalworkoutduration" value={totalWorkoutDuration} onChange={(e) => setTotalWorkoutDuration(e.target.value)} placeholder="Total Workout Duration" style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
                            <div className="form-group my-3 border-bottom pb-4">
                                <label className="form-label fw-bold" htmlFor="totalworkout">Total Workout Calories</label>
                                <input type="text" className="form-control rounded-4 py-2 px-2 border-0" id="totalworkoutcalories" value={totalWorkoutCalories} onChange={(e) => setTotalWorkoutCalories(e.target.value)} placeholder="Total Workout Calories" style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
                            
                            <h5 className='fw-bold'>Workout Activity</h5>
                            <div className="form-group my-3 ">
                                <label className="form-label fw-bold" htmlFor="courselevel">Upload Workout Video</label>
                                <div className="form-group my-3">
        <label className="form-label fw-bold" htmlFor="videotitle">Video Title</label>
        <input
          type="text"
          className="form-control py-2 px-2 border-0 rounded-4"
          id="videotitle"
          placeholder="Title"
          style={{ backgroundColor: '#FAFAFA', height: '52px' }}
          value={currentTitle}
          onChange={(e) => setCurrentTitle(e.target.value)}
        />
      </div>
                                <div className="form-group my-3 ">
                                <label className="form-label fw-bold" htmlFor="workoutduration">Workout Duration</label>
                                <input type="number" min="0" className="form-control rounded-4 py-2 px-2 border-0" id="workoutduration" placeholder="Workout Duration" value={workoutDuration} onChange={(e) => setWorkoutDuration(e.target.value)} style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
                            <div className="form-group my-3 ">
                                <label className="form-label fw-bold" htmlFor="workoutduration">Workout Duration Unit</label>
                                {/* <input type="text" className="form-control rounded-4 py-2 px-2 border-0" id="workoutduration" placeholder="Workout Duration" style={{ backgroundColor: '#FAFAFA', height: '52px' }}  /> */}
                                <div className="position-relative">
                          <div className="custom-dropdown">
                            <div
                              className=" rounded-4 py-3 px-2 border-0"
                              style={{
                                backgroundColor: "#FAFAFA",
                                height: "52px",
                              }}
                            >
                              {selectedDurationUnit
                                ? selectedDurationUnit
                                : <span className="text-low">Dropdown to select</span>}
                              <span
                                className="position-absolute top-50 end-0 translate-middle-y pe-3 dropdown-label"
                                onClick={toggleDropdownDurationUnit}
                              >
                                <img
                                  src="./img/user/signup/Arrow - Down 2.png"
                                  alt=""
                                />
                              </span>
                            </div>
                            {isDropdownDurationUnitOpen && (
                              <div className="options p-2">
                                <div className="row">
                                <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Seconds</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Days"
                                        checked={selectedDurationUnit === "seconds"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleDurationUnitChange("seconds")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Minutes</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Minutes"
                                        checked={selectedDurationUnit === "minutes"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleDurationUnitChange("minutes")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center ">
                                      <span>Hours</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Hours"
                                        checked={selectedDurationUnit === "hours"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleDurationUnitChange("hours")
                                        }
                                      />
                                    </label>
                                  </div>
                                  
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                            </div>
                            <div className="form-group my-3 ">
                                <label className="form-label fw-bold" htmlFor="workoutduration">Exercise Duration</label>
                                <input type="number" min="0" className="form-control rounded-4 py-2 px-2 border-0" id="exerciseduration" placeholder="Exercise Duration" value={workoutExerciseDuration} onChange={(e) => setWorkoutExerciseDuration(e.target.value)} style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
                            <div className="form-group my-3 ">
                                <label className="form-label fw-bold" htmlFor="workoutduration">Exercise Duration Unit</label>
                                {/* <input type="text" className="form-control rounded-4 py-2 px-2 border-0" id="workoutduration" placeholder="Workout Duration" style={{ backgroundColor: '#FAFAFA', height: '52px' }}  /> */}
                                <div className="position-relative">
                          <div className="custom-dropdown">
                            <div
                              className=" rounded-4 py-3 px-2 border-0"
                              style={{
                                backgroundColor: "#FAFAFA",
                                height: "52px",
                              }}
                            >
                              {selectedExerciseDurationUnit
                                ? selectedExerciseDurationUnit
                                : <span className="text-low">Dropdown to select</span>}
                              <span
                                className="position-absolute top-50 end-0 translate-middle-y pe-3 dropdown-label"
                                onClick={toggleDropdownExerciseDurationUnit}
                              >
                                <img
                                  src="./img/user/signup/Arrow - Down 2.png"
                                  alt=""
                                />
                              </span>
                            </div>
                            {isDropdownExerciseDurationUnitOpen && (
                              <div className="options p-2">
                                <div className="row">
                                <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Seconds</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Days"
                                        checked={selectedExerciseDurationUnit === "seconds"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleExerciseDurationUnitChange("seconds")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center ">
                                      <span>Minutes</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Minutes"
                                        checked={selectedExerciseDurationUnit === "minutes"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleExerciseDurationUnitChange("minutes")
                                        }
                                      />
                                    </label>
                                  </div>
                                  {/* <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center ">
                                      <span>Hours</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Hours"
                                        checked={selectedDurationUnit === "hours"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleDurationUnitChange("hours")
                                        }
                                      />
                                    </label>
                                  </div> */}
                                  
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                            </div>
                            </div>
                            
                            {videos.map((video, index) => (
        <div key={index} className="form-group my-3">
          <label className="form-label fw-bold">Upload Videos</label>
          <div className="d-flex align-items-center">
            <div>
              <span className='border-0 px-4 py-3 rounded-circle' style={{ backgroundColor: '#1D191C1A', height: '52px' }}>{index + 1}</span>
            </div>
            <div className="d-flex align-items-center w-100">
              <input
                type="text"
                className="form-control py-2 px-2 border-0 rounded-4 mx-3"
                style={{ backgroundColor: '#FAFAFA', height: '52px' }}
                value={video.title}
                readOnly
              />
              <img
                src="./img/courseinstructor/home/Delete.png"
                className=''
                alt=""
                onClick={() => handleDeleteVideo(index)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>
      ))}

      {/* <div className="form-group my-3">
        <label className="form-label fw-bold" htmlFor="videotitle">Video Title</label>
        <input
          type="text"
          className="form-control py-2 px-2 border-0 rounded-4"
          id="videotitle"
          placeholder="Title"
          style={{ backgroundColor: '#FAFAFA', height: '52px' }}
          value={currentTitle}
          onChange={(e) => setCurrentTitle(e.target.value)}
        />
      </div> */}
      </div>
      
      <div>
      {showUploader ? (
                <div className="instructordropzone text-center d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <img src="./img/courseinstructor/home/Video.png" className="instructorupload-icon" alt="Upload Icon" />
                        <span className="d-block mt-2 fw-bold">Upload Video</span>
                    </div>
                    <input type="file" className="instructorupload-input" accept="video/*" onChange={handleVideoUpload} />
                </div>
            ) : (
              <>
              
                <div className="instructordropzoneuploadvideo">
                    <video className="instructoruploaded-video" controls>
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {/* <input
                        type="file"
                        className="instructoruploadvideo-input"
                        accept="image/*"
                        onChange={handleFileSelect}
                    /> */}
                </div>
              </>
            )}
      </div>
      <div className="formfieldwidth">
<div className="d-flex justify-content-center align-items-center my-3">
  <img src="./img/courseinstructor/home/Group 1686554024.png" style={{cursor:'pointer'}} onClick={handleAddVideo} className='img-fluid' alt="" />
  
</div>
<div className='text-center'>
<h6><b>Upload Video</b></h6>

</div>
<div className="form-group my-3 ">
                                <label className="form-label fw-bold" htmlFor="workoutsessionprice">Workout Session Price</label>
                                <input type="number" min="0" className="form-control rounded-4 py-2 px-2 border-0" id="workoutsessionprice" placeholder="Workout Session Price" value={workoutPrice} onChange={(e) => setWorkoutPrice(e.target.value)} style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
                            <div className='my-5 col-md-12'>
                    <button className='btn rounded-pill px-5 py-3 shadow w-100 fw-bold' onClick={handlecontinue}  style={{backgroundColor:'#1D191C', color:'white'}}>Continue</button>
                    </div>
</div>


</div>

           </div>
           
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  </>
  )
}

export default TrainerCreateWorkout