import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './instructorhome.css';
import InstructorSidebar from './InstructorSidebar';
import InstructoreHeader from './InstructoreHeader';
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const InstructorCreateCourseDetails = () => {
  const location = useLocation();
  const { courseDetail } = location.state || {};
    const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);

    const handleContinue =() =>{
      navigate('/instructorhome')
    }
    const handleCourseCreateSuccessful = () => {
        let getRes = (res) => {
          console.log('Response received:', res)
          if (res.statusCode == 201) {
            setShowModal(true);

            // toast.success("Logged in Successfully", {
            //   autoClose: 1500,
            //   onClose: () => navigate('/instructorhome')
            // });

      } else {
        toast.error(res.message, {
          autoClose: 1500
        });
      }
    };
        let body = {
          thumbnail: courseDetail.thumbnail,
          title: courseDetail.title,
          description: courseDetail.description,
          objective: courseDetail.objective,
          level: courseDetail.level,
          books: courseDetail.books,
          content: courseDetail.content,
          price: courseDetail.price,
          quiz: courseDetail.quiz,
          assignment: courseDetail.assignment,
          courseDuration: courseDetail.courseDuration,
        };
        console.log(body);
        callApi("POST", routes.createCourse, body, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
    };
    const handleCourseEditSuccessful = () => {
      let getRes = (res) => {
        console.log('Response received:', res)
        if (res.statusCode == 200) {
          setShowModal(true);

          // toast.success("Logged in Successfully", {
          //   autoClose: 1500,
          //   onClose: () => navigate('/instructorhome')
          // });

    } else {
      toast.error(res.message, {
        autoClose: 1500
      });
    }
  };
      let body = {
        thumbnail: courseDetail.thumbnail,
        title: courseDetail.title,
        description: courseDetail.description,
        objective: courseDetail.objective,
        level: courseDetail.level,
        books: courseDetail.books,
        content: courseDetail.content,
        price: courseDetail.price,
        quiz: courseDetail.quiz,
        assignment: courseDetail.assignment,
        courseDuration: courseDetail.courseDuration,
      };
      console.log(body);
      callApi("PATCH", routes.editCourse+'/'+courseDetail.courseId, body, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
  };
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        let timer;
        if (showModal) {
          timer = setTimeout(() => {
            handleContinue();
          }, 2000);
        }
        return () => clearTimeout(timer);
      }, [showModal]);
      
  return (
    <>
    <ToastContainer/>
      <div className="overflow-hidden">
        <div className="row">
          {/* Sidebar */}
          <InstructorSidebar/>
          {/* Main Content */}
          <div className="col-md-10">
            <InstructoreHeader/>
            <div className='container-fluid my-4'>
              <h3 className='fw-bold'>Create Course</h3>
              <div className="row clearfix mt-3">
              <div className=" col-lg-6 col-md-6 col-12">
              <div className=" single_post ">
                    <div className="px-md-4 ">
                    <div className=" ">
                    <div className="img-post">
    <img className="d-block img-fluid w-100 rounded-4" src={courseDetail?.thumbnail} alt="First slide" style={{height:'300px'}}/>
</div>
<div className="d-flex justify-content-between eighteenfont my-2 "><span  style={{ textDecoration: 'none',color:'black' }}><b>{courseDetail?.title}</b></span>
<div>
<span className="badge py-2" style={{ fontSize: '14px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {courseDetail?.level}
                                        </span>
</div>
                                        </div>
                                        <div className="d-flex justify-content-between p-price mt-3"><span><b>${courseDetail?.price}</b></span><span><b>{courseDetail?.courseDuration} Course</b></span></div>
                                        <div className="d-flex flex-wrap justify-content-start my-4 border-bottom">
      <div className='col-md-5  my-2'>
        <div className="d-flex justify-content-start">
        <i className="fa-solid fa-users fa-lg mt-2"></i>
        <p className='mx-2'>8,289 Students</p>
        </div>
      </div>
      <div className='col-md-5  my-2'>
        <div className="d-flex justify-content-start">
        <i className="fa-solid fa-clock fa-lg  mt-2"></i>
        <p className='mx-2'>{courseDetail.courseDuration} Course</p>
        </div>
      </div>
    </div>
    <div className='border-bottom my-2'>
        <h6 className='eighteenfont'><b>Course Description</b></h6>
        <p dangerouslySetInnerHTML={{ __html: courseDetail?.description }}></p>
    </div>
    <div className='border-bottom my-2'>
        <h6 className='eighteenfont'><b>Course Objective</b></h6>
        <p dangerouslySetInnerHTML={{ __html: courseDetail?.objective }}></p>
    </div>
    <div className='border-bottom my-3'>
        <h6 className='eighteenfont'><b>Digital Book</b></h6>
        {courseDetail?.books && courseDetail?.books.map((book, index) => (<div className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
                      <a className="me-3" href="#">
                        <img className="rounded shadow-1-strong mb-3"
                          src={book?.bookAvatar} alt="avatar"
                          width="60" height="60" />
                      </a>
                      <div className="flex-grow-1 flex-shrink-1">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1">
                            <b>{book?.bookTitle}</b>
                            </p>
                          </div>
                          <span><b>${book?.bookPrice}</b></span>
                        </div>
                      </div>
                    </div>
                    {/* <div className='mt-1'>
                    <button className='btn rounded-pill px-5 py-2 shadow' onClick={courseenroll} style={{backgroundColor:'#1D191C', color:'white'}}>Buy Now</button>
                    </div> */}
            </div>))}

    </div>
                    </div>
    
    <div className='border-bottom my-3'>
        <h6 className='eighteenfont'><b>Course Assignment</b></h6>
        {courseDetail?.assignment && courseDetail?.assignment.map((assignment, index) => (<div key={index} className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
                      <a className="me-3" href="#">
                        <img className="rounded shadow-1-strong mb-3"
                          src={assignment?.assignmentAvatar} alt="avatar"
                          width="60" height="60" />
                      </a>
                      
                            <p className="my-3">
                            <b>Assignment {index + 1}</b>
                            </p>
                          
                    </div>
                    
            </div>))}

    </div>
    <div className='border-bottom my-3'>
        <h6 className='eighteenfont'><b>Course Quiz</b></h6>
        {courseDetail?.quiz && courseDetail?.quiz.map((quiz, index) => (<div key={index} className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
                      <a className="me-3" href="#">
                        <img className="rounded shadow-1-strong mb-3"
                          src="./img/courses/coursedetails/coursequiz.png" alt="avatar"
                          width="60" height="60" />
                      </a>
                      
                            <p className="my-3">
                            <b>Quiz {index + 1}</b>
                            </p>
                          
                    </div>
                    
            </div>))}

    </div>
    <div className=' my-3'>
        <h6 className='eighteenfont'><b>Course Content</b></h6>
        {courseDetail?.content && courseDetail?.content.map((content, index) => (<div key={index} className="my-3 py-2 shadow rounded-2">
    <div className="d-flex justify-content-between align-items-center px-3 py-1">
        <div className="d-flex flex-start">
            <p className="m-0"><b>{content?.contentTitle}</b></p>
        </div>
        <div>
        <i className="fa-solid fa-circle-play fa-lg"></i>
        </div>
    </div>
</div>))}
    




    </div>
    {courseDetail?.courseId ? 
    <div className='mt-4 mb-5'>
    <button className='btn rounded-pill px-5 py-3 shadow w-100'  onClick={(e) => { e.preventDefault(); handleCourseEditSuccessful(); }} style={{backgroundColor:'#1D191C', color:'white'}}>Save Changes</button>
    </div>
                    :<div className='mt-4 mb-5'>
                    <button className='btn rounded-pill px-5 py-3 shadow w-100'  onClick={(e) => { e.preventDefault(); handleCourseCreateSuccessful(); }} style={{backgroundColor:'#1D191C', color:'white'}}>Continue</button>
                    </div>}
                    {showModal && (
                    <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog " style={{marginTop: '5%'}}>
                        <div className="modal-content my-1">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="paymentModalLabel">Payment Successful</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body text-center">
                                
                                <img src="./img/courses/coursepayment/coursepaymentsuccessfull.png" alt="" height='100' width='100'/>
                                <h4 className='my-3'>{courseDetail?.courseId ?<b>Course Edit Successfully!</b>:<b>Course Created Successfully!</b>}</h4>
                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.</h6>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                
                )}
                    </div>                        
                </div>
        </div>
            <div className="col-lg-5 col-md-5 col-12 left-box">
                
                
                
            </div>
        
        </div>
            </div>
          
          </div>
          
        </div>
      </div>
    
    </>
  )
}

export default InstructorCreateCourseDetails