import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';

const PopularWorkout = () => {
  const [workouts, setworkouts] = useState([]);
  const [isloading, setIsLoading] = useState(false);

    const getWorkout = () => {
    
        let getRes = (res) => {
          //  localStorage.setItem("userData", res?.data?.user);
          setworkouts(res.data.docs);
        //   console.log(res.data.docs)
        };
    
        callApi("GET", routes.workouts, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
    
        getWorkout();
      }, []);
      const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setFilteredProducts(workouts);
  }, [workouts]);
  const handleSearch = (query) => {
    if (query.trim() === '') {
      // If search query is empty, show all products
      setFilteredProducts(workouts);
    } else {
      // Otherwise, filter products based on the search query
      const filtered = workouts.filter(workout =>
        workout?.workoutTitle?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };
  return (
    <>
    <div className="row">
    <div className="col-md-6 col-12 mt-md-5 mx-md-4 px-md-2">
        <div className="banner-container">
            <h1 className="banner ">Popular Workout for you</h1>
            <div className="banner-underline"></div>
        </div>
<span className="text-subheading mt-4">Discover our top-rated workout programs designed to fit all fitness levels and goals. From strength training to high-intensity interval workouts, these popular routines are crafted to maximize results and keep you motivated.  </span>
</div>
<div className="col-md-5 d-flex align-items-center justify-content-center my-4">
        <div className="input-group mt-md-5 mx-md-4" style={{ width: '100%', maxWidth: '400px' }}>
          <input
            type="text"
            value={searchQuery}
            onChange={handleChange}
            className="form-control rounded-pill px-3 py-2"
            placeholder="Search workouts"
            style={{ borderColor: '#1D191C', paddingRight: '2.5rem' }}
          />
          <span className="position-absolute end-0 me-3 mt-2">
            <i className="fas fa-search"></i>
          </span>
        </div>
      </div>
    </div>
                <div className="workout-card-padded">
      <div className="my-5 mx-md-4">
        <div className="row">
        {filteredProducts && filteredProducts.map((workout, index) => (
        <div className="col-xs-12 col-sm-6 col-lg-3" key={index}>
            <div className="workout-card" >
              <div className="workout-card-image" style={{ backgroundImage: `url(${workout?.workoutThumbnail})` }}>
                {/* <div className="workout-card-category">City</div> */}
              <div className="workout-card-description">
                <h2 >{workout?.workoutTitle}</h2>
                <p>{workout?.workoutDuration} | {workout?.workoutLevel}</p>
              </div>
              {/* <img className="workout-card-user workout-card-avatar workout-card-avatar-large" src="https://github.com/lewagon/bootstrap-challenges/blob/master/11-Airbnb-search-form/images/anne.jpg?raw=true" alt="User" /> */}
              <Link className="workout-card-link" to={{
            pathname: "/workoutdetails",
            search: `?workoutId=${workout?._id}`, 
          }} ></Link>
              </div>
            </div>
          </div>))}
          
        </div>
      </div>
    </div>
    </>
  )
}

export default PopularWorkout