import React, { useEffect, useState } from 'react'
import routes from '../../Api/routes';
import { callApi } from '../../Api/ApiCaller';

const PrivacyPolicy = () => {
    const [policy, setPolicy] = useState('');
  const [isloading, setIsLoading] = useState(false);

    const getPolicy = () => {
        let getRes = (res) => {
          setPolicy(res?.policy);
        };
    
        callApi("GET", routes.policy, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
        // console.log(detail);

        getPolicy();
      }, []);
      function decodeHtml(html) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
      }
  return (
    <>
    <div className="container">
    <div className="row justify-content-center">
        <div className="col-md-5 col-12">
            <div className="banner-container text-center mt-5">
                <h1 className="banner-terms">Privacy Policy</h1>
                <div className="banner-terms-underline"></div>
            </div>
            {/* <h5 className="banner-employes ">saying about us</h5> */}
        </div>
    </div>
 
    <div className="row justify-content-center mb-5">
        <div className="col-md-12">
        
        {policy &&  <p className="text-center my-4" dangerouslySetInnerHTML={{ __html: decodeHtml(policy?.content) }}></p>}
                      


            
        </div>
    </div>


</div>

    </>
  )
}

export default PrivacyPolicy