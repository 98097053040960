export const BASE_URL =
  "https://api.theechoapp.com/api/v1";

export default {
  // -----AUTH------//
  authenticateAppImage: BASE_URL + "/app-image",
  signUp: BASE_URL + "/users/signup",
  signIn: BASE_URL + "/users/login",
  signInAdmin: BASE_URL + "/user/admin/login",
  sendOTP: BASE_URL + "/user/sendOTP",
  verifyOTP: BASE_URL + "/users/verify-otp",
  forgotPassword: BASE_URL + "/users/forgot-password",
  verifyForgotPasswordOtp: BASE_URL + "/users/verify-otp-forgot-password",
  resetPasswords: BASE_URL + "/users/reset-password",
  resendOtp: BASE_URL + "/users/resend-otp",
  changePassword: BASE_URL + "/users/change-password",
  verifyOTPresetPassword: BASE_URL + "/user/verifyOTPResetPassword",
  logOut: BASE_URL + "/users/logout",
  editUser: BASE_URL + "/users/edit-profile",
  completeProfile: BASE_URL + "/users/build-profile",
  otpForDeletedMe: BASE_URL + "/users/send-otp-for-deleting",
  deleteMe: BASE_URL + "/users/delete-me",
  profile:BASE_URL + "/users/me",
  editProfile: BASE_URL + "/users/edit-profile",
  // -----Stats-------//
  getStats: BASE_URL + "/user/stats",

  /// User
  user: BASE_URL + "/user",
/// About Us
aboutUs: BASE_URL + "/about",
ourMission: BASE_URL + "/our-mission", 
socialMediaLinks: BASE_URL + "/social-media-links",
terms: BASE_URL + "/terms",
policy: BASE_URL + "/policy",

// Reviews

reviews: BASE_URL + "/reviews",
  // Courses
 courses: BASE_URL + "/courses",
 coursedetails: BASE_URL + "/courses/get-one-course-web",

   // Workouts
   workouts: BASE_URL + "/workouts",
   workoutdetails: BASE_URL + "/workouts/get-one-workout-web",

   // Courses
 courseEnrollment: BASE_URL + "/enroll/create-enrollment-order",
 confirmPayment: BASE_URL + "/enroll/confirm-enrollment-payment/enrollment-web-hook",
 addBankDetails: BASE_URL + "/users/stripe/add-bank",
 checkBankInfo: BASE_URL + "/users/check-bank-info",
//  coursedetails: BASE_URL + "/courses/get-one-course-web",

// Progress Tracking
ongoingCourses: BASE_URL + "/progress/get-ongoing-courses",
completeCourses: BASE_URL + "/progress/get-completed-courses",
ongoingWorkouts: BASE_URL + "/progress/get-ongoing-workouts",
completeWorkouts: BASE_URL + "/progress/get-completed-workouts",
markVideoComplete: BASE_URL + "/progress/mark-video-complete",
getOneOngoingCourses: BASE_URL + "/progress/get-one-ongoing-course",
uploadCourseAssignment: BASE_URL + "/progress/upload-course-assignment",
markQuizComplete: BASE_URL + "/progress/mark-quiz-complete",
getOneOngoingWorkout: BASE_URL + "/progress/get-one-ongoing-workout",
markCourseComplete: BASE_URL + "/progress/mark-course-complete",
markWorkoutComplete: BASE_URL + "/progress/mark-workout-complete",
getallprogress: BASE_URL + "/progress/progress-tracking-for-all-courses/?year = 2024",
// Interests

interests: BASE_URL + "/interests",

// Goals

goals: BASE_URL + "/goals",

// Goals

expertise: BASE_URL + "/expertise",

// Contact Us
contactUs: BASE_URL + "/contact/contact-us",

// Course Instructor
myCourses: BASE_URL + "/courses/get-my-courses",
createCourse: BASE_URL + "/courses/create-course",
editCourse: BASE_URL + "/courses/update-course",
deleteCourse: BASE_URL + "/courses/delete-course",
// Workout Trainer
myWorkouts: BASE_URL + "/workouts/get-my-workouts",
createWorkout: BASE_URL + "/workouts/create-workout",
};
