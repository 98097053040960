import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './instructorhome.css';
import InstructorSidebar from './InstructorSidebar';
import InstructoreHeader from './InstructoreHeader';
import { S3 } from "aws-sdk";
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';
import 'react-quill/dist/quill.snow.css'; // Import React Quill styles
import ReactQuill from 'react-quill';
const InstructorEditCourse = () => {
    const [courseTitle, setCourseTitle] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseObjective, setCourseObjective] = useState("");
  const [totalCourseDuration, setTotalCourseDuration] = useState("");

  const [coursePrice, setCoursePrice] = useState("");

  const [selectedLevel, setSelectedLevel] = useState("");
  const [isDropdownLevelOpen, setIsDropdownLevelOpen] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [showDropZone, setShowDropZone] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [videos, setVideos] = useState([]);
  const [currentTitle, setCurrentTitle] = useState('');
  // const [currentVideoURL, setCurrentVideoURL] = useState('');
  const [uploadedBookCover, setUploadedBookCover] = useState('');
  const [bookAvatar, setBookAvatar] = useState('');
  const [uploadedAssignmentCover, setUploadedAssignmentCover] = useState('');
  const [assignmentAvatar, setAssignmentAvatar] = useState('');
  const [showUploader, setShowUploader] = useState(true);
  const [selectedassignmentFileName, setSelectedassignmentFileName] = useState(null);
  const [selectedquizFileName, setSelectedquizFileName] = useState(null);
  const [bookTitle, setBookTitle] = useState('');
  const [bookUrl, setBookUrl] = useState('');
  const [bookPrice, setBookPrice] = useState('');
  const [books, setBooks] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [currentAssignmentURL, setCurrentAssignmentURL] = useState('');
  const navigate = useNavigate();
  const [CompleteCourse, setCompleteCourse] = useState('');
  const handleDescriptionChange = (value) => {
    setCourseDescription(value);
  };
  const handleObjectiveChange = (value) => {
    setCourseObjective(value);
  };
  function decodeHtml(html) {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
//   Edit the Course Setting
const [isloading, setIsLoading] = useState(false);
  const [coursedetail, setcoursedetail] = useState([]);
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const courseId = queryParams.get("courseId");
    const getCourseDetail = () => {
      
      let getRes = (res) => {
        //  localStorage.setItem("userData", res?.data?.user);
        setcoursedetail(res.courseInfo);
        setImageURL(res.courseInfo.thumbnail);
        setCourseTitle(res.courseInfo.title);
        setSelectedLevel(res.courseInfo.level);
        setCourseDescription(decodeHtml(res.courseInfo.description));
        setCourseObjective(decodeHtml(res.courseInfo.objective));
        setTotalCourseDuration(res.courseInfo.courseDuration);
        setBooks(res.courseInfo.books);
        setVideos(res.courseInfo.content);
        setCoursePrice(res.courseInfo.price);
        setQuizzes(res.courseInfo.quiz);
        setAssignments(res.courseInfo.assignment);
        // setQuizzes(res.courseInfo.quiz);



        // setproducts(res.data.data.products);
      //   console.log(productId)
      };
  
      callApi("GET", routes.coursedetails+'/'+courseId, null, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
    };
    useEffect(() => {
      if (courseId) {
          getCourseDetail();
      }
    }, [courseId]);

  const handleLevelChange = (value) => {
    setSelectedLevel(value);
    setIsDropdownLevelOpen(false);
  };

  const toggleDropdownLevel = () => {
    setIsDropdownLevelOpen(!isDropdownLevelOpen);
  };

 
  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = await uploadImageOnS3(file);
      setImageURL(fileUrl);
      setShowDropZone(false);
      // console.log(fileUrl);
    }
  };
  const uploadImageOnS3 = async (file) => {
    const s3bucket = new S3({
      region: 'us-east-2',
      accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        // setImageURL(reader.result);
        const params = {
          Bucket: 's3echobucket',
          Key: file.name,
          Body: arrayBuffer,
          ContentDisposition: `inline;filename="${file.name}"`,
          ContentType: file.type,
        };

        s3bucket.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  // const handleFileUpload = (event) => {
  //   const uploadedFile = event.target.files[0];
  //   if (uploadedFile) {
  //     setUploadedFileName(uploadedFile.name);
  //   }
  // };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFileName(file.name);
      const fileUrl = await uploadbookOnS3(file);
      setBookUrl(fileUrl);
      // setShowDropZone(false);
      // console.log(fileUrl);
    }
  };
  const uploadbookOnS3 = async (file) => {
    const s3bucket = new S3({
      region: 'us-east-2',
      accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        // setImageURL(reader.result);
        const params = {
          Bucket: 's3echobucket',
          Key: file.name,
          Body: arrayBuffer,
          ContentDisposition: `inline;filename="${file.name}"`,
          ContentType: file.type,
        };

        s3bucket.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  const handleBookCoverUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedBookCover(file.name);
      const fileUrl = await uploadbookcoverOnS3(file);
      setBookAvatar(fileUrl);
      // setShowDropZone(false);
      // console.log(fileUrl);
    }
  };
  const uploadbookcoverOnS3 = async (file) => {
    const s3bucket = new S3({
      region: 'us-east-2',
      accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        // setImageURL(reader.result);
        const params = {
          Bucket: 's3echobucket',
          Key: file.name,
          Body: arrayBuffer,
          ContentDisposition: `inline;filename="${file.name}"`,
          ContentType: file.type,
        };

        s3bucket.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  // const handleVideoUpload = (event) => {
  //   const file = event.target.files[0];
  //   const videoURL = URL.createObjectURL(file);
  //   setVideoURL(videoURL);
  //   setShowUploader(false);
  // };
  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = await uploadVideoOnS3(file);
      setVideoURL(fileUrl);
      setShowUploader(false);
      // console.log(fileUrl);
    }
  };
  const uploadVideoOnS3 = async (file) => {
    const s3bucket = new S3({
      region: 'us-east-2',
      accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        // setVideoURL(reader.result);
        const params = {
          Bucket: 's3echobucket',
          Key: file.name,
          Body: arrayBuffer,
          ContentDisposition: `inline;filename="${file.name}"`,
          ContentType: file.type,
        };

        s3bucket.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  const handleAssignmentCoverUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedAssignmentCover(file.name);
      const fileUrl = await uploadAssignmentcoverOnS3(file);
      setAssignmentAvatar(fileUrl);
      // setShowDropZone(false);
      // console.log(fileUrl);
    }
  };
  const uploadAssignmentcoverOnS3 = async (file) => {
    const s3bucket = new S3({
      region: 'us-east-2',
      accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        // setImageURL(reader.result);
        const params = {
          Bucket: 's3echobucket',
          Key: file.name,
          Body: arrayBuffer,
          ContentDisposition: `inline;filename="${file.name}"`,
          ContentType: file.type,
        };

        s3bucket.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  // const handleassignmentFileSelect = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedassignmentFileName(file.name);
  //   const assignmentURL = URL.createObjectURL(file);
  //   setCurrentAssignmentURL(assignmentURL);
  // };
  const handleassignmentFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedassignmentFileName(file.name);
      const fileUrl = await uploadAssignmentOnS3(file);
      setCurrentAssignmentURL(fileUrl);
      // setShowDropZone(false);
      console.log(fileUrl);
    }
  };
  const uploadAssignmentOnS3 = async (file) => {
    const s3bucket = new S3({
      region: 'us-east-2',
      accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        // setCurrentAssignmentURL(reader.result);
        const params = {
          Bucket: 's3echobucket',
          Key: file.name,
          Body: arrayBuffer,
          ContentDisposition: `inline;filename="${file.name}"`,
          ContentType: file.type,
        };

        s3bucket.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  const handleAssignmentFileRemove = () => {
    setSelectedassignmentFileName('');
    setCurrentAssignmentURL('');
  };

  const handlequizFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedquizFileName(file.name);
  };

  const handlequizFileRemove = () => {
    setSelectedquizFileName(null);
  };

  const handleAddBook = () => {
    if (bookTitle && bookUrl && bookPrice && bookAvatar) {
      setBooks([...books, { bookTitle, bookUrl, bookPrice, bookAvatar }]);
      setBookTitle('');
      setBookUrl('');
      setBookPrice('');
      setBookAvatar('');
    } else {
      alert('Please fill in all fields');
    }
  };
  const handleAddVideo = () => {
    if (currentTitle && videoURL) {
      setVideos([...videos, { contentTitle: currentTitle, contentUrl: videoURL }]);
      setCurrentTitle('');
      setVideoURL('');
      setShowUploader(true);
    } else {
      alert('Please provide a title or wait to upload a video');
    }
  };

  const handleDeleteVideo = (index) => {
    const newVideos = [...videos];
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };
  const handleAddAssignment = () => {
    if (currentAssignmentURL && assignmentAvatar) {
      setAssignments([...assignments, { assignmentUrl: currentAssignmentURL, assignmentFileName: selectedassignmentFileName, assignmentAvatar: assignmentAvatar }]);
      setSelectedassignmentFileName('');
      setCurrentAssignmentURL('');
      setAssignmentAvatar('');
    } else {
      alert('Please upload an assignment and Cover');
    }
  };

  const handleDeleteAssignment = (index) => {
    const newAssignments = [...assignments];
    newAssignments.splice(index, 1);
    setAssignments(newAssignments);
  };
  const [quizzes, setQuizzes] = useState([]);
  const [currentQuiz, setCurrentQuiz] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({ text: '', options: [{ option: '', isOptionCorrect: false }, { option: '', isOptionCorrect: false }] });

  const handleQuestionChange = (e) => {
    setCurrentQuestion({ ...currentQuestion, text: e.target.value });
  };

  const handleOptionChange = (index, value) => {
    const newOptions = currentQuestion.options.map((option, i) => (i === index ? { ...option, option: value } : option));
    setCurrentQuestion({ ...currentQuestion, options: newOptions });
  };

  const handleCorrectOptionChange = (index) => {
    const newOptions = currentQuestion.options.map((option, i) => ({
      ...option,
      isOptionCorrect: i === index ? !option.isOptionCorrect : false,
    }));
    setCurrentQuestion({ ...currentQuestion, options: newOptions });
  };

  const handleAddOption = () => {
    if (currentQuestion.options.length < 5) {
      setCurrentQuestion({ ...currentQuestion, options: [...currentQuestion.options, { option: '', isOptionCorrect: false }] });
    }
  };

  const handleAddQuestion = () => {
    if (currentQuestion.text && currentQuestion.options.every(opt => opt.option)) {
      if (currentQuestion.options.some(opt => opt.isOptionCorrect)) {
        setCurrentQuiz([...currentQuiz, currentQuestion]);
        setCurrentQuestion({ text: '', options: [{ option: '', isOptionCorrect: false }, { option: '', isOptionCorrect: false }] });
      } else {
        alert('Please mark at least one option as correct.');
      }
    } else {
      alert('Please provide a question and at least two options.');
    }
  };

  const handleCreateQuiz = () => {
    if (currentQuiz.length > 0) {
      setQuizzes([...quizzes, { questions: currentQuiz }]);
      setCurrentQuiz([]);
    } else {
      alert('Please add at least one question to the quiz.');
    }
  };

  const handleDeleteQuiz = (index) => {
    const updatedQuizzes = quizzes.filter((quiz, i) => i !== index);
    setQuizzes(updatedQuizzes);
  };
  const handlecontinue = () => {
    const completeCourseData = {
      courseId: courseId,  
      thumbnail: imageURL,
      title: courseTitle,
      description: courseDescription,
      objective: courseObjective,
      level: selectedLevel,
      books: books,
      content: videos,
      price: coursePrice,
      quiz: quizzes,
      assignment: assignments,
      courseDuration: totalCourseDuration,
    };
    if (!imageURL || !courseTitle || !courseDescription || !courseObjective || !selectedLevel || !coursePrice) {
      alert('Please fill in all the required details.');
      
    }
    else{
      setCompleteCourse(completeCourseData);
    console.log(completeCourseData);
    navigate('/instructorcreatecoursedetails' , { state: { courseDetail: completeCourseData } });
    }
    // setCompleteCourse(completeCourseData);
    // console.log(completeCourseData);
  };
  // const handlecontinue = () => {
  //   handleAddBook();
  //   handleAddVideo();
  //   handleAddAssignment();
  // };

  // useEffect(() => {
  //   if (books.length > 0 || videos.length > 0 || assignments.length > 0) {
  //     console.log('Books:', books);
  //     console.log('Videos:', videos);
  //     console.log('Assignments:', assignments);
  //     // navigate('/instructorcreatecoursedetails');
  //   }
  // }, [books, videos, assignments]);
  return (
    <>
    <div className="overflow-hidden">
      <div className="row " style={{marginBottom:'5%'}}>
        {/* Sidebar */}
        <InstructorSidebar/>
        {/* Main Content */}
        <div className="col-md-10">
          <InstructoreHeader/>
          <div className='container-fluid my-4'>
            <h3 className='fw-bold'>Create Course</h3>
            <div className='row'>
           <div className="col-md-6 col-12 ">
           <div className="row h-100 instructorcreatecoursemidborder p-2">
           <div>
            {showDropZone && (
                <div className="instructordropzone text-center d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <img src="./img/courseinstructor/home/Camera.png" className="instructorupload-icon" alt="Upload Icon" />
                        <span className="d-block mt-2 fw-bold">Upload Image</span>
                    </div>
                    <input
                        type="file"
                        className="instructorupload-input"
                        accept="image/*"
                        onChange={handleFileSelect}
                    />
                </div>
            )}
            {imageURL && !showDropZone && (
                <>
                
                <div className="instructordropzoneuploadimg-container">
    <div className="instructordropzoneuploadimg">
        <img src={imageURL} alt="Uploaded" className="instructoruploaded-image" />
        <div>
        
        <img src="./img/courseinstructor/home/Group 1686554026.png" alt="" className="bottom-right-image" />
        <input
            type="file"
            className="instructoruploadimg-input rounded-circle"
            accept="image/*"
            onChange={handleFileSelect}
        /> 
        </div>
        {/* Uncomment the following input field if needed */}
        {/* <input
            type="file"
            className="instructoruploadimg-input"
            accept="image/*"
            onChange={handleFileSelect}
        /> */}
    </div>
</div>
                
                </>
            )}
        </div>
    <div className='formfieldwidth'>
    <div className="form-group my-3">
                                <label className="form-label fw-bold" htmlFor="coursetitle">Course Title</label>
                                <input type="text" className="form-control rounded-4 py-2 px-2 border-0" id="coursetitle" value={courseTitle} onChange={(e) => setCourseTitle(e.target.value)} placeholder="Course Title" style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
                            <div className="form-group my-3">
                        <label
                          className="form-label fw-bold"
                          htmlFor="interestSelect"
                        >
                          Course Level
                        </label>
                      <div className="position-relative">
                          <div className="custom-dropdown">
                            <div
                              className=" rounded-4 py-3 px-2 border-0"
                              style={{
                                backgroundColor: "#FAFAFA",
                                height: "52px",
                              }}
                            >
                              {selectedLevel
                                ? selectedLevel
                                : <span className="text-low">Dropdown to select</span>}
                              <span
                                className="position-absolute top-50 end-0 translate-middle-y pe-3 dropdown-label"
                                onClick={toggleDropdownLevel}
                              >
                                <img
                                  src="./img/user/signup/Arrow - Down 2.png"
                                  alt=""
                                />
                              </span>
                            </div>
                            {isDropdownLevelOpen && (
                              <div className="options p-2">
                                <div className="row">
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Beginner</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Beginner"
                                        checked={selectedLevel === "beginner"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("beginner")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center ">
                                      <span>Intermediate</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Intermediate"
                                        checked={selectedLevel === "intermediate"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("intermediate")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Advance</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Advance"
                                        checked={selectedLevel === "advance"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("advance")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>All</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="All"
                                        checked={selectedLevel === "all"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("all")
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
          <label className="form-label fw-bold" htmlFor="coursedescription">Course Description</label>
          <ReactQuill
            theme="snow"
            value={courseDescription}
            onChange={handleDescriptionChange}
            placeholder="Description"
            style={{ backgroundColor: '#FAFAFA' }}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                // ['link', 'image', 'video'],
                ['clean']
              ],
            }}
          />
        </div>
        <div className="form-group">
          <label className="form-label fw-bold" htmlFor="coursedescription">Course Description</label>
          <ReactQuill
            theme="snow"
            value={courseObjective}
            onChange={handleObjectiveChange}
            placeholder="Objective"
            style={{ backgroundColor: '#FAFAFA' }}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                // ['link', 'image', 'video'],
                ['clean']
              ],
            }}
          />
        </div>
                            <div className="form-group my-3 border-bottom pb-4">
                                <label className="form-label fw-bold" htmlFor="totalworkout">Total Course Duration</label>
                                <input type="text" className="form-control rounded-4 py-2 px-2 border-0" id="totalcourseduration" value={totalCourseDuration} onChange={(e) => setTotalCourseDuration(e.target.value)} placeholder="Total Course Duration" style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
                            <h5 className='fw-bold'>Course Books</h5>
                            <div className="form-group my-3 border-bottom pb-4">
        <label className="form-label fw-bold" htmlFor="booktitle">Book Title</label>
        <input 
          type="text" 
          className="form-control rounded-4 py-2 px-2 border-0" 
          id="booktitle" 
          placeholder="Title" 
          style={{ backgroundColor: '#FAFAFA', height: '52px' }}  
          value={bookTitle}
          onChange={(e) => setBookTitle(e.target.value)}
        />
      </div>
      <div className="form-group my-3">
        <label className="form-label fw-bold" htmlFor="bookurl">Upload Book Cover</label>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control py-2 px-2 border-0 uploadbookinput"
            id="bookurl"
            placeholder="Cover Title"
            value={uploadedBookCover}
            readOnly
          />
          <label htmlFor="bookcover-upload" className="btn border mb-0 px-3 uploadbookbrowse">
            Browse
            <input
              id="bookcover-upload"
              type="file"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={(e) => {
                handleBookCoverUpload(e);
                if (e.target.files[0]) {
                  setBookAvatar(URL.createObjectURL(e.target.files[0]));
                }
              }}
            />
          </label>
        </div>
        
      </div>
      <div className="form-group my-3">
        <label className="form-label fw-bold" htmlFor="bookurl">Upload Book</label>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control py-2 px-2 border-0 uploadbookinput"
            id="bookurl"
            placeholder="Course Title"
            value={uploadedFileName}
            readOnly
          />
          <label htmlFor="file-upload" className="btn border mb-0 px-3 uploadbookbrowse">
            Browse
            <input
              id="file-upload"
              type="file"
              style={{ display: 'none' }}
              accept=".pdf,.doc,.docx,.txt"
              onChange={(e) => {
                handleFileUpload(e);
                if (e.target.files[0]) {
                  setBookUrl(URL.createObjectURL(e.target.files[0]));
                }
              }}
            />
          </label>
        </div>
      </div>
      <div className="form-group my-3 border-bottom pb-4">
        <label className="form-label fw-bold" htmlFor="bookprice">Book Price</label>
        <input 
          type="number" 
          step="0.01" 
          min="0" 
          className="form-control rounded-4 py-2 px-2 border-0" 
          id="bookprice" 
          placeholder="Price" 
          style={{ backgroundColor: '#FAFAFA', height: '52px' }}  
          value={bookPrice}
          onChange={(e) => setBookPrice(e.target.value)}
        />
      </div>
      
      <div className="d-flex justify-content-center align-items-center my-3">
  <img src="./img/courseinstructor/home/Group 1686554024.png" style={{cursor:'pointer'}} onClick={handleAddBook} className='img-fluid' alt="" />
  
</div>
<div className='text-center'>
<h6><b>Upload Book</b></h6>

</div>
      {books.length > 0 && (
        <div className="mt-4">
          <h4>Added Books</h4>
          <ul className="list-group">
            {books.map((book, index) => (
              <li key={index} className="list-group-item">
                <p className='my-0'><strong>Title:</strong> {book.bookTitle}</p>
                {/* <p><strong>URL:</strong> <a href={book.bookUrl} target="_blank" rel="noopener noreferrer">{book.bookUrl}</a></p> */}
                <p className='my-0'><strong>Price:</strong> ${book.bookPrice}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
                            <h5 className='fw-bold'>Course Content</h5>
                            {videos.map((video, index) => (
        <div key={index} className="form-group my-3">
          <label className="form-label fw-bold">Upload Videos</label>
          <div className="d-flex align-items-center">
            <div>
              <span className='border-0 px-4 py-3 rounded-circle' style={{ backgroundColor: '#1D191C1A', height: '52px' }}>{index + 1}</span>
            </div>
            <div className="d-flex align-items-center w-100">
              <input
                type="text"
                className="form-control py-2 px-2 border-0 rounded-4 mx-3"
                style={{ backgroundColor: '#FAFAFA', height: '52px' }}
                value={video.contentTitle}
                readOnly
              />
              <img
                src="./img/courseinstructor/home/Delete.png"
                className=''
                alt=""
                onClick={() => handleDeleteVideo(index)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>
      ))}

      <div className="form-group my-3">
        <label className="form-label fw-bold" htmlFor="videotitle">Video Title</label>
        <input
          type="text"
          className="form-control py-2 px-2 border-0 rounded-4"
          id="videotitle"
          placeholder="Title"
          style={{ backgroundColor: '#FAFAFA', height: '52px' }}
          value={currentTitle}
          onChange={(e) => setCurrentTitle(e.target.value)}
        />
      </div>
      </div>
      
      <div>
      {showUploader ? (
                <div className="instructordropzone text-center d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <img src="./img/courseinstructor/home/Video.png" className="instructorupload-icon" alt="Upload Icon" />
                        <span className="d-block mt-2 fw-bold">Upload Video</span>
                    </div>
                    <input type="file" className="instructorupload-input" accept="video/*" onChange={handleVideoUpload} />
                </div>
            ) : (
              <>
              
                <div className="instructordropzoneuploadvideo">
                    <video className="instructoruploaded-video" controls>
                        <source src={videoURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {/* <input
                        type="file"
                        className="instructoruploadvideo-input"
                        accept="image/*"
                        onChange={handleFileSelect}
                    /> */}
                </div>
              </>
            )}
      </div>
<div className="formfieldwidth">
<div className="d-flex justify-content-center align-items-center my-3">
  <img src="./img/courseinstructor/home/Group 1686554024.png" style={{cursor:'pointer'}} onClick={handleAddVideo} className='img-fluid' alt="" />
  
</div>
<div className='text-center'>
<h6><b>Upload Content</b></h6>

</div>
<div className="form-group my-3 ">
                                <label className="form-label fw-bold" htmlFor="bookprice">Course Price</label>
                                <input type="number" min="0" className="form-control rounded-4 py-2 px-2 border-0" id="courseprice" value={coursePrice} onChange={(e) => setCoursePrice(e.target.value)} placeholder="Course Price" style={{ backgroundColor: '#FAFAFA', height: '52px' }}  />
                            </div>
</div>


</div>

           </div>
           <div className="col-md-6 paddingtoadjustmidcreatecourse" >
           <div className="position-relative instructordropzone text-center d-flex flex-column justify-content-center align-items-center mt-2">
        <div>
          <img src="./img/courseinstructor/home/Document.png" className="instructorupload-icon" alt="Upload Icon" />
          <span className="d-block mt-2 fw-bold">{selectedassignmentFileName ? selectedassignmentFileName : 'Upload Assignment'}</span>
        </div>
        {selectedassignmentFileName ? (
          <>
            <img src="./img/courseinstructor/home/Layer 2.png" alt="" onClick={handleAssignmentFileRemove} className="position-absolute top-0 end-0 mt-0 mr-3" style={{ zIndex: 1, cursor: 'pointer' }} />
            <div className="instructoruploadassignment-input"></div>
          </>
        ) : (
          <>
            <input
              type="file"
              className="instructorupload-input"
              accept=".pdf,.doc,.docx,.txt"
              onChange={handleassignmentFileSelect}
            />
          </>
        )}
      </div>
      <div className="form-group my-3">
        <label className="form-label fw-bold" htmlFor="bookurl">Upload Assignmant Cover</label>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control py-2 px-2 border-0 uploadbookinput"
            id="bookurl"
            placeholder="Cover Title"
            value={uploadedAssignmentCover}
            readOnly
          />
          <label htmlFor="assignmentcover-upload" className="btn border mb-0 px-3 uploadbookbrowse">
            Browse
            <input
              id="assignmentcover-upload"
              type="file"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={(e) => {
                handleAssignmentCoverUpload(e);
                if (e.target.files[0]) {
                  setAssignmentAvatar(URL.createObjectURL(e.target.files[0]));
                }
              }}
            />
          </label>
        </div>
        
      </div>
      <div>
        {assignments.map((assignment, index) => (
          <div key={index} className="position-relative d-flex justify-content-between align-items-center mt-2">
            <div>{assignment.assignmentFileName ? assignment.assignmentFileName: <>Assignment {index+1}</>}</div>
            <img src="./img/courseinstructor/home/Layer 2.png" alt="Delete" onClick={() => handleDeleteAssignment(index)} className="ml-2" style={{ cursor: 'pointer' }} />
          </div>
        ))}
      </div>
      <div className="formfieldwidth">
<div className="d-flex justify-content-center align-items-center my-3">
  <img src="./img/courseinstructor/home/Group 1686554024.png" style={{cursor:'pointer'}} onClick={handleAddAssignment} className='img-fluid' alt="" />
  
</div>
<div className='text-center'>
<h6><b>Upload Assignment</b></h6>

</div>
<div className="form-group my-4 ">
                                <label className="form-label fw-bold" htmlFor="bookprice">Course Quizes</label>
                            </div>
</div>
<div className="form-group my-3">
        <label className="form-label fw-bold">Question</label>
        <input
          type="text"
          className="form-control rounded-4 py-2 px-2 border-0"
          value={currentQuestion.text}
          onChange={handleQuestionChange}
          placeholder="Enter your question"
          style={{ backgroundColor: '#FAFAFA', height: '52px' }}
        />
      </div>
      {currentQuestion.options.map((option, index) => (
        <div key={index} className="form-group my-3 d-flex align-items-center">
          <label className="form-label fw-bold me-2">Option {index + 1}</label>
          <input
            type="text"
            className="form-control py-2 px-2 border-0 rounded-4"
            value={option.option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            placeholder={`Enter option ${index + 1}`}
            style={{ backgroundColor: '#FAFAFA', height: '52px' }}
          />
          <input
            type="checkbox"
            className="ms-2 form-check-input shadow rounded-2"
            style={{ width: '24px', height: '24px' }}
            checked={option.isOptionCorrect}
            onChange={() => handleCorrectOptionChange(index)}
          />
          {/* <span className="ms-2">Correct</span> */}
        </div>
      ))}
      {currentQuestion.options.length < 5 && (
        <button className="btn btn-primary my-2 mx-1" style={{backgroundColor:'#1D191C', color:'white'}} onClick={handleAddOption}>Add Option</button>
      )}
      <button className="btn btn-success my-2 mx-1" style={{backgroundColor:'#1D191C', color:'white'}} onClick={handleAddQuestion}>Add Question</button>
      <button className="btn btn-warning my-2 mx-1" style={{backgroundColor:'#1D191C', color:'white'}} onClick={handleCreateQuiz}>Create Quiz</button>
      <h3>Quizzes</h3>
      {quizzes.map((quiz, index) => (
        <div key={index} className="mb-3">
          <h4>Quiz {index + 1}</h4>
          {quiz.questions.map((question, qIndex) => (
            <div key={qIndex} className="mb-2">
              <p>{question.text}</p>
              <ul>
                {question.options.map((opt, oIndex) => (
                  <li key={oIndex} className={opt.isOptionCorrect ? 'text-success' : ''}>
                    {opt.option}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <button className="btn btn-danger" onClick={() => handleDeleteQuiz(index)}>Delete Quiz</button>
        </div>
      ))}

<div className="formfieldwidth">
{/* <div className="d-flex justify-content-center align-items-center my-3">
  <img src="./img/courseinstructor/home/Group 1686554024.png" style={{cursor:'pointer'}} className='img-fluid' alt="" />
  
</div>
<div className='text-center'>
<h6><b>Add more</b></h6>

</div> */}
<div className='my-5 col-md-12'>
                    <button className='btn rounded-pill px-5 py-4 shadow w-100 fw-bold' onClick={handlecontinue}  style={{backgroundColor:'#1D191C', color:'white'}}>Continue</button>
                    </div>
</div>
           </div>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  </>
  )
}

export default InstructorEditCourse