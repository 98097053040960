import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../CourseInstructor/Dashboard/instructorhome.css';
import InstructorSidebar from '../../CourseInstructor/Dashboard/InstructorSidebar';
import InstructoreHeader from '../../CourseInstructor/Dashboard/InstructoreHeader';
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const TrainerHome = () => {
  const [myWorkouts, setmyWorkouts] = useState('')
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const getmyWorkouts = () => {
    let getRes = (res) => {
      setmyWorkouts(res?.workouts);
    };

    callApi("GET", routes.myWorkouts, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  
  useEffect(() => {
    // console.log(detail);
    getmyWorkouts()
  }, [])
  const checkBankInfo = () => {
    let getRes = (res) => {
      if (res.isBankAdded) {
        navigate('/trainercreateworkout');
        // toast.success(res.message, {
        //   autoClose: 1500,
        //   onClose: () => {      
        //       navigate('/instructorcreatecourse');
        //   }
        //   })
            
      } else {
        toast.error(res.message, {
              autoClose: 1500
              })
      }
    };

    callApi("GET", routes.checkBankInfo, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setFilteredProducts(myWorkouts);
  }, [myWorkouts]);
  const handleSearch = (query) => {
    if (query.trim() === '') {
      // If search query is empty, show all products
      setFilteredProducts(myWorkouts);
    } else {
      // Otherwise, filter products based on the search query
      const filtered = myWorkouts.filter(course =>
        course?.workoutTitle?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };
  return (
    <>
    <div className="overflow-hidden">
      <div className="row">
        {/* Sidebar */}
        <InstructorSidebar/>
        {/* Main Content */}
        <div className="col-md-10">
          <InstructoreHeader handleSearch={handleSearch}/>
          <div className='container-fluid my-4'>
            <h3 className='fw-bold'>My Workouts</h3>
            <div className='row'>
            {filteredProducts && filteredProducts.map((workout, index) => ( <div key={index} className="col-xl-4 col-lg-6">
              <div className="d-flex justify-content-center  my-3 mx-1">
      <div className="card p-3 bg-white border-0 shadow rounded-4 w-100">
      <div className="  d-flex flex-row align-items-center ">
          <div className="about-product text-center">
              <img className='rounded-4' src={workout?.workoutThumbnail} width="80" style={{height:'80px'}} />
          </div>
          <div className="stats  flex-grow-1">
              <div className="px-3">
              <div className=" p-price ">
                  <Link  to={{
            pathname: '/trainercoursedetails',
            search: `?workoutId=${workout?._id}`, 
          }} style={{ textDecoration: 'none', color:'black' }}>
                      <h6 className="mb-0"><b>{workout?.workoutTitle}</b></h6>
                  </Link>
                  
                  </div>
                  <div className=" p-price mt-2">
                      <span style={{color:'#7F7F7F'}}>{workout?.workoutDuration} | {workout?.workoutLevel}</span>
                      
                  </div>
              </div>
          </div>
          
      </div>
      
    
      </div>
      
  </div>
              </div>))}
            {/* {[...Array(6)].map((_, index) => ( <div className="col-xl-4 col-lg-6">
              <div className="d-flex justify-content-center  my-3 mx-1">
      <div className="card p-3 bg-white border-0 shadow rounded-4 w-100">
      <div className="  d-flex flex-row align-items-center ">
          <div className="about-product text-center">
              <img className='img-fluid rounded-4' src="./img/workouttrainer/home/Rectangle 148438 (2).png" width="80" height='80' />
          </div>
          <div className="stats  flex-grow-1">
              <div className="px-3">
              <div className=" p-price ">
                  <Link to='/trainercoursedetails' style={{ textDecoration: 'none', color:'black' }}>
                      <h6 className="mb-0"><b>Squat Management Exercise</b></h6>
                  </Link>
                  
                  </div>
                  <div className=" p-price mt-2">
                      <span style={{color:'#7F7F7F'}}>12 minutes | Intermediate</span>
                      
                  </div>
              </div>
          </div>
          
      </div>
      <div className='border-bottom border-top my-3'>
        <div className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
        <a className="me-3" href="#">
                        <img className="rounded-circle shadow-1-strong mb-3"
                          src="./img/user/profile/profile.png" alt="avatar"
                          width="48" height="48" />
                      </a>
                      
                            <p className="my-2">
                            <b>John Duo</b>
                            </p>
                          
                    </div>
                    
            </div>

    </div>
    <div className="d-flex justify-content-center">
                            <button className="btn mx-2 rounded-pill my-1 w-100" style={{ height: '48px', width: '100px', backgroundColor:'#1D191C1A',color:'#1D191C' }}><b>Reject</b></button>
                            <button className="btn btn-dark rounded-pill mx-2 my-1 w-100" style={{ height: '48px', width: '100px' }}>Accept</button>
                        </div>
      </div>
      
  </div>
              </div>))} */}
            </div>
          </div>
          <div type='button' onClick={checkBankInfo} className="plus-img">
          <img className='img-fluid' src="./img/courseinstructor/home/plusimg.png" alt="plus" />
        </div>
        </div>
        
      </div>
    </div>
  </>
  )
}

export default TrainerHome