import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../CourseInstructor/Dashboard/instructorhome.css';
import InstructoreHeader from '../../CourseInstructor/Dashboard/InstructoreHeader'
import InstructorSidebar from '../../CourseInstructor/Dashboard/InstructorSidebar'
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const TrainerCreateWorkoutDetails = () => {
    const location = useLocation();
  const { workoutDetail } = location.state || {};
    const navigate = useNavigate();
  const [isloading, setIsLoading] = useState(false);
  const totalDuration = workoutDetail?.workoutActivity?.reduce((acc, activity) => {
    return acc + (activity?.activityDuration?.duration || 0);
  }, 0);
    const handleContinue =() =>{
      navigate('/trainerhome')
    }
    const handleCourseCreateSuccessful = () => {
        let getRes = (res) => {
            console.log('Response received:', res)
            if (res.statusCode == 201) {
              setShowModal(true);
  
              // toast.success("Logged in Successfully", {
              //   autoClose: 1500,
              //   onClose: () => navigate('/instructorhome')
              // });
  
        } else {
          toast.error(res.message, {
            autoClose: 1500
          });
        }
      };
          let body = {
            workoutThumbnail: workoutDetail.workoutThumbnail,
            workoutTitle: workoutDetail.workoutTitle,
            workoutLevel: workoutDetail.workoutLevel,
            workoutActivity: workoutDetail.workoutActivity,
            workoutPrice: workoutDetail.workoutPrice,
            workoutDuration: workoutDetail.workoutDuration,
  calories: workoutDetail.calories,
          };
          console.log(body);
          callApi("POST", routes.createWorkout, body, setIsLoading, getRes, (error) => {
            console.log("error", error);
          });
    };
  
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        let timer;
        if (showModal) {
          timer = setTimeout(() => {
            handleContinue();
          }, 2000);
        }
        return () => clearTimeout(timer);
      }, [showModal]);
  return (
    <>
    <ToastContainer/>

    <div className="overflow-hidden">
      <div className="row " style={{marginBottom:'5%'}}>
        {/* Sidebar */}
        <InstructorSidebar/>
        {/* Main Content */}
        <div className="col-md-10">
          <InstructoreHeader/>
          <div className='container-fluid my-4'>
            <h3 className='fw-bold'>Create Workout Plan</h3>
            <div className='row'>
           <div className="col-md-6 col-12 ">
           <div className=" single_post ">
                    <div className="">
                    <div className=" mt-3">
                    <div className="img-post">
    <img className="d-block img-fluid w-100 rounded-4" src={workoutDetail?.workoutThumbnail} alt="First slide" style={{height:'250px'}}/>
</div>
<div className="d-flex justify-content-between">
<div className=" eighteenfont my-2 "><h5  style={{ textDecoration: 'none',color:'black' }}><b>{workoutDetail?.workoutTitle}</b></h5>

</div>
<div className=" p-price mt-3"><h6><b>${workoutDetail?.workoutPrice}.00</b></h6></div>
</div>
                                        <div className="d-flex justify-content-start  border-bottom">
                                        <div>
<span className="badge py-2 my-3 px-3 mx-1 rounded-pill" style={{ fontSize: '12px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className='py-1'>{workoutDetail?.workoutLevel}</span>
                                        </span>
</div>
<div>
<span className="badge py-2 my-3 px-3 mx-1 rounded-pill" style={{ fontSize: '12px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className='py-1'><i className="fa-solid fa-circle-play fa-lg mx-1"></i>{workoutDetail?.workoutActivity.length} workout</span>
                                        </span>
</div>
<div>
<span className="badge py-2 my-3 px-3 mx-1 rounded-pill" style={{ fontSize: '12px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <span className='py-1'><i className="fa-solid fa-clock fa-lg mx-1"></i>{workoutDetail?.workoutDuration}</span>
                                        </span>
</div>
                                        </div>
                                        
                                      
                                        {workoutDetail?.workoutActivity && workoutDetail?.workoutActivity.map((workout, index) => ( <div className="col-md-12">
                                          <div className="d-flex justify-content-center  mt-3 ">
        <div className="card   border-0 rounded-4 d-flex flex-row align-items-center w-100" style={{backgroundColor:'#1D191C1A'}}>
            <div className="">
                <img className='img-fluid rounded-start-4' src="./img/courses/workoutdetails/Rectangle 148441.png" width="124" height='130' />
            </div>
            <div className="stats  flex-grow-1">
                <div className="px-3">
                
                    <Link to='/workoutstart' style={{ textDecoration: 'none', color:'black' }}>
                        <h5 className="my-3"><b>{workout?.title}</b></h5>
                    </Link>
                    
                    
                    <p className='text-secondary'>{workout?.activityDuration?.duration} {workout?.activityDuration?.unit}</p>
                </div>
            </div>
        </div>
    </div>
</div>))}


                                    
                    </div>
    
    
    
    
                    <div className='mt-4 mb-5'>
                    <button className='btn rounded-pill px-5 py-3 shadow w-100'  onClick={(e) => { e.preventDefault(); handleCourseCreateSuccessful(); }} style={{backgroundColor:'#1D191C', color:'white'}}>Continue</button>
                    </div>
                    {showModal && (
                    <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog " style={{marginTop: '5%'}}>
                        <div className="modal-content my-1">
                            {/* <div className="modal-header">
                                <h5 className="modal-title" id="paymentModalLabel">Payment Successful</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body text-center">
                                
                                <img src="./img/courses/coursepayment/coursepaymentsuccessfull.png" alt="" height='100' width='100'/>
                                <h4 className='my-3'><b>Course Created Successful!</b></h4>
                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.</h6>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                
                )}
                    </div>                        
                </div>

           </div>
           
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  </>
  )
}

export default TrainerCreateWorkoutDetails