import React, { useEffect, useState } from 'react';
import AuthenticateImage from '../User/AuthenticateImage';
import { useLocation, useNavigate } from 'react-router-dom';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const InstructorSignUpProfile2 = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();
    const { detail } = location.state || {};
  const [isloading, setIsLoading] = useState(false);

    // State variable for social media links
    const [socialMediaLinks, setSocialMediaLinks] = useState({
        whatsApp: "",
        facebook: "",
        twitter: "",
        instagram: "",
        website: ""
      });
    
      const handleInputChange = (field, value) => {
        setSocialMediaLinks(prevLinks => ({
          ...prevLinks,
          [field]: value
        }));
      };
    
    // const handleInputChange = (index, value) => {
    //     setSocialMediaLinks((prevLinks) => {
    //         const newLinks = [...prevLinks];
    //         newLinks[index] = value;
    //         return newLinks;
    //     });
    // };
    const handleAccount = () => {
    
        console.log(socialMediaLinks);
        let getRes = (res) => {
          if (res.statusCode == 200) {
    
            setShowModal(true);
              
            
          } else {
            toast.error(res.message, {
                  autoClose: 1500
                  })
          }
        };
        // const fileUrl = avatar;
        let body = {
          fullName: detail.fullName,
          avatar: detail.avatar,
          location: {
            type: "Point",
            coordinates: [72.343361, 31.04668],
            address: detail.location,
            // description: "Burewala"
          },
          description: detail.description,
          socialMediaLinks: socialMediaLinks,
        };
        console.log(body)
        callApi("POST", routes.completeProfile, body, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
    
    
      };
    const handleAccountSuccessful = (e) => {
        e.preventDefault();
        
        // Check if at least one link is provided
        // const hasAtLeastOneLink = socialMediaLinks.some(link => link.trim() !== "");
        
        if (socialMediaLinks.whatsApp !== "" && socialMediaLinks.facebook !== "" &&socialMediaLinks.twitter !== "" &&socialMediaLinks.instagram !== "") {
            console.log('Social Media Links:', socialMediaLinks);
            handleAccount();
        } else {
            alert('Please provide social media links.');
            
        }
    };

    useEffect(() => {
        let timer;
        if (showModal) {
          timer = setTimeout(() => {
            setShowModal(false);
            navigate('/instructorhome');
          }, 2000);
        }
        return () => clearTimeout(timer);
    }, [showModal]);

    return (
        <>
        <ToastContainer/>
            <section className="overflow-hidden">
                <div className="">
                    <div className="row">
                        <div className="col-md-6 text-center text-sm-start" style={{ color: '#1D191C' }}>
                            <div className="container-fluid d-flex justify-content-center align-items-center">
                                <div className="w-100">
                                    <div className="px-xl-5 ms-xl-4 mt-4 mb-5">
                                        <img className='mb-2 img-fluid' src="./img/user/login/logo 1 (1).png" alt="" height='28' width='66'/>
                                        <span className="fw-bold eighteenfont mx-2">Powering Your Platform</span>
                                    </div>
                                    <div className="d-flex flex-column align-items-center align-items-sm-start h-custom-2 px-xl-5 ms-xl-4" style={{ marginTop: '25%' }}>
                                        <form style={{ maxWidth: '100%', width: '100%' }}>
                                            <h4 className="fw-bold mb-2 pb-2">Choose Your Account Type</h4>
                                            <div className='col-md-9'>
                                                <p className='text-secondary eighteenfont'>Check your email inbox for the OTP code we sent you. Please enter it below to proceed.</p>
                                            </div>
                                            <div>
      <div className="d-flex justify-content-between my-3">
        <div>
          <img src="./img/courseinstructor/signup/Frame 1686553983.png" alt="" />
        </div>
        <div className='w-100'>
          <input
            type="text"
            className="form-control rounded-4 py-2 px-2 border-0 mx-3"
            placeholder="Add WhatsApp link"
            style={{ backgroundColor: '#FAFAFA', height: '48px' }}
            pattern="^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$"
            title="Please enter a valid URL"
            value={socialMediaLinks.whatsApp}
            onChange={(e) => handleInputChange('whatsApp', e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between my-3">
        <div>
          <img src="./img/courseinstructor/signup/Frame 1686553983 (1).png" alt="" />
        </div>
        <div className='w-100'>
          <input
            type="text"
            className="form-control rounded-4 py-2 px-2 border-0 mx-3"
            placeholder="Add Facebook link"
            style={{ backgroundColor: '#FAFAFA', height: '48px' }}
            pattern="^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$"
            title="Please enter a valid URL"
            value={socialMediaLinks.facebook}
            onChange={(e) => handleInputChange('facebook', e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between my-3">
        <div>
          <img src="./img/courseinstructor/signup/Frame 1686553983 (2).png" alt="" />
        </div>
        <div className='w-100'>
          <input
            type="text"
            className="form-control rounded-4 py-2 px-2 border-0 mx-3"
            placeholder="Add Twitter link"
            style={{ backgroundColor: '#FAFAFA', height: '48px' }}
            pattern="^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$"
            title="Please enter a valid URL"
            value={socialMediaLinks.twitter}
            onChange={(e) => handleInputChange('twitter', e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between my-3">
        <div>
          <img src="./img/courseinstructor/signup/Frame 1686553983 (3).png" alt="" />
        </div>
        <div className='w-100'>
          <input
            type="text"
            className="form-control rounded-4 py-2 px-2 border-0 mx-3"
            placeholder="Add Instagram link"
            style={{ backgroundColor: '#FAFAFA', height: '48px' }}
            pattern="^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$"
            title="Please enter a valid URL"
            value={socialMediaLinks.instagram}
            onChange={(e) => handleInputChange('instagram', e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between my-3">
        <div>
          <img src="./img/courseinstructor/signup/Frame 1686553983 (4).png" alt="" />
        </div>
        <div className='w-100'>
          <input
            type="text"
            className="form-control rounded-4 py-2 px-2 border-0 mx-3"
            placeholder="Add Website link"
            style={{ backgroundColor: '#FAFAFA', height: '48px' }}
            pattern="^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$"
            title="Please enter a valid URL"
            value={socialMediaLinks.website}
            onChange={(e) => handleInputChange('website', e.target.value)}
          />
        </div>
      </div>
    </div>

                                            <div className="">
                                                <div className="text-center align-items-center">
                                                    <div className='mb-5 mt-5 mx-auto'>
                                                        <button className='btn rounded-pill px-5 py-3 shadow w-100' onClick={handleAccountSuccessful} style={{ backgroundColor: '#1D191C', color: 'white' }}>Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        {showModal && (
                                            <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                                <div className="modal-dialog" style={{ marginTop: '5%' }}>
                                                    <div className="modal-content my-1">
                                                        <div className="modal-body text-center">
                                                            <img src="./img/courses/coursepayment/coursepaymentsuccessfull.png" alt="" height='100' width='100'/>
                                                            <h4 className='my-3'><b>Account Setup Successfully!</b></h4>
                                                            <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AuthenticateImage />
                    </div>
                </div>
            </section>
        </>
    );
}

export default InstructorSignUpProfile2;
