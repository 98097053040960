import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
const WorkoutDetails = () => {

    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState(null);
    const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');
    const [enrollment, setenrollment] = useState('');
    let token = localStorage.getItem('accessToken') ?? false;
    const courseenroll =(e) =>{
      e.preventDefault();

    let getRes = (res) => {
      if (res.statusCode === 200) {
        setenrollment(res.data);
        console.log(res.data.clientSecret);
        console.log(res.data.paymentIntentId);
        navigate('/courseenrollpayment', { state: { clientSecret: res.data.clientSecret, paymentIntent: res.data.paymentIntentId } });
      }
    };

    callApi(
      'POST',
      routes.courseEnrollment+'/'+workoutId,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log('error', error);
      }
    );
  };
    // navigate('/coursepayment')
  
  const [isloading, setIsLoading] = useState(false);
  const [workoutdetail, setworkoutdetail] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const workoutId = queryParams.get("workoutId");
    const getWorkoutDetail = () => {
      
      let getRes = (res) => {
        //  localStorage.setItem("userData", res?.data?.user);
        setworkoutdetail(res.workoutInfo);
        // setproducts(res.data.data.products);
      //   console.log(productId)
      };
  
      callApi("GET", routes.workoutdetails+'/'+workoutId, null, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
    };
    useEffect(() => {
        if (workoutId) {
            getWorkoutDetail();
        }
      }, [workoutId]);
      const splitTitle = (title, charLimit) => {
        if (!title) return ['', ''];
      
        // Find the position to split the title without cutting words
        let splitIndex = charLimit;
      
        if (title.length > charLimit) {
          while (splitIndex > 0 && title[splitIndex] !== ' ') {
            splitIndex--;
          }
      
          if (splitIndex === 0) {
            // If no space is found, fall back to the charLimit
            splitIndex = charLimit;
          }
        }
      
        const firstPart = title.substring(0, splitIndex);
        const secondPart = title.substring(splitIndex).trim();
      
        return [firstPart, secondPart];
      };
      const [firstPart, secondPart] = splitTitle(workoutdetail?.workoutTitle, 25);

  return (
    <>
    <div className="container-fluid">
    <div className="row my-4">
    <div className="col-md-5 col-12 ">
        <div className="banner-container">
            <h1 className="banner-employes ">{firstPart}</h1>
            <div className="banner-employes-underline"></div>
        </div>
        <h5 className="banner-employes ">{secondPart}</h5>
    </div>
<div className="col-md-5 col-12 mx-auto mt-4">
<span className="text-subheading ">Find the perfect workout to help you build muscle, improve endurance, and achieve your personal fitness goals. Get started and transform your routine today!</span>

</div>
</div>

</div>
<div  className="blog-page">
    <div className="container-fluid">
        <div className="row clearfix">
            <div className="col-lg-7 col-md-7 col-12 left-box">
                <div className=" single_post ">
                    <div className="">
                    <div className="px-md-4 coursedetailsmidborder">
                    <div className="img-post">
    <img className="d-block img-fluid w-100 rounded-4" src={workoutdetail?.workoutThumbnail} style={{height:'300px'}} />
</div>
<div className=" eighteenfont my-3 "><span  style={{ textDecoration: 'none',color:'black' }}><b>{workoutdetail?.workoutTitle}</b></span>

                                        </div>
                                        <div className=" p-price mt-3"><span><b>${workoutdetail?.workoutPrice}</b></span></div>
                                        <div className="d-flex justify-content-start py-2 border-bottom">
                                        <div>
<span className="badge py-2 my-3 px-3 mx-1 rounded-pill" style={{ fontSize: '14px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className='py-1'>{workoutdetail?.workoutLevel}</span>
                                        </span>
</div>
<div>
<span className="badge py-2 my-3 px-4 mx-1 rounded-pill" style={{ fontSize: '14px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className='py-1'><i className="fa-solid fa-circle-play fa-lg mx-1"></i> {workoutdetail?.totalWorkouts} workout</span>
                                        </span>
</div>
<div>
<span className="badge py-2 my-3 px-3 mx-1 rounded-pill" style={{ fontSize: '14px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <span className='py-1'><i className="fa-solid fa-clock fa-lg mx-1"></i> {workoutdetail?.workoutDuration}</span>
                                        </span>
</div>
                                        </div>
                                        
                                        <div className="row mt-2">
                                        {workoutdetail?.workoutActivity && workoutdetail?.workoutActivity.map((workout, index) => (
                                          <div className="col-md-6">
                                          <div className="d-flex justify-content-center  mt-3 ">
        <div className="card   border-0 rounded-4 d-flex flex-row align-items-center w-100" style={{backgroundColor:'#1D191C1A'}}>
            <div className="">
                <img className='img-fluid rounded-start-4' src="./img/courses/workoutdetails/Rectangle 148441.png" width="124" height='130' />
            </div>
            <div className="stats  flex-grow-1">
                <div className="px-3">
                <h5 className="my-3"><b>{workout?.title}</b></h5>
                    {/* <Link to='/workoutstart' style={{ textDecoration: 'none', color:'black' }}>
                        <h5 className="my-3"><b>{workout?.title}</b></h5>
                    </Link> */}
                    
                    
                    <p className='text-secondary'>{workout?.activityDuration?.duration} {workout?.activityDuration?.unit}</p>
                </div>
            </div>
        </div>
    </div>
</div> ))}

                                        </div>
                    </div>
    
    
    
    
    <div className='mt-5 mb-5 col-md-6 mx-3'>
                    <button className='btn rounded-pill px-5 py-3 shadow w-100' onClick={courseenroll} style={{backgroundColor:'#1D191C', color:'white'}}>Enroll Course</button>
                    </div>
                    </div>                        
                </div>
                
                
            </div>
        <div className="col-lg-5 col-md-5 col-12">
        <section className="" >
  <div className="container-fluid">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="">

        
          <div className=" text-center">
            <div className="mt-3 mb-2">
              <img src={workoutdetail?.trainer?.avatar}
                className="rounded-circle img-fluid" height='80' width='80' />
            </div>
            <h6 className=""><b>{workoutdetail?.trainer?.fullName}</b></h6>
            <p className='text-secondary'>{workoutdetail?.trainer?.description}</p>
            <div className="d-flex justify-content-center text-center mt-3 mb-2 text-secondary">
              <div className="px-3 " style={{ borderRight:"1px solid #E2E2E2"}}>
                <p className="mb-2 h5">{workoutdetail?.trainer?.totalWorkouts}</p>
                <p className="text-muted mb-0">Courses</p>
              </div>
              <div className="px-3">
                <p className="mb-2 h5">{workoutdetail?.trainer?.totalStudents}</p>
                <p className="text-muted mb-0">Students</p>
              </div>
             
            </div>
           
            <div className="col-md-7 mx-auto mb-3 ">
            {token ?<Link to={`/userinbox?instructorId=${workoutdetail?.trainer?._id}`} type="button" className="btn btn-secondary rounded-pill btn-info btn-lg w-100 py-3 shadow" style={{ fontSize: '16px', fontWeight: '500' }}>
    Chat with Trainer
</Link>:
<button type="button" className="btn btn-secondary rounded-pill  btn-lg w-100 py-3 shadow" style={{ fontSize: '16px', fontWeight: '500' }}>
    Logged In for Chat
</button>}
            </div>
            
          </div>
          <div className='mt-4'>
          <span className='eighteenfont '><b>About Instructor</b></span>
          </div>
          <p className='text-secondary my-3'>{workoutdetail?.trainer?.description}</p>
          
          <div className='mt-4'>
          <span className='eighteenfont '><b>Workout Session</b></span>
          </div>
          
            <div className=" my-3">
    <div className="col-md-12 mx-auto">
    {workoutdetail?.trainer?.workouts && workoutdetail?.trainer?.workouts.map((workout, index) => (
  <div className="workout-card" key={index}>
    <div className="workout-card-image" style={{ backgroundImage: `url(${workout?.workoutThumbnail})` }}>
      {/* <div className="workout-card-category">City</div> */}
      <div className="workout-card-description">
        <h2>{workout?.workoutTitle}</h2>
        <p>{workout?.workoutDuration} | {workout?.workoutLevel}</p>
      </div>
      {/* <img className="workout-card-user workout-card-avatar workout-card-avatar-large" src="https://github.com/lewagon/bootstrap-challenges/blob/master/11-Airbnb-search-form/images/anne.jpg?raw=true" alt="User" /> */}
      <Link className="workout-card-link" to={{
            pathname: "/workoutdetails",
            search: `?workoutId=${workout?._id}`, 
          }}></Link>
    </div>
  </div>
))}

            
    </div>

          

        </div>

      </div>
    </div>
  </div>
</section>
        </div>
        </div>
    </div>
</div>

    </>
  )
}

export default WorkoutDetails