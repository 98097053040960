import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import InstructorSidebar from '../../CourseInstructor/Dashboard/InstructorSidebar';
import InstructoreHeader from '../../CourseInstructor/Dashboard/InstructoreHeader';
import '../../CourseInstructor/Dashboard/instructorhome.css';
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';

const TrainerCourseDetails = () => {
  const [isloading, setIsLoading] = useState(false);
  const [workoutdetail, setworkoutdetail] = useState([]);
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const workoutId = queryParams.get("workoutId");
    const getWorkoutDetail = () => {
      
      let getRes = (res) => {
        //  localStorage.setItem("userData", res?.data?.user);
        setworkoutdetail(res.workoutInfo);
        // setproducts(res.data.data.products);
      //   console.log(productId)
      };
  
      callApi("GET", routes.workoutdetails+'/'+workoutId, null, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
    };
    useEffect(() => {
      if (workoutId) {
          getWorkoutDetail();
      }
    }, [workoutId]);
    const [myWorkouts, setmyWorkouts] = useState('')
  const getmyWorkouts = () => {
    let getRes = (res) => {
      setmyWorkouts(res?.workouts);
    };

    callApi("GET", routes.myWorkouts, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  
  useEffect(() => {
    // console.log(detail);
    getmyWorkouts()
  }, [])
  return (
    <>
      <div className="overflow-hidden">
        <div className="row">
          {/* Sidebar */}
          <InstructorSidebar/>
          {/* Main Content */}
          <div className="col-md-10">
            <InstructoreHeader/>
            <div className='container-fluid my-4'>
              <h3 className='fw-bold'>My Workouts</h3>
              <div className="row clearfix">
              <div className="col-lg-5 col-md-5 col-12">
        <section className="" >
  <div className="mx-3">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="">

    
          
            <div className=" my-3">
    <div className="col-md-12">
    <div className="d-flex justify-content-center  my-3 mx-1">
    <div className="col-md-12 mx-auto">
    {workoutdetail?.trainer?.workouts && workoutdetail?.trainer?.workouts.map((workout, index) => (
  <div className="workout-card" key={index}>
    <div className="workout-card-image" style={{ backgroundImage: `url(${workout?.workoutThumbnail})` }}>
      {/* <div className="workout-card-category">City</div> */}
      <div className="workout-card-description">
        <h2>{workout?.workoutTitle}</h2>
        <p>{workout?.workoutDuration} | {workout?.workoutLevel}</p>
      </div>
      {/* <img className="workout-card-user workout-card-avatar workout-card-avatar-large" src="https://github.com/lewagon/bootstrap-challenges/blob/master/11-Airbnb-search-form/images/anne.jpg?raw=true" alt="User" /> */}
      <Link className="workout-card-link" to={{
            pathname: "/trainercoursedetails",
            search: `?workoutId=${workout?._id}`, 
          }}></Link>
    </div>
  </div>
))}

            
    </div>
      {/* <div className="card p-3 bg-white border-0 shadow rounded-4 w-100">
      <div className="  d-flex flex-row align-items-center ">
          <div className="about-product text-center">
              <img className='img-fluid rounded-4' src="./img/workouttrainer/home/Rectangle 148438 (2).png" width="80" height='80' />
          </div>
          <div className="stats  flex-grow-1">
              <div className="px-3">
              <div className=" p-price ">
                  <Link to='/trainercoursedetails' style={{ textDecoration: 'none', color:'black' }}>
                      <h6 className="mb-0"><b>Squat Management Exercise</b></h6>
                  </Link>
                  
                  </div>
                  <div className=" p-price mt-2">
                      <span style={{color:'#7F7F7F'}}>12 minutes | Intermediate</span>
                      
                  </div>
              </div>
          </div>
          
      </div>
      <div className='border-bottom border-top my-3'>
        <div className="d-flex justify-content-between p-price mt-3">
        <div className="d-flex flex-start ">
        <a className="me-3" href="#">
                        <img className="rounded-circle shadow-1-strong mb-3"
                          src="./img/user/profile/profile.png" alt="avatar"
                          width="48" height="48" />
                      </a>
                      
                            <p className="my-2">
                            <b>John Duo</b>
                            </p>
                          
                    </div>
                    
            </div>

    </div>
    <div className="d-flex justify-content-center">
                            <button className="btn mx-2 rounded-pill my-1 w-100" style={{ height: '48px', width: '100px', backgroundColor:'#1D191C1A',color:'#1D191C' }}><b>Reject</b></button>
                            <button className="btn btn-dark rounded-pill mx-2 my-1 w-100" style={{ height: '48px', width: '100px' }}>Accept</button>
                        </div>
      </div> */}
      
  </div>
    
    </div>

          

        </div>

      </div>
    </div>
  </div>
</section>
        </div>
            <div className="col-lg-7 col-md-7 col-12 left-box">
                <div className=" single_post ">
                    <div className="px-md-4 instructorcoursedetailsmidborder">
                    <div className=" single_post ">
                    <div className="">
                    <div className=" mt-3">
                    <div className="img-post">
                    <img className="d-block img-fluid w-100 rounded-4" src={workoutdetail?.workoutThumbnail} style={{height:'300px'}} />
</div>
<div className="d-flex justify-content-between">
<div className=" eighteenfont my-2 "><h5  style={{ textDecoration: 'none',color:'black' }}><b>{workoutdetail?.workoutTitle}</b></h5>

</div>
<div className=" p-price mt-3"><h6><b>${workoutdetail?.workoutPrice}</b></h6></div>
</div>
                                        <div className="d-flex justify-content-start  border-bottom">
                                        <div>
<span className="badge py-2 my-3 px-3 mx-1 rounded-pill" style={{ fontSize: '12px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className='py-1'>{workoutdetail?.workoutLevel}</span>
                                        </span>
</div>
<div>
<span className="badge py-2 my-3 px-3 mx-1 rounded-pill" style={{ fontSize: '12px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span className='py-1'><i className="fa-solid fa-circle-play fa-lg mx-1"></i>{workoutdetail?.totalWorkouts} workout</span>
                                        </span>
</div>
<div>
<span className="badge py-2 my-3 px-3 mx-1 rounded-pill" style={{ fontSize: '12px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <span className='py-1'><i className="fa-solid fa-clock fa-lg mx-1"></i>{workoutdetail?.workoutDuration}</span>
                                        </span>
</div>
                                        </div>
                                        
                                      
                                        
                                        {workoutdetail?.workoutActivity && workoutdetail?.workoutActivity.map((workout, index) => (
                                          <div className="col-md-12">
                                          <div className="d-flex justify-content-center  mt-3 ">
        <div className="card   border-0 rounded-4 d-flex flex-row align-items-center w-100" style={{backgroundColor:'#1D191C1A'}}>
            <div className="">
                <img className='img-fluid rounded-start-4' src="./img/courses/workoutdetails/Rectangle 148441.png" width="124" height='130' />
            </div>
            <div className="stats  flex-grow-1">
                <div className="px-3">
                
                    <Link to='/workoutstart' style={{ textDecoration: 'none', color:'black' }}>
                        <h5 className="my-3"><b>{workout?.title}</b></h5>
                    </Link>
                    
                    
                    <p className='text-secondary'>{workout?.activityDuration?.duration} {workout?.activityDuration?.unit}</p>
                </div>
            </div>
        </div>
    </div>
</div> ))}
                                      

                                    
                    </div>
                    
                    </div>                        
                </div>
                    {/* <div className="text-center h-custom-2  mt-4">
                <img src="./img/user/profile/profile.png" alt="avatar" className="rounded-circle img-fluid mt-md-3" style={{ width: '120px', height:'120px' }} />
                <h5 className="mt-3 mb-2 eighteenfont"><b>John Duo</b></h5>
                <p className=" mb-1"><i className="fa-solid fa-location-dot fa-lg mx-2"></i>Time Square NYC, Ney York USA.</p>
                <div className='border-bottom col-md-11 mx-auto my-4'></div>
                </div>
                <div className="col-md-11 mx-auto">
                <p className='fw-bold eighteenfont'>Personal Info</p>
                <div className="row">
                <div className="col-md-6">
<div>
<p><b>Gender</b></p>
<p className='text-muted'>Male</p>
</div>
<div>
<p><b>Weight</b></p>
<p className='text-muted'>72kg</p>
</div>
<div>
<p><b>Goal</b></p>
<p className='text-muted'>Build Muscles, Improve Fitness</p>
</div>
                </div>
                <div className="col-md-6">
                <div>
<p><b>Age</b></p>
<p className='text-muted'>27 years</p>
</div>
<div>
<p><b>Height</b></p>
<p className='text-muted'>172 cm</p>
</div>
<div>
<p><b>Physical Activity Level</b></p>
<p className='text-muted'>Intermediate</p>
</div>
                </div>
                </div>
                <div className="d-flex justify-content-center">
                            <button className="btn mx-2 rounded-pill my-1 w-100" style={{ height: '60px', width: '100px', backgroundColor:'#1D191C1A',color:'#1D191C' }}><b>Reject</b></button>
                            <button className="btn btn-dark rounded-pill mx-3 my-1 w-100" style={{ height: '60px' }}>Accept</button>
                        </div>
                </div> */}
    {/* <div className='mt-4 mb-5 col-md-5'>
                    <button className='btn rounded-pill px-5 py-3 shadow w-100'  style={{backgroundColor:'#1D191C', color:'white'}}>Enroll Course</button>
                    </div> */}
                    </div>                        
                </div>
                
                
            </div>
        
        </div>
            </div>
          
          </div>
          
        </div>
      </div>
    </>
  )
}

export default TrainerCourseDetails