import React, { useEffect, useState } from 'react'
import HomeCarousel from '../Home/HomeCarousel'
import PopularCourses from '../Home/PopularCourses'
import PopularWorkout from '../Home/PopularWorkout'
import { callApi } from '../../Api/ApiCaller'
import routes from '../../Api/routes'
import { Link } from 'react-router-dom'

const AboutUs = () => {
    const [about, setabout] = useState([]);
  const [isloading, setIsLoading] = useState(false);

    // const getAboutUs = () => {
    
    //     let getRes = (res) => {
    //       //  localStorage.setItem("userData", res?.data?.user);
    //       setabout(res.data.docs);
    //     //   console.log(res.data.docs)
    //     };
    
    //     callApi("GET", routes.aboutUs, null, setIsLoading, getRes, (error) => {
    //       console.log("error", error);
    //     });
    //   };
    //   useEffect(() => {
    
    //     getAboutUs();
    //   }, []);
    const getAboutUs = () => {
    
      let getRes = (res) => {
        //  localStorage.setItem("userData", res?.data?.user);
        setabout(res.aboutUs);
      //   console.log(res.data.docs)
      };
  
      callApi("GET", routes.aboutUs, null, setIsLoading, getRes, (error) => {
        console.log("error", error);
      });
    };
    useEffect(() => {
  
      getAboutUs();
    }, []);
    function decodeHtml(html) {
      const txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    }
    const [ourMission, setOurMission] = useState([]);
  const getOurMission = () => {
    
        let getRes = (res) => {
          setOurMission(res.ourMission);
          // console.log(res.ourMission)
        };
    
        callApi("GET", routes.ourMission, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
    
        getOurMission();
      }, []);
  return (
    <>
<HomeCarousel/>

<div className="container-fluid">
<section className="how-it-works">
                    <h1 className="how-it-works-title">How Can You reach to Us</h1>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 mx-auto text-center my-4">
                            {about &&  <p className="" dangerouslySetInnerHTML={{ __html: decodeHtml(about?.content) }}></p>}
                            </div>
                        </div>
                    </div>


                    {/* <div className="">
                        <div className="row justify-content-center">
                        {about && about.slice(0, 3).map((about, index) => (
                        <div className="col-md-4 mx-2 my-2" key={index} style={{ maxWidth: '384px' }}>
                                <div className="card d-flex flex-column container border-0 shadow rounded-5" >
                                    <div style={{ maxWidth: '324px' }}>
                                    <div className="position-relative d-inline-block mx-2 mt-4">
      <img src="./img/aboutus/Ellipse 12.png" className="rounded-circle" style={{ height: '80px', width: '80px' }} alt="..." />
      <div className="position-absolute top-50 start-50 translate-middle text-success" style={{ fontSize: '24px', fontWeight: 'bold' }}>
        {index + 1}
      </div>
    </div>
                                        <div className="card-body " style={{ maxWidth: '324px' }}>
                                            <h5 className="card-title mt-2"><b>{about?.title}</b></h5>
                                            <h5 className="card-text my-3 text-secondary" >{about?.content}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>))}
                            
                        </div>
                    </div> */}
                </section>
</div>
<div style={{background: "rgba(29, 25, 28, 0.1)"}} className='mb-5 mt-3'>
  <div className="container-fluid d-flex align-items-center" style={{minHeight: "67vh"}}>
    <div className="row w-100">
      <div className="col-md-4 mx-auto my-3">
        <img src={ourMission?.image}  className="img-fluid rounded-5 " style={{height:'429px', width:'429px'}}  alt="Paris Image"/>
      </div>
      <div className="col-md-8 mx-auto">
      <div className="col-md-10">
      <h5><span className="badge py-3 px-3 rounded-3 mt-5" style={{backgroundColor:'#FAFAFA',color:'black'}}><b>Our Mission</b></span></h5>
        <div className="text my-4">
          <h1 style={{fontWeight:'600', fontSize:'3.38vw'}}>{ourMission?.heading}</h1>
          <h5 className=' my-4' style={{color:'#7F7F7F'}}>{ourMission?.content}</h5>
        </div>
        <Link to="/courses" type="button" class="btn btn-outline-dark px-4 my-4">Explore Now</Link>
      </div>
      </div>
    </div>
  </div>
</div>

<div className="container-fluid">
    <PopularCourses/>
    <PopularWorkout/>
</div>
    </>
  )
}

export default AboutUs