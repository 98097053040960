import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const InstructorSidebar = () => {
    const location = useLocation();
    const [currentURL, setCurrentURL] = useState(location.pathname);
    const [userlogged, setuserlogged] = useState(localStorage.getItem('loggedInUser'))
    const navigate = useNavigate();
    const [isloading, setIsLoading] = useState(false);
    const storedUserData = localStorage.getItem('userData');
    const [userData, setUserData] = useState(storedUserData ? JSON.parse(storedUserData) : null);
    
    useEffect(() => {
      setCurrentURL(location.pathname);
    }, [location.pathname]);
    // const [activeLink, setActiveLink] = useState(null);

    // const handleLinkClick = (event) => {
    //   setActiveLink(currentURL);
    // };
    // const handleLinkClickProgress = (event) => {
    //     setActiveLink(event.target.innerText);
    //   };
    //   const handleLinkClickSettings = (event) => {
    //     setActiveLink(event.target.innerText);
    //   };
    const handleLogout = () => {
      let getRes = (res) => {
          if (res.statusCode == 200) {

              localStorage.setItem("userData", null);
              localStorage.setItem("accessToken", "");
              localStorage.setItem("refreshToken", "");
              setUserData([]);
              setShowModal(true);
              
          } 
          // else {
          //     localStorage.setItem("userData", null);
          //     localStorage.setItem("accessToken", "");
          //     localStorage.setItem("refreshToken", "");
          //     setUserData([]);
          //     toast.error("Logged out Successfully", {
          //         autoClose: 1500,
          //         onClose: () => {
          //             localStorage.removeItem('loggedInUser');
          //             // Update the userlogged state to false
          //             setuserlogged(false);
          //             navigate('/');
          //         }
          //     })
          // }
      };

      callApi("POST", routes.logOut,null,  setIsLoading, getRes, getRes);
  }
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let timer;
    if (showModal) {
      timer = setTimeout(() => {
        localStorage.removeItem('loggedInUser');
        navigate("/");
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [showModal]);
    //   const handleLogout = () => {
    //     // Remove data of 'loggedInUser' from localStorage
    //     localStorage.removeItem('loggedInUser');
    //     // Update the userlogged state to false
    //     setuserlogged(false);
    //     navigate('/');
    // };
  return (
    <>
            {/* <ToastContainer /> */}

    <div className="col-md-2 sidebarhide p-3 text-bg-light" style={{maxHeight:'1024px'}}>
            <div className="container">
            <div className="d-flex flex-column flex-shrink-0">
              <div className="d-flex align-items-center mb-3 me-md-auto  ">
                <img className='fs-4 mx-4 img-fluid' src="./img/courseinstructor/header/logo 2.png" alt="" />
              </div>
              <hr />
              <ul className="nav nav-pills flex-column mb-auto">
              <li className="nav-item my-2">
                  {userlogged === 'instructor'? <> <Link to='/instructorhome' className={`nav-link rounded-3 sidebarbtn ${currentURL === '/instructorhome' || currentURL ==='/instructorcreatecourse' || currentURL ==='/instructorcoursedetails' || currentURL ==='/instructorcreatecoursedetails'? 'sidebarbtncolor' : ''}`} >
                  <div className="d-flex justify-content-start">
                    <div>
                    {currentURL === '/instructorhome'|| currentURL ==='/instructorcreatecourse' || currentURL ==='/instructorcoursedetails' || currentURL ==='/instructorcreatecoursedetails' ? <img src="./img/courseinstructor/header/Home (1).png" alt="" />:<img src="./img/courseinstructor/header/Home (2).png" alt="" />}
                    </div>
                    <div style={{marginTop:'2px'}}>
                    <span className='sidebartext mx-2'>Home</span>
                    </div>
                    </div>
                  </Link> </>: <> <Link to='/trainerhome' className={`nav-link rounded-3 sidebarbtn ${currentURL === '/trainerhome' || currentURL ==='/trainercreateworkout' || currentURL ==='/trainercoursedetails' || currentURL ==='/trainercreateworkoutdetails'? 'sidebarbtncolor' : ''}`} >
                  <div className="d-flex justify-content-start">
                    <div>
                    {currentURL === '/trainerhome'|| currentURL ==='/trainercreateworkout' || currentURL ==='/trainercoursedetails' || currentURL ==='/trainercreateworkoutdetails' ? <img src="./img/courseinstructor/header/Home (1).png" alt="" />:<img src="./img/courseinstructor/header/Home (2).png" alt="" />}
                    </div>
                    <div style={{marginTop:'2px'}}>
                    <span className='sidebartext mx-2'>Home</span>
                    </div>
                    </div>
                  </Link></>}
                </li>
                <li className="nav-item my-2">
                  {userlogged === 'instructor'? <Link to='/instructorprogresstracking' className={`nav-link rounded-3 sidebarbtn ${currentURL === '/instructorprogresstracking' ? 'sidebarbtncolor' : ''}`}>
                  <div className="d-flex justify-content-start">
                    <div>
                  {currentURL === '/instructorprogresstracking' ?<img src="./img/courseinstructor/header/Chart (2).png" alt="" />:<img src="./img/courseinstructor/header/Chart (1).png" alt="" />}
                  </div>
                    <div style={{marginTop:'2px'}}>
                    <span className='sidebartext mx-2'>Progress Tracking</span>
                    </div>
                    </div>
                  </Link>: <Link to='/trainerprogresstracking' className={`nav-link rounded-3 sidebarbtn ${currentURL === '/trainerprogresstracking' ? 'sidebarbtncolor' : ''}`}>
                  <div className="d-flex justify-content-start">
                    <div>
                  {currentURL === '/trainerprogresstracking' ?<img src="./img/courseinstructor/header/Chart (2).png" alt="" />:<img src="./img/courseinstructor/header/Chart (1).png" alt="" />}
                  </div>
                    <div style={{marginTop:'2px'}}>
                    <span className='sidebartext mx-2'>Progress Tracking</span>
                    </div>
                    </div>
                  </Link>}
                </li>
                <li className="nav-item my-2">
                  <Link to='/instructorsettings' className={`nav-link rounded-3 sidebarbtn ${currentURL === '/instructorsettings' ? 'sidebarbtncolor' : ''}`}>
                    <div className="d-flex justify-content-start">
                    <div>
                  {currentURL === '/instructorsettings' ?<img src="./img/courseinstructor/header/Setting (2).png" alt="" />:<img src="./img/courseinstructor/header/Setting (1).png" alt=""/>}
                    </div>
                    <div style={{marginTop:'2px'}}>
                    <span className='sidebartext mx-2'>Settings</span>
                    </div>
                    </div>
                  </Link>
                </li>
                
                
              </ul>
              <div style={{marginTop:'570px'}}>
              <hr />
              <ul className="nav nav-pills flex-column mb-auto" >
                <li className="nav-item my-2">
                  <button className='nav-link rounded-3 sidebarbtn '  onClick={handleLogout}>
                  <img src="./img/courseinstructor/header/Chart (2).png" alt=""/>
                    <span className='sidebartext mx-2'>Logout</span>
                  </button>
                </li>
              </ul></div>
              {/* <div className="dropdown">
                <a href="#" className="d-flex align-items-center  text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
                  <strong>mdo</strong>
                </a>
                <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                  <li><a className="dropdown-item" href="#">New project...</a></li>
                  <li><a className="dropdown-item" href="#">Settings</a></li>
                  <li><a className="dropdown-item" href="#">Profile</a></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><a className="dropdown-item" href="#">Sign out</a></li>
                </ul>
              </div> */}
            </div>
            </div>
          </div>
          {showModal && (
                      <div
                      className="modal show d-flex align-items-center justify-content-center"
                      id="paymentModal"
                      tabIndex="-1"
                      aria-labelledby="paymentModalLabel"
                      style={{
                        display: "block",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content my-1">
                          <div className="modal-body text-center">
                            <img
                              src="./img/courses/coursepayment/coursepaymentsuccessfull.png"
                              alt=""
                              height="100"
                              width="100"
                            />
                            <h4 className="my-3">
                              <b>Logged Out Successfuly!</b>
                            </h4>
                            <h6>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat
                              porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    )}
    </>
  )
}

export default InstructorSidebar