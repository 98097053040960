import React, { useEffect, useState } from 'react'
import './termsandcondition.css';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
const TermsAndCondition = () => {
    const [terms, setTerms] = useState('');
    const [isloading, setIsLoading] = useState(false);
    const getTerms = () => {
        let getRes = (res) => {
          setTerms(res?.terms);
        };
    
        callApi("GET", routes.terms, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
        // console.log(detail);

        getTerms();
      }, []);
      function decodeHtml(html) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
      }
  return (
    <>
    <div className="container" >
    <div className="row justify-content-center">
        <div className="col-md-5 col-12">
            <div className="banner-container text-center mt-5">
                <h1 className="banner-terms">Terms of Use</h1>
                <div className="banner-terms-underline"></div>
            </div>
            {/* <h5 className="banner-employes ">saying about us</h5> */}
        </div>
    </div>
 
    <div className="row justify-content-center mb-5">
        <div className="col-md-12">
        
        {terms &&  <p className="text-center my-4" dangerouslySetInnerHTML={{ __html: decodeHtml(terms?.content) }}></p>}
        
                
        </div>
    </div>


</div>

    </>
  )
}

export default TermsAndCondition