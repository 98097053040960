import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthenticateImage from "../AuthenticateImage";
import { callApi } from "../../../Api/ApiCaller";
import routes from "../../../Api/routes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SignUpAccountType = ({ setuserlogged }) => {
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("otpemail");
  const [isloading, setIsLoading] = useState(false);

  const handleContinue = async (e) => {
    e.preventDefault();
    let getRes = (res) => {
      if (res.statusCode === 200 ) {

        // toast.success("Profile Updated Successfully", {
        //         autoClose: 1500,
        //       });

        switch (selectedAccountType) {
          case "student":
            localStorage.setItem("loggedInUser", "student");
            setuserlogged("student");
            localStorage.setItem("accountType", "student");
            navigate(`/usersignupprofile1?role=${selectedAccountType}`);
            break;
          case "instructor":
            localStorage.setItem("loggedInUser", "instructor");
            setuserlogged("instructor");
            localStorage.setItem("accountType", "instructor");
            navigate(`/usersignupprofile1?role=${selectedAccountType}`);
            break;
          case "trainer":
            localStorage.setItem("loggedInUser", "trainer");
            setuserlogged("trainer");
            localStorage.setItem("accountType", "trainer");
            navigate(`/usersignupprofile1?role=${selectedAccountType}`);
            break;
          default:
            // Handle default case or error
            break;
        }

      } else {
        toast.error(res.message, {
              autoClose: 1500
              })
      }
    //   setShowModal(false);
    };
    let body = {
      role: selectedAccountType,
    };

    callApi(
      "PATCH",
      routes.editUser,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const handleAccountTypeSelect = (accountType) => {
    setSelectedAccountType(accountType);

  };

  return (
    <>
    <ToastContainer/>
      <section className="overflow-hidden">
        <div className="">
          <div className="row">
            <div
              className="col-md-6 text-center text-sm-start"
              style={{ color: "#1D191C" }}
            >
              <div className="container-fluid d-flex justify-content-center align-items-center">
                <div className="w-100">
                  <div className="px-xl-5 ms-xl-4 mt-4 mb-5">
                    <img
                      className="mb-2 img-fluid"
                      src="./img/user/login/logo 1 (1).png"
                      alt=""
                      height="100"
                      width="100"
                    />
                    <span className="fw-bold eighteenfont mx-2">
                      Powering Your Platform
                    </span>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center align-items-sm-start h-custom-2 px-xl-5 ms-xl-4"
                    style={{ marginTop: "25%" }}
                  >
                    <form style={{ maxWidth: "100%", width: "100%" }}>
                      <h4 className="fw-bold mb-2 pb-2">
                        Choose Your Account Type
                      </h4>
                      <div className="col-md-9">
                        <p className="text-secondary eighteenfont">
                          Check your email inbox for the OTP code we sent you.
                          Please enter it below to proceed.
                        </p>
                      </div>
                      <div
                        className="container p-price mt-3 border rounded-3"
                        onClick={() => handleAccountTypeSelect("student")}
                        style={{
                          cursor: "pointer",
                          opacity: selectedAccountType === "student" ? 1 : 0.5,
                        }}
                      >
                        <div className="d-flex flex-start p-2">
                          <a className="me-3" >
                            <img
                              className="rounded-circle shadow-1-strong "
                              src="./img/user/signup/Profile (1).png"
                              alt="avatar"
                              width="52"
                              height="52"
                            />
                          </a>
                          <div className="flex-grow-1 flex-shrink-1">
                            <div className="text-start">
                              <p className="mb-0">
                                <b>I am User</b>
                              </p>

                              <span style={{ fontSize: "14px" }}>
                                Lorem ipsum dolor sit amet, consectetur adipi
                                elit eclipe
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="container p-price mt-3 border rounded-3"
                        onClick={() => handleAccountTypeSelect("instructor")}
                        style={{
                          cursor: "pointer",
                          opacity:
                            selectedAccountType === "instructor" ? 1 : 0.5,
                        }}
                      >
                        <div className="d-flex flex-start p-2">
                          <a className="me-3" >
                            <img
                              className="rounded-circle shadow-1-strong "
                              src="./img/user/signup/Profile (1).png"
                              alt="avatar"
                              width="52"
                              height="52"
                            />
                          </a>
                          <div className="flex-grow-1 flex-shrink-1">
                            <div className="text-start">
                              <p className="mb-0">
                                <b>I am Instructor</b>
                              </p>

                              <span style={{ fontSize: "14px" }}>
                                Lorem ipsum dolor sit amet, consectetur adipi
                                elit eclipe
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="container p-price mt-3 border rounded-3"
                        onClick={() => handleAccountTypeSelect("trainer")}
                        style={{
                          cursor: "pointer",
                          opacity: selectedAccountType === "trainer" ? 1 : 0.5,
                        }}
                      >
                        <div className="d-flex flex-start p-2">
                          <a className="me-3" >
                            <img
                              className="rounded-circle shadow-1-strong "
                              src="./img/user/signup/Profile (1).png"
                              alt="avatar"
                              width="52"
                              height="52"
                            />
                          </a>
                          <div className="flex-grow-1 flex-shrink-1">
                            <div className="text-start">
                              <p className="mb-0">
                                <b>I am Trainer</b>
                              </p>

                              <span style={{ fontSize: "14px" }}>
                                Lorem ipsum dolor sit amet, consectetur adipi
                                elit eclipe
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="text-center align-items-center">
                          <div className="mb-5 mt-5  mx-auto">
                            <button
                              className="btn rounded-pill px-5 py-3 shadow w-100"
                              onClick={handleContinue}
                              style={{
                                backgroundColor: "#1D191C",
                                color: "white",
                              }}
                              disabled={!selectedAccountType}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AuthenticateImage />
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpAccountType;
