import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './instructorhome.css';
import InstructorSidebar from './InstructorSidebar';
import InstructoreHeader from './InstructoreHeader';
import { callApi } from '../../../Api/ApiCaller';
import routes from '../../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const InstructorHome = () => {

  const [myCourses, setmyCourses] = useState('')
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const getmyCourses = () => {
    let getRes = (res) => {
      setmyCourses(res?.data?.courses);
    };

    callApi("GET", routes.myCourses, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const checkBankInfo = () => {
    let getRes = (res) => {
      if (res.isBankAdded) {
        navigate('/instructorcreatecourse');
        // toast.success(res.message, {
        //   autoClose: 1500,
        //   onClose: () => {      
        //       navigate('/instructorcreatecourse');
        //   }
        //   })
            
      } else {
        toast.error(res.message, {
              autoClose: 1500
              })
      }
    };

    callApi("GET", routes.checkBankInfo, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  // const deleteCourse = (courseId) => {
  //   let getRes = (res) => {
  //     if (res.statusCode === 200 ) {
  //       getmyCourses();
  //       toast.success(res.message, {
  //         autoClose: 1500
  //         })
            
  //     } else {
  //       toast.error(res.message, {
  //             autoClose: 1500
  //             })
  //     }
  //   };

  //   callApi("DELETE", routes.deleteCourse+'/'+courseId, null, setIsLoading, getRes, (error) => {
  //     console.log("error", error);
  //   });
  // };
  useEffect(() => {
    // console.log(detail);
    getmyCourses()
  }, [])
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    setFilteredProducts(myCourses);
  }, [myCourses]);
  const handleSearch = (query) => {
    if (query.trim() === '') {
      // If search query is empty, show all products
      setFilteredProducts(myCourses);
    } else {
      // Otherwise, filter products based on the search query
      const filtered = myCourses.filter(course =>
        course?.title?.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };
  return (
    <>
    <ToastContainer/>
      <div className="overflow-hidden">
        <div className="row">
          {/* Sidebar */}
          <InstructorSidebar/>
          {/* Main Content */}
          <div className="col-md-10">
            <InstructoreHeader handleSearch={handleSearch}/>
            <div className='container-fluid my-4'>
              <h3 className='fw-bold'>My Courses</h3>
              <div className='row'>
              {filteredProducts && filteredProducts.map((course, index) => ( <div key={index} className="col-xl-4 col-lg-6">
                <div className="d-flex justify-content-center  my-3 mx-1">
        <div className="card p-3 bg-white border-0 shadow rounded-4 d-flex flex-row align-items-center w-100">
            <div className="about-product text-center">
                <img className=' rounded-4' src={course?.thumbnail} width="80" style={{height:'80px'}} />
            </div>
            <div className="stats  flex-grow-1">
                <div className="px-3">
                <div className="d-flex justify-content-between p-price ">
                    <Link  to={{
            pathname: '/instructorcoursedetails',
            search: `?courseId=${course?._id}`, 
          }} style={{ textDecoration: 'none', color:'black' }}>
                        <h6 className="mb-0"><b>{course?.title}</b></h6>
                    </Link>
                    <div className='d-flex justify-content-center'>
                    <div>
                    <span className="badge py-2 mx-4" style={{ fontSize: '10px', background: 'rgba(29, 25, 28, 0.1)', color: 'black', padding: '5px 10px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                    {course?.level} 
                    </span>
                    </div>
                    <div className='mt-1 position-relative'>
  <i className="fa-solid fa-xl fa-ellipsis-vertical " style={{cursor:'pointer'}}
    data-bs-toggle="dropdown"
    aria-expanded="false"></i>
  <ul className="dropdown-menu px-2 border-0 mt-2" style={{ left: '-100%' }}>
    <li><Link to={{
            pathname: '/instructoreditcourse',
            search: `?courseId=${course?._id}`, 
          }} className="dropdown-item border-bottom px-2 my-1" > <img className='mb-1 ' src="./img/Navbar/Edit.png" alt="" /> Edit </Link></li>
    {/* <li><button type='button' onClick={() => deleteCourse(course?._id)} className="dropdown-item  px-2 my-1" ><img className='mb-1' src="./img/courseinstructor/home/Delete.png" alt="" /> Delete</button></li> */}
    <li><button type='button' className="dropdown-item  px-2 my-1" ><img className='mb-1' src="./img/courseinstructor/home/Delete.png" alt="" /> Delete</button></li>
   
  </ul>
</div>

                    </div>
                    </div>
                    <div className="d-flex justify-content-between p-price mt-3">
                        <span style={{fontSize:'14px'}}><b>${course?.price}</b></span>
                        <span style={{fontSize:'14px'}}><b>3 Month Course</b></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
                </div>))}
              </div>
            </div>
            <div type='button' onClick={checkBankInfo}  className="plus-img">
            <img className='img-fluid' src="./img/courseinstructor/home/plusimg.png" alt="plus" />
          </div>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default InstructorHome;
