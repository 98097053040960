import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthenticateImage from "../AuthenticateImage";
import "./signup.css";
import { callApi } from "../../../Api/ApiCaller";
import routes from "../../../Api/routes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const UserSignUpProfile2 = () => {
  const [email, setEmail] = useState("");
  const [interests, setinterests] = useState([])
  const [selectedInterest, setSelectedInterest] = useState("");
  const [selectedInterestId, setSelectedInterestId] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedgender, setSelectedgender] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [isDropdownLevelOpen, setIsDropdownLevelOpen] = useState(false);
  const [isDropdowngenderOpen, setIsDropdowngenderOpen] = useState(false);
  const [goals, setgoals] = useState([])
  const [selectedgoal, setSelectedgoal] = useState("");
  const [selectedgoalId, setSelectedgoalId] = useState("");
  const [isDropdowngoalOpen, setIsDropdowngoalOpen] = useState(false);
  const [age, setage] = useState('');
  const [weight, setweight] = useState('100');
  const [height, setheight] = useState('5');
  const [accounttype, setaccounttype] = useState(
    localStorage.getItem("accountType")
  );
  // const [activityLevel, setactivityLevel] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { detail } = location.state || {};

  const getInterests = () => {
    let getRes = (res) => {
      setinterests(res.data.docs);
    };

    callApi("GET", routes.interests, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const getGoals = () => {
    let getRes = (res) => {
      setgoals(res.data.docs);
    };

    callApi("GET", routes.goals, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  useEffect(() => {
    // console.log(detail);
    getInterests()
    getGoals();
  }, [])
  
  const [isloading, setIsLoading] = useState(false);
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleInterestChange = (id) => {
    setSelectedInterest((prevSelectedInterests) => {
      if (prevSelectedInterests.includes(id)) {
        // Remove the ID if it's already in the array
        return prevSelectedInterests.filter((interestId) => interestId !== id);
      } else {
        // Add the ID if it's not in the array
        return [...prevSelectedInterests, id];
      }
    });
  };

  useEffect(() => {
    console.log("Selected Interest IDs:", selectedInterest);
  }, [selectedInterest]);

  const getSelectedInterestNames = () => {
    return interests
      .filter(interest => selectedInterest.includes(interest._id))
      .map(interest => interest.name)
      .join(", ");
  };


  const handleContinue = () => {
    navigate("/forgotpasswordotp");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handlegenderChange = (value) => {
    setSelectedgender(value);
    setIsDropdowngenderOpen(false);
  };
  const toggleDropdowngender = () => {
    setIsDropdowngenderOpen(!isDropdowngenderOpen);
  };
  const handleLevelChange = (value) => {
    setSelectedLevel(value);
    setIsDropdownLevelOpen(false);
  };
  const toggleDropdownLevel = () => {
    setIsDropdownLevelOpen(!isDropdownLevelOpen);
  };
  const handlegoalChange = (goal) => {
    setSelectedgoal((prevSelectedGoals) => {
      if (prevSelectedGoals.includes(goal._id)) {
        // Remove the goal ID if it's already in the array
        return prevSelectedGoals.filter((goalId) => goalId !== goal._id);
      } else {
        // Add the goal ID if it's not in the array
        return [...prevSelectedGoals, goal._id];
      }
    });
  };

  const getSelectedGoalNames = () => {
    return goals
      .filter((goal) => selectedgoal.includes(goal._id))
      .map((goal) => goal.name)
      .join(", ");
  };

  useEffect(() => {
    console.log(selectedgoal);
  }, [selectedgoal])
  const toggleDropdowngoal = () => {
    setIsDropdowngoalOpen(!isDropdowngoalOpen);
  };
  const [selectedweight, setSelectedweight] = useState("lb");

  const handleweightClick = (item, e) => {
    e.preventDefault();
    setSelectedweight(item);
  };
  const [selectedheight, setSelectedheight] = useState("ft");

  const handleheightClick = (item, e) => {
    e.preventDefault();
    setSelectedheight(item);
  };

  const handleAccountSuccessful = () => {
    
    let getRes = (res) => {
      if (res.statusCode == 200) {

        setShowModal(true);
          
        
      } else {
        toast.error(res.message, {
              autoClose: 1500
              })
      }
    };
    // const fileUrl = avatar;
    let body = {
      fullName: detail.fullName,
      avatar: detail.avatar,
      location: {
        type: "Point",
        coordinates: [72.343361, 31.04668],
        address: detail.location,
        // description: "Burewala"
      },
      interests: selectedInterest,
      gender: selectedgender,
      age: age,
      weight: {
        value: weight,
        unit: selectedweight,
      },
      height: {
        value: height,
        unit: selectedheight,
      },
      
      goals: selectedgoal,
      activityLevel: selectedLevel,

      // description: description,
    };
    console.log(body)
    callApi("POST", routes.completeProfile, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  
    console.log(detail.fullName);
    console.log(detail.avatar);
    console.log(detail.location);
    console.log(selectedInterest);
    console.log(selectedgender);
    console.log(age);
    console.log(weight);
    console.log(selectedweight);
    console.log(height);
    console.log(selectedheight);
    console.log(selectedgoal);
    console.log(selectedLevel);


  };

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let timer;
    if (showModal) {
      timer = setTimeout(() => {
        setShowModal(false);
        navigate("/");
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [showModal]);

  return (
    <>
    <ToastContainer/>
      <section className="overflow-hidden">
        <div className="">
          <div className="row">
            <div
              className="col-md-6 text-center text-sm-start "
              style={{ color: "#1D191C" }}
            >
              <div className="container-fluid d-flex justify-content-center align-items-center">
                <div className="w-100">
                  <div className="px-xl-5 ms-xl-4 mt-4 mb-4">
                    <img
                      className="mb-2 img-fluid"
                      src="./img/user/login/logo 1 (1).png"
                      alt=""
                      height="100"
                      width="100"
                    />
                    <span className="fw-bold eighteenfont mx-2">
                      Powering Your Platform
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center align-items-sm-start h-custom-2 px-xl-5 ms-xl-4 ">
                    <form style={{ maxWidth: "100%", width: "100%" }}>
                      <h4 className="fw-bold mb-1 pb-1">Build Your Profile</h4>
                      <div className="col-md-9">
                        <p className="text-secondary eighteenfont mt-3">
                          Check your email inbox for the OTP code we sent you.
                          Please enter it below to proceed.
                        </p>
                      </div>
                      <div className="form-group mb-3">
                        <label
                          className="form-label fw-bold"
                          htmlFor="interestSelect"
                        >
                          Select Your Interest
                        </label>
                        <div className="position-relative">
                          <div className="custom-dropdown">
                            <div
                              className=" rounded-4 py-3 px-2 border-0"
                              style={{
                                backgroundColor: "#FAFAFA",
                                height: "52px",
                              }}
                            >
                              <p  style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "90%" }}>
        {selectedInterest.length > 0
          ? <span>{getSelectedInterestNames()}</span>
          : <span className="text-muted">Dropdown to select</span>}
      </p>
                              <span
                                className="position-absolute top-50 end-0 translate-middle-y pe-3 dropdown-label"
                                onClick={toggleDropdown}
                              >
                                <img
                                  src="./img/user/signup/Arrow - Down 2.png"
                                  alt=""
                                />
                              </span>
                            </div>
                            {isDropdownOpen && (
        <div className="options p-2">
          <div className="row">
            {interests && interests.map((interest) => (
              <div className="col-12" key={interest._id}>
                <label className="d-flex justify-content-between align-items-center border-bottom">
                  <span>{interest.name}</span>
                  <input
                    type="checkbox"
                    name="interest"
                    value={interest.name}
                    checked={selectedInterest.includes(interest._id)}
                    style={{
                      accentColor: "#1D191C",
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                    onChange={() => handleInterestChange(interest._id)}
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label
                          className="form-label fw-bold"
                          htmlFor="interestSelect"
                        >
                          Select Your Gender
                        </label>
                        <div className="position-relative">
                          <div className="custom-dropdown">
                            <div
                              className=" rounded-4 py-3 px-2 border-0"
                              style={{
                                backgroundColor: "#FAFAFA",
                                height: "52px",
                              }}
                            >
                              {selectedgender
                                ? selectedgender
                                : <span className="text-low">Dropdown to select</span>}
                              <span
                                className="position-absolute top-50 end-0 translate-middle-y pe-3 dropdown-label"
                                onClick={toggleDropdowngender}
                              >
                                <img
                                  src="./img/user/signup/Arrow - Down 2.png"
                                  alt=""
                                />
                              </span>
                            </div>
                            {isDropdowngenderOpen && (
                              <div className="options p-2">
                                <div className="row">
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Male</span>
                                      <input
                                        type="radio"
                                        name="gender"
                                        value="Male"
                                        checked={selectedgender === "male"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handlegenderChange("male")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center ">
                                      <span>Female</span>
                                      <input
                                        type="radio"
                                        name="gender"
                                        value="Female"
                                        checked={selectedgender === "female"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handlegenderChange("female")
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label
                          className="form-label fw-bold"
                          htmlFor="form2Example18"
                        >
                          Enter Your Age
                        </label>
                        <div className="position-relative">
                          <input
                            type="number"
                            className="form-control rounded-4 py-2 px-2 border-0"
                            id="age"
                            placeholder="Age"
                            style={{
                              backgroundColor: "#FAFAFA",
                              height: "52px",
                            }}
                            value={age}
                            onChange={(e) => setage(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label
                              className="form-label fw-bold"
                              htmlFor="form2Example18"
                            >
                              Your Weight
                            </label>
                            <div className="col-md-12">
                              <div className="d-flex align-items-center">
                                <div className="position-relative col-md-7">
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control rounded-4 py-2 px-2 border-0"
                                    id="weight"
                                    placeholder="Weight"
                                    style={{
                                      backgroundColor: "#FAFAFA",
                                      height: "52px",
                                    }}
                                    value={weight}
                            onChange={(e) => setweight(e.target.value)}
                                  />
                                </div>
                                <div className="btn-group col-md-3 mx-3">
                                  <button
                                    type="button"
                                    className="btn dropdown-toggle rounded-4 border-0 "
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{
                                      backgroundColor: "#FAFAFA",
                                      height: "52px",
                                      fontWeight:'600'
                                    }}
                                  >
                                    {selectedweight}
                                  </button>
                                  <ul className="dropdown-menu">
      <li>
        <button
          className="dropdown-item"
          onClick={(e) => handleweightClick("t ", e)}
        >
          t
        </button>
      </li>
      <li>
        <button
          className="dropdown-item"
          onClick={(e) => handleweightClick("kg ", e)}
        >
          kg
        </button>
      </li>
      <li>
        <button
          className="dropdown-item"
          onClick={(e) => handleweightClick("g ", e)}
        >
          g
        </button>
      </li>
      <li>
        <button
          className="dropdown-item"
          onClick={(e) => handleweightClick("mg ", e)}
        >
          mg
        </button>
      </li>
      <li>
        <button
          className="dropdown-item"
          onClick={(e) => handleweightClick("lb ", e)}
        >
          lb
        </button>
      </li>
      <li>
        <button
          className="dropdown-item"
          onClick={(e) => handleweightClick("oz ", e)}
        >
          oz
        </button>
      </li>
    </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label
                              className="form-label fw-bold"
                              htmlFor="form2Example18"
                            >
                              Your Height
                            </label>
                            <div className="col-md-12">
                              <div className="d-flex align-items-center">
                                <div className="position-relative col-md-7">
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control rounded-4 py-2 px-2 border-0"
                                    id="height"
                                    placeholder="Height"
                                    style={{
                                      backgroundColor: "#FAFAFA",
                                      height: "52px",
                                    }}
                                    value={height}
                            onChange={(e) => setheight(e.target.value)}
                                  />
                                </div>
                                <div className="btn-group col-md-3 mx-3">
                                  <button
                                    type="button"
                                    className="btn dropdown-toggle rounded-4 border-0"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{
                                      backgroundColor: "#FAFAFA",
                                      height: "52px",
                                      fontWeight:'600'
                                    }}
                                  >
                                    {selectedheight}
                                  </button>
                                  <ul className="dropdown-menu">
  <li>
    <button
      className="dropdown-item"
      onClick={(e) => handleheightClick("m ", e)}
    >
      m
    </button>
  </li>
  <li>
    <button
      className="dropdown-item"
      onClick={(e) => handleheightClick("yd ", e)}
    >
      yd
    </button>
  </li>
  <li>
    <button
      className="dropdown-item"
      onClick={(e) => handleheightClick("ft ", e)}
    >
      ft
    </button>
  </li>
  <li>
    <button
      className="dropdown-item"
      onClick={(e) => handleheightClick("in ", e)}
    >
      in
    </button>
  </li>
  <li>
    <button
      className="dropdown-item"
      onClick={(e) => handleheightClick("cm ", e)}
    >
      cm
    </button>
  </li>
</ul>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label
                          className="form-label fw-bold"
                          htmlFor="interestSelect"
                        >
                          Select Your Goals
                        </label>
                        <div className="position-relative">
                          <div className="custom-dropdown">
                            <div
                              className=" rounded-4 py-3 px-2 border-0"
                              style={{
                                backgroundColor: "#FAFAFA",
                                height: "52px",
                              }}
                            >
                              <p  style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "90%" }}>
        {selectedgoal.length > 0
          ? <span>{getSelectedGoalNames()}</span>
          : <span className="text-muted">Dropdown to select</span>}
      </p>
                              <span
                                className="position-absolute top-50 end-0 translate-middle-y pe-3 dropdown-label"
                                onClick={toggleDropdowngoal}
                              >
                                <img
                                  src="./img/user/signup/Arrow - Down 2.png"
                                  alt=""
                                />
                              </span>
                            </div>
                            {isDropdowngoalOpen && (
        <div className="options p-2 border">
          <div className="row">
            {goals.map((goal) => (
              <div className="col-12" key={goal._id}>
                <label className="d-flex justify-content-between align-items-center border-bottom">
                  <span>{goal.name}</span>
                  <input
                    type="checkbox"
                    name="goal"
                    value={goal.name}
                    checked={selectedgoal.includes(goal._id)}
                    style={{
                      accentColor: "#1D191C",
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                    onChange={() => handlegoalChange(goal)}
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label
                          className="form-label fw-bold"
                          htmlFor="interestSelect"
                        >
                          Physical Activity Level
                        </label>
                      <div className="position-relative">
                          <div className="custom-dropdown">
                            <div
                              className=" rounded-4 py-3 px-2 border-0"
                              style={{
                                backgroundColor: "#FAFAFA",
                                height: "52px",
                              }}
                            >
                              {selectedLevel
                                ? selectedLevel
                                : <span className="text-low">Dropdown to select</span>}
                              <span
                                className="position-absolute top-50 end-0 translate-middle-y pe-3 dropdown-label"
                                onClick={toggleDropdownLevel}
                              >
                                <img
                                  src="./img/user/signup/Arrow - Down 2.png"
                                  alt=""
                                />
                              </span>
                            </div>
                            {isDropdownLevelOpen && (
                              <div className="options p-2">
                                <div className="row">
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Beginner</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Beginner"
                                        checked={selectedLevel === "beginner"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("beginner")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Intermediate</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Intermediate"
                                        checked={selectedLevel === "intermediate"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("intermediate")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center border-bottom">
                                      <span>Advance</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="Advance"
                                        checked={selectedLevel === "advance"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("advance")
                                        }
                                      />
                                    </label>
                                  </div>
                                  <div className="col-12">
                                    <label className="d-flex justify-content-between align-items-center ">
                                      <span>All</span>
                                      <input
                                        type="radio"
                                        name="level"
                                        value="All"
                                        checked={selectedLevel === "all"}
                                        style={{
                                          accentColor: "#1D191C",
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "8px",
                                        }}
                                        onChange={() =>
                                          handleLevelChange("all")
                                        }
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group mb-3">
                        <label
                          className="form-label fw-bold"
                          htmlFor="form2Example18"
                        >
                          Physical Activity Level
                        </label>
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control rounded-4 py-2 px-2 border-0"
                            id="physical"
                            placeholder="Enter"
                            style={{
                              backgroundColor: "#FAFAFA",
                              height: "52px",
                            }}
                            value={activityLevel}
                            onChange={(e) => setactivityLevel(e.target.value)}
                          />
                        </div>
                      </div> */}
                      <br />
                      <div className="">
                        <div className="text-center align-items-center">
                          <div className="mb-5 mt-3 mx-auto">
                            <button
                              className="btn rounded-pill px-5 py-3 shadow w-100"
                              onClick={(e) => {
                                e.preventDefault();
                                handleAccountSuccessful();
                              }}
                              style={{
                                backgroundColor: "#1D191C",
                                color: "white",
                              }}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    {showModal && (
                      <div
                        className="modal show d-flex align-items-center justify-content-center"
                        id="paymentModal"
                        tabIndex="-1"
                        aria-labelledby="paymentModalLabel"
                        style={{
                          display: "block",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div
                          className="modal-dialog "
                          // style={{ marginTop: "5%" }}
                        >
                          <div className="modal-content my-1">
                            <div className="modal-body text-center">
                              <img
                                src="./img/courses/coursepayment/coursepaymentsuccessfull.png"
                                alt=""
                                height="100"
                                width="100"
                              />
                              <h4 className="my-3">
                                <b>Account Setup Successfully!</b>
                              </h4>
                              <h6>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Pellentesque vulputate placerat
                                porta. Ut commodo nulla vitae eleifend sodales.
                                Fusce ornare.
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <AuthenticateImage />
          </div>
        </div>
      </section>
    </>
  );
};

export default UserSignUpProfile2;
