import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ProgressTrackingWorkoutContent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const workoutId = queryParams.get("workoutId");
    const [isLoading, setIsLoading] = useState(false);
    const [ongoingWorkout, setOngoingWorkout] = useState([]);
    const [completeWorkout, setCompleteWorkout] = useState(null);
    const [openWorkoutId, setopenWorkoutId] = useState(0);

    const getWorkout = () => {
        callApi("GET", routes.getOneOngoingWorkout+'/'+workoutId, null, setIsLoading, handleGetWorkoutResponse, handleError);
    };

    const handleGetWorkoutResponse = (res) => {
        setCompleteWorkout(res);
        setOngoingWorkout(res.progressWorkout);
        setopenWorkoutId(res?.progressWorkout?.workout?._id)
    };

    const handleError = (error) => {
        console.log("Error fetching workout:", error);
    };

    useEffect(() => {
        if (workoutId) {
            getWorkout();
        }
    }, [workoutId]);

    const isVideoCompleted = (videoId) => {
        if (!ongoingWorkout?.workoutVideos) return false;
        const video = ongoingWorkout.workoutVideos.find(video => video?.video === videoId);
        return video ? video.completed : false;
    };

    const checkIfWorkoutCompleted = () => {
        if (completeWorkout && completeWorkout.totalVideos === completeWorkout.watchedVideos) {
            // console.log("Workout is completed");
            let getRes = (res) => {
                if (res.statusCode == 200) {
                    toast.success(res.message, {
                        autoClose: 1500
                    })
                    
                } else {
                    toast.error(res.message, {
                        autoClose: 1500
                    })
                }
            };
            
                callApi("POST", routes.markWorkoutComplete+'/'+openWorkoutId, null, setIsLoading, getRes, (error) => {
                  console.log("error", error);
                });
        }
    };

    useEffect(() => {
        checkIfWorkoutCompleted();
    }, [completeWorkout]);

    return (
        <>
    <ToastContainer/>
    <div className="container-fluid" style={{ minHeight: '488px' }}>
            <div className='mt-5'>
                <h1 className="how-it-works-title">Progress Tracking</h1>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto text-center my-4">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className="col-md-9 mx-auto">
                    <div className="row" style={{marginBottom:'10%'}}>
                        {ongoingWorkout && ongoingWorkout?.workout?.workoutActivity.map((workout, index) => (
                            <div className="col-md-6" key={index}>
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="px-3">
                                        <input
                                            className="form-check-input shadow rounded-2"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                            style={{ width: '24px', height: '24px' }}
                                            checked={isVideoCompleted(workout?._id)} // Bind the checked state to the checkbox
                                            readOnly // Make the checkbox read-only
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center w-100 mt-3">
                                        <div className="card border-0 rounded-4 d-flex flex-row align-items-center w-100 shadow" >
                                            <div>
                                                <img className='img-fluid rounded-start-4' src="./img/courses/workoutdetails/Rectangle 148441 (1).png" width="124" height='130' alt="Workout" />
                                            </div>
                                            <div className="stats flex-grow-1">
                                                <div className="px-3">
                                                    <Link to={{
                                                        pathname: "/workoutstart",
                                                        search: `?workoutActivity=${workout?._id}&workoutId=${workoutId}`, 
                                                    }} style={{ textDecoration: 'none', color: 'black' }}>
                                                        <h5 className="my-3"><b>{workout?.title}</b></h5>
                                                    </Link>
                                                    <p className='text-secondary'>{workout?.activityDuration.duration} {workout?.activityDuration.unit}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        </>
        
    );
};

export default ProgressTrackingWorkoutContent;
