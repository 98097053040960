import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const stripePromise = loadStripe('pk_test_51PacoIH8rwhS1mDiNUNu0lx8zzmEf633SktSvCkWvP1h32gaxC0YMkQUnTPaTeJoXCSvNsyle9D0StkSi4kJsmb400ZW1iMyUh');

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const clientSecret = location.state.clientSecret;
  const paymentIntentId = location.state.paymentIntent;
  const [isloading, setIsLoading] = useState(false);
  const [confirmPayment, setconfirmPayment] = useState('');
  const navigate = useNavigate();
  const handleconfirmPayment =() =>{
  let getRes = (res) => {
    if (res.statusCode === 200) {
      toast.success("Course Enrolled Successfully", {
        autoClose: 1500,
        onClose: () => {
          
            navigate('/courses');
        }
      });
} else {
toast.error(res.message, {
      autoClose: 1500
      })

      
    }
  };
  let body = {
    paymentIntentId: paymentIntentId,
    
  };
  callApi(
    'POST',
    routes.confirmPayment,
    body,
    setIsLoading,
    getRes,
    (error) => {
      console.log("error", error);
    }
  );
};

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log('Payment successful!', paymentIntent);
      
      setconfirmPayment(paymentIntent)
      console.log(paymentIntent.id)
      handleconfirmPayment();
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow p-4" style={{ border: 'none', borderRadius: '1rem' }}>
            <h2 className="card-title text-center mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Payment Details</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-4">
                <label htmlFor="card-element">Credit or Debit Card</label>
                <div id="card-element" className="form-control" style={{ padding: '0.5rem', border: '1px solid #ced4da', borderRadius: '0.25rem' }}>
                  <CardElement />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block mt-4" disabled={!stripe} style={{ fontSize: '1.1rem', padding: '0.75rem' }}>
                Pay
              </button>
              {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckoutForm = () => (
 <> <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
  <ToastContainer/>
  </>
);

export default CheckoutForm;
