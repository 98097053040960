import React, { useEffect, useRef, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { callApi } from "../../Api/ApiCaller";
import routes from "../../Api/routes";
import ReactPlayer from "react-player";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WorkoutStart = () => {
  const [showModal, setShowModal] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const countdownRef = useRef(null);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [ongoingWorkout, setOngoingWorkout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [activityduration, setActivityDuration] = useState(0);
  const [watchedTime, setWatchedTime] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [openWorkoutId, setOpenWorkoutId] = useState(0);
  const workoutId = queryParams.get("workoutId");
  const workoutActivity = queryParams.get("workoutActivity");
  const playerRef = useRef(null);

  const handleVideoDoneSuccessful = () => {
    let watchvedio = Math.round(watchedTime / 60);
    let totalvedio = Math.round(duration / 60);
    console.log(`Total video time: ${totalvedio} minutes`);
    console.log(`Watched time: ${watchvedio} minutes`);

    let body = {
      watchedDuration: watchvedio,
      videoDuration: totalvedio,
    };

    let getRes = (res) => {
      if (res.statusCode === 200) {
        toast.success(res.message, { autoClose: 1500 });
      } else {
        toast.error(res.message, { autoClose: 1500 });
      }
    };

    callApi("POST", `${routes.markVideoComplete}/${openWorkoutId}/${currentActivity?._id}`, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    if (workoutId && workoutActivity) {
      getWorkout();
    }
  }, [workoutId, workoutActivity]);

  useEffect(() => {
    if (ongoingWorkout) {
      const currentActivity = ongoingWorkout.workout.workoutActivity[currentActivityIndex];
      const exerciseDuration = currentActivity?.exerciseDuration;
      let durationInSeconds = exerciseDuration?.duration;
      
      if (exerciseDuration?.unit === "minutes") {
        durationInSeconds *= 60;
      }

      setActivityDuration(durationInSeconds);
      setTimerKey((prevKey) => prevKey + 1); // Reset the timer key
      setIsPlaying(false); // Pause the timer initially
    }
  }, [currentActivityIndex, ongoingWorkout]);

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleProgress = (state) => {
    setWatchedTime(state.playedSeconds);
  };

  const handleStart = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleTimerComplete = () => {
    setShowModal(true);
  };

  const nextWorkout = () => {
    setShowModal(false);
    if (currentActivityIndex < ongoingWorkout.workout.workoutActivity.length - 1) {
      setCurrentActivityIndex(currentActivityIndex + 1);
    }
  };

  const prevWorkout = () => {
    if (currentActivityIndex > 0) {
      setCurrentActivityIndex(currentActivityIndex - 1);
      handlePause();
    }
  };

  const skipWorkout = () => {
    if (currentActivityIndex < ongoingWorkout.workout.workoutActivity.length - 1) {
      setCurrentActivityIndex(currentActivityIndex + 1);
      handlePause();
    }
  };

  const getWorkout = () => {
    let getRes = (res) => {
      setOngoingWorkout(res.progressWorkout);
      setOpenWorkoutId(res?.progressWorkout?.workout?._id);

      const index = res.progressWorkout.workout.workoutActivity.findIndex(
        (activity) => activity._id === workoutActivity
      );
      if (index !== -1) {
        setCurrentActivityIndex(index);
      }
    };

    callApi("GET", `${routes.getOneOngoingWorkout}/${workoutId}`, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  if (!ongoingWorkout) return null; // Or a loading indicator

  const currentActivity = ongoingWorkout.workout.workoutActivity[currentActivityIndex];

  const splitTitle = (title, charLimit) => {
    if (!title) return ["", ""];

    let splitIndex = charLimit;

    if (title.length > charLimit) {
      while (splitIndex > 0 && title[splitIndex] !== " ") {
        splitIndex--;
      }

      if (splitIndex === 0) {
        splitIndex = charLimit;
      }
    }

    const firstPart = title.substring(0, splitIndex);
    const secondPart = title.substring(splitIndex).trim();

    return [firstPart, secondPart];
  };

  const [firstPart, secondPart] = splitTitle(currentActivity.title, 25);

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="row my-4 justify-content-center">
          <div className="col-md-5 col-12 px-0">
            <div className="banner-container">
              <h1 className="banner-employes">{firstPart}</h1>
              <div className="banner-employes-underline"></div>
            </div>
            <h5 className="banner-employes">{secondPart}</h5>
          </div>
          <div className="col-md-5 col-12 mt-4 px-0">
            <span className="text-subheading px-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore consectetur. adipi elit, sed
              do eiusmod tempor incididunt ut labore consectetur.
            </span>
          </div>
        </div>
      </div>

      <div className="container text-center align-items-center my-2">
        <div className="d-flex justify-content-center align-items-center my-2">
          <ReactPlayer
            className="flex-shrink-1 py-2 px-3 mx-1"
            ref={playerRef}
            controls={true}
            url={currentActivity.url}
            onEnded={handleVideoDoneSuccessful}
            onDuration={handleDuration}
            onProgress={handleProgress}
            style={{ width: "660px", height: "380px" }}
          />
        </div>
        <div className="my-5 text-center align-items-center container">
          <h5>
            <b>{currentActivity.title}</b>
          </h5>
          <div className="d-flex justify-content-center my-4">
            <CountdownCircleTimer
              key={timerKey}
              isPlaying={isPlaying}
              duration={activityduration}
              colors={[["#1D191C"]]}
              strokeWidth={10}
              size={150}
              trailColor="#FFFFFF"
              onComplete={handleTimerComplete}
            >
              {({ remainingTime }) => (
                <div style={{ fontSize: "32px", fontWeight: "700" }}>
                  {remainingTime}
                </div>
              )}
            </CountdownCircleTimer>
          </div>
          <div className="my-1 col-md-2 mx-auto col-8">
            <button
              className="btn rounded-pill px-2 py-3 w-100"
              onClick={handleStart}
              style={{
                backgroundColor: "#1D191C",
                color: "white",
                fontSize: "18px",
              }}
            >
              Start
            </button>
          </div>
          <div className="my-1 col-md-2 mx-auto col-8">
            <button
              className="btn rounded-pill px-2 py-3 w-100"
              onClick={handlePause}
              style={{
                backgroundColor: "#1D191C",
                color: "white",
                fontSize: "18px",
              }}
            >
              Pause
            </button>
          </div>
          <div className="my-5">
            <div className="d-flex justify-content-center">
              <div className="mx-2">
                <button
                  className="btn rounded-pill px-5 py-3 w-100"
                  onClick={prevWorkout}
                  style={{
                    backgroundColor: "#e9e8e9",
                    color: "#1D191C",
                    fontSize: "16px",
                  }}
                >
                  <img
                    src="./img/courses/workoutdetails/skipleft.png"
                    alt=""
                    className="mx-md-2 mb-1"
                  />
                  <b>Previous</b>
                </button>
              </div>
              <div className="mx-2">
                <button
                  className="btn rounded-pill px-5 py-3 w-100"
                  onClick={skipWorkout}
                  style={{
                    backgroundColor: "#e9e8e9",
                    color: "#1D191C",
                    fontSize: "16px",
                  }}
                >
                  <b>Skip</b>
                  <img
                    src="./img/courses/workoutdetails/skipright.png"
                    alt=""
                    className="mx-md-2 mb-1"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="modal show"
          id="paymentModal"
          tabIndex="-1"
          aria-labelledby="paymentModalLabel"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog" style={{ marginTop: "5%" }}>
            <div className="modal-content my-1 rounded-4">
              <div className="modal-body text-center">
                <div className="my-2 pb-3 border-bottom">
                  <img
                    src="./img/courses/workoutdetails/workoutdone.png"
                    alt=""
                  />
                  <h4 className="my-3">
                    <b>Congratulations!</b>
                  </h4>
                  <h6>You have completed the Activity!</h6>
                </div>
                <div className="d-flex justify-content-center text-center mt-3 mb-2 text-secondary">
                  <div className="px-3 mx-2">
                    <p className="mb-2 h5">{ongoingWorkout?.workout?.workoutActivity?.length ?? 0}</p>
                    <p className="text-muted mb-0">Workout</p>
                  </div>
                  <div className="px-3 mx-2">
                    <p className="mb-2 h5">{(duration * 0.083).toFixed(3)}</p>
                    <p className="text-muted mb-0">Cal</p>
                  </div>
                  <div className="px-3 mx-2">
                    <p className="mb-2 h5">{duration}</p>
                    <p className="text-muted mb-0">Seconds</p>
                  </div>
                </div>
                <div className="my-4 mx-3">
                  <Link
                    className="btn rounded-pill px-5 py-3 w-100"
                    to={"/"}
                    style={{
                      backgroundColor: "#E2E2E2",
                      color: "#9E9E9E",
                      fontSize: "18px",
                    }}
                  >
                    Go to Home
                  </Link>
                </div>
                <div className="my-4 mx-3">
                  <button
                    className="btn rounded-pill px-5 py-3 w-100"
                    onClick={nextWorkout}
                    style={{
                      backgroundColor: "#1D191C",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    Next Workout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WorkoutStart;
