import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';

const Footer = () => {
  const [socialLinks, setSocialLinks] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const getSocialLinks = () => {
    
        let getRes = (res) => {
          //  localStorage.setItem("userData", res?.data?.user);
          setSocialLinks(res.socialMediaLinks);
          // console.log(res.socialMediaLinks)
        };
    
        callApi("GET", routes.socialMediaLinks, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      useEffect(() => {
    
        getSocialLinks();
      }, []);
  return (
    <>
    <div className="">
  <footer
          className="text-center text-lg-start text-white "
          style={{backgroundColor: "#1D191C"}}
          >
    <div className="container-fluid p-4 pb-0">
      <section className="">
        <div className="row">
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto my-3">
            {/* <h6 className="text-uppercase mb-4 font-weight-bold">
              Company name
            </h6> */}
            <img className='mb-4' src="./img/footer/logo 1 1.png" alt="" />
            <h5 style={{fontWeight:'300'}} className='mb-4'>
            Join our community and start your journey towards digital mastery and fitness excellence today! 
            </h5>
            <a className=" mx-1" href={socialLinks?.twitter} target="_blank"><img src="./img/footer/twitterfooter.png" alt="" /></a>
            <a className=" mx-1" href={socialLinks?.instagram} target="_blank"><img src="./img/footer/instafooter.png" alt="" /></a>
            <a className=" mx-1" href={socialLinks?.linkedin} target="_blank"><img src="./img/footer/linkfooter.png" alt="" /></a>
            <a className=" mx-1" href={socialLinks?.facebook} target="_blank"><img src="./img/footer/ballfooter.png" alt="" /></a>
              
            
          </div>

          <hr className="w-100 clearfix d-md-none" />

          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
            <h5 className=" mb-4 "><b>Quick Links</b></h5>
            <p>
              <Link to='/' style={{textDecoration:'none'}} className="text-white">Home</Link>
            </p>
            <p>
              <Link to='/aboutus' style={{textDecoration:'none'}} className="text-white">About Us</Link>
            </p>
            <p>
              <Link to='/contactus' style={{textDecoration:'none'}} className="text-white">Contact Us</Link>
            </p>
            <p>
              <Link to='/courses' style={{textDecoration:'none'}} className="text-white">Our Courses</Link>
            </p>
          </div>

          <hr className="w-100 clearfix d-md-none" />

          <hr className="w-100 clearfix d-md-none" />

          <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
            <h5 className=" mb-4 "><b>Quick Access</b></h5>
            <p>
              <Link to='/privacypolicy' style={{textDecoration:'none'}} className="text-white">Privacy Policy</Link>
            </p>
            <p>
              <Link to='/termsandcondition' style={{textDecoration:'none'}} className="text-white">Terms of Use</Link>
            </p>

            
          </div>

          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
            <h5 className=" mb-4 "><b>Support</b></h5>

            <p><i className="fa-solid fa-phone" style={{ marginRight:'15px'}}></i>+1 - 202 -55-0179</p>
            <p><i className="fa-regular fa-envelope fa-lg" style={{ marginRight:'15px'}}></i>support@smartinterion.com</p>
            <p><i className="fa-solid fa-location-dot fa-lg" style={{ marginRight:'15px'}}></i>1840 E Garvey Avenue Street West Covina, CA 91791, US</p>
          </div>
        </div>
      </section>
    </div>

    <div
         className="text-center p-3 container border-top mt-3" style={{fontFamily:'Poppins'}} >
      <i className="fa-regular fa-copyright"></i> 2023 Digital Craft. All Right Reserved
    </div>
  </footer>
</div>
    </>
  )
}

export default Footer