import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Picker from '@emoji-mart/react';
import socketIO from 'socket.io-client';
import InstructorSidebar from '../Dashboard/InstructorSidebar';
import InstructoreHeader from '../Dashboard/InstructoreHeader';
import '../Dashboard/instructorhome.css';
import '../../Inbox/inbox.css';
import { useSocket } from '../../Context/SocketContext';
import { S3 } from "aws-sdk";
import ReactPlayer from 'react-player';

// const socket = socketIO('http://ec2-13-59-83-138.us-east-2.compute.amazonaws.com');

const InstructorInbox = () => {
	const socket = useSocket();
	const storedUserData = localStorage.getItem('userData');
    const [user, setUser] = useState(storedUserData ? JSON.parse(storedUserData) : null);
    // const user = useSelector((e) => e.userDataSlice.userData);
    const [message, setMessage] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [perLine, setPerLine] = useState(25);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showSend, setShowSend] = useState(false);
    const [chatHeads, setChatHeads] = useState([]);
    const [activeChat, setActiveChat] = useState(null);
    const [activeChatDetails, setActiveChatDetails] = useState(null);

    const [chatHistory, setChatHistory] = useState([]);
    const [messageType, setmessageType] = useState('text');
    const [uploadedFileName, setUploadedFileName] = useState('');

const test= [
	'Amish', "Massab",'Humais'
]

const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    let formattedTime = new Intl.DateTimeFormat('en-US', options).format(date);
    formattedTime = formattedTime.replace('AM', 'am').replace('PM', 'pm');
    return formattedTime;
};
    useEffect(() => {
        if (user) {
			// console.log(user);
            socket.emit('user-enter',  user );

            socket.emit('get-inboxes',  user );
            socket.on('inboxes', (data) => {
				// console.log( data)
                setChatHeads(data?.inboxes);
            });

            // return () => {
            //     socket.off('inboxes');
            //     if (activeChat) {
            //         socket.emit('leave-room',  user, activeChat );
            //     }
            // };
        }
    }, [user]);

    const handleFileChange = async (e) => {
        const validFileTypes = [
            'application/pdf',
            'text/plain',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/rtf',
            'application/vnd.oasis.opendocument.text',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ];
        const file = e.target.files[0];
        if (file && validFileTypes.includes(file.type)) {
            setUploadedFileName(file.name);
            const fileUrl = await uploadFileOnS3(file);
    //   setImageURL(fileUrl);
            setMessage(fileUrl);
            // console.log(fileUrl);
            setmessageType('file');
        setShowSend(e.target.value.trim() !== '');

        } else {
            alert('Please select a valid file.');
        }
        
      };
      const uploadFileOnS3 = async (file) => {
        const s3bucket = new S3({
          region: 'us-east-2',
          accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
          secretAccessKey: '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',
          signatureVersion: 'v4',
        });
    
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const arrayBuffer = reader.result;
            // setImageURL(reader.result);
            const params = {
              Bucket: 's3echobucket',
              Key: file.name,
              Body: arrayBuffer,
              ContentDisposition: `inline;filename="${file.name}"`,
              ContentType: file.type,
            };
    
            s3bucket.upload(params, (err, data) => {
              if (err) {
                reject(err);
              } else {
                resolve(data.Location);
              }
            });
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
      };

      const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const fileUrl = await uploadImageOnS3(file);
    //   setImageURL(fileUrl);
            setMessage(fileUrl);
            // console.log(fileUrl);
            setmessageType('photo');
        setShowSend(e.target.value.trim() !== '');

        }
         else if(file && file.type.startsWith('video/')){
            
                const fileUrl = await uploadImageOnS3(file);
        //   setImageURL(fileUrl);
                setMessage(fileUrl);
                // console.log(fileUrl);
                setmessageType('video');
            setShowSend(e.target.value.trim() !== '');
         }
        
        else {
            alert('Please select a valid image or video file.');
        }
    };
    const uploadImageOnS3 = async (file) => {
        const s3bucket = new S3({
          region: 'us-east-2',
          accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
          secretAccessKey: '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',
          signatureVersion: 'v4',
        });
    
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const arrayBuffer = reader.result;
            // setImageURL(reader.result);
            const params = {
              Bucket: 's3echobucket',
              Key: file.name,
              Body: arrayBuffer,
              ContentDisposition: `inline;filename="${file.name}"`,
              ContentType: file.type,
            };
    
            s3bucket.upload(params, (err, data) => {
              if (err) {
                reject(err);
              } else {
                resolve(data.Location);
              }
            });
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
      };

    const handleInputChange = (e) => {
        setMessage(e.target.value);
        setShowSend(e.target.value.trim() !== '');
		setmessageType('text')
    };

    const handleEmojiClick = (emoji) => {
        setMessage(message + emoji.native);
    };

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    const handleSend = () => {
        if (message.trim() !== '' && activeChat) {
            // const newMessage = {
            //     user,
            //     to: activeChat,
            //     message,
            //     messageType: 'text',
            // };
            socket.emit('send-message', user, activeChat,message,messageType);
            
            setMessage('');
        }
    };

    const truncateText = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text.substring(0, maxLength) + '....';
        }
        return text;
    };

    const enterChat = (chat) => {
		const endUser = chat?.users?.[0]?._id === chat?._id ? chat?.users?.[1] : chat?.users?.[0]
		console.log(endUser);
		console.log(chat);
        setActiveChatDetails(endUser);
        setActiveChat(endUser?._id);
        socket.emit('join-chat',  user, endUser?._id );
        socket.on('messages', (data) => {
            setChatHistory(data?.messages);
            console.log(data?.messages);
        });
    };

    useEffect(() => {
        const scrollToBottom = () => {
            const chatContainer = document.querySelector('.chat-messages');
            if (chatContainer) {
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }
        };

        scrollToBottom(); // Scroll to bottom on component mount and when messages change

        const calculatePerLine = () => {
            const emojiPickerWrapper = document.querySelector('.emoji-picker-wrapper');
            if (emojiPickerWrapper) {
                const wrapperWidth = emojiPickerWrapper.clientWidth;
                const emojisPerLine = Math.floor(wrapperWidth / 36); // Assuming each emoji button is 36px wide
                setPerLine(emojisPerLine);
            }
        };

        calculatePerLine();

        window.addEventListener('resize', calculatePerLine);

        return () => {
            window.removeEventListener('resize', calculatePerLine);
        };
    }, [chatHistory]);

    return (
        <>
            <div className="overflow-hidden">
                <div className="row">
                    <InstructorSidebar />
                    <div className="col-md-10">
                        <InstructoreHeader />
                        <div className="container-fluid my-4">
                            <h3 className="fw-bold">Inbox</h3>
                            <div className="my-4">
                                <main className="content" style={{ marginBottom: '10%' }}>
                                    <div className="p-0">
                                        <div className="card rounded-4">
                                            <div className="row g-0">
                                                <div className="col-12 col-lg-5 col-xl-3 p-2">
                                                    <div className="left-chats">
                                                        <div className="px-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1 position-relative">
                                                                    <input type="text" className="form-control my-1 border-0 py-2" placeholder="Search agent" style={{ backgroundColor: '#FAFAFA' }} />
                                                                    <div className="position-absolute top-50 end-0 translate-middle-y">
                                                                        <i className="fas fa-search text-muted mx-3"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {chatHeads && chatHeads.map((chat) => (
                                        <button className="list-group-item list-group-item-action border-0 p-2" onClick={() => enterChat(chat)}>
                                            <div className="d-flex align-items-start border-bottom pb-2">
                                                <img src={chat?.users?.[0]?.avatar} className="rounded-circle mr-1" alt="Chris Wood" width="44" height="44" />
                                                <div className='mx-2 w-100'>
                                                    <div className="d-flex justify-content-between " style={{ fontSize: '14px' }}>
                                                        <div className=" ml-3">
                                                            <span><b>{chat?.users?.[0]?.fullName}</b></span>
                                                        </div>
                                                        <div><span className='text-muted'>{formatTime(chat?.updatedAt)}</span></div>
                                                    </div>
                                                    <div style={{ fontSize: '14px' }} className='text-muted'><span>{truncateText(chat?.lastMessage, 31)}</span></div>
                                                </div>
                                            </div>
                                        </button>
                                    ))}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-7 col-xl-9 p-2">
                                                    <div>
                                                        <div className="py-2 px-2 border-bottom">
                                                            <div className="d-flex align-items-center py-1">
                                                                <div className="position-relative">
                                                                    <img src={activeChatDetails?.avatar} className="rounded-circle mr-1" alt="Connecting..." width="44" height="44" />
                                                                </div>
                                                                <div className="flex-grow-1 pl-3 mx-3">
                                                                    <strong>{activeChatDetails?.fullName}</strong>
                                                                    {activeChatDetails?.isOnline ? <div className="text-muted small">Active Now <span className="fas fa-circle chat-online fa-xs mx-2"></span></div>: 
                                                                    <div className="text-muted small">Offline <span className="fas fa-circle chat-offline fa-xs mx-2"></span></div>}
                                                                </div>
                                                                <div>
                                                                    {/* <i className="fa-solid fa-lg fa-ellipsis-vertical"></i> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="position-relative">
                                                        <div className="chat-messages p-4">
                                                        {chatHistory && chatHistory.slice().reverse().map((msg, index) => (
                <div key={index} className={`chat-message-${msg.sender._id === user?._id ? 'right' : 'left'} pb-4`}>
                    <div>
                        <img src={msg.sender.avatar} className="rounded-circle mr-1" alt="User" width="48" height="48" />
                    </div>
                    <div>
                        {msg.type === 'photo' ? (
                            <img className='flex-shrink-1 px-3 mx-1' src={msg.message} alt="Sent" style={{ maxWidth: '100%' }} />
                        ) : msg.type === 'file' ? (
                            <a href={msg.message} className='flex-shrink-1 py-2 px-3 mx-1' style={{ display: 'block', textDecoration: 'none', color: msg.sender._id === user?._id ? 'white' : 'black', backgroundColor: msg.sender._id === user?._id ? 'dark' : 'light', borderRadius: msg.sender._id === user?._id ? '16px 4px 16px 16px' : '4px 16px 16px 16px' }} target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn btn-primary" data-mdb-ripple-init>Open File <i className="fa-regular fa-folder-open"></i></button>
                            </a>
                        ) : msg.type === 'video' ? (
                            <ReactPlayer
                            className='flex-shrink-1 py-2 px-3 mx-1'
                                url={msg.message}
                                controls
                                width='100%'
                                height='auto'
                            />
                        ) : (
                            <div className={`flex-shrink-1 py-4 px-3 mx-3 ${msg.sender._id === user?._id ? 'bg-dark' : 'bg-light'}`} style={{ borderRadius: msg.sender._id === user?._id ? '16px 4px 16px 16px' : '4px 16px 16px 16px', color: msg.sender._id === user?._id ? 'white' : 'black' }}>
                                {msg.message}
                            </div>
                        )}
                        <div className={`text-muted ${msg?.sender?._id === user?._id ? 'text-end' : ''} small text-nowrap mt-1 mx-3`}>
                            {formatTime(msg.createdAt)} {msg?.seen ? <img src="./img/inbox/Group (2).png" alt="" /> : null}
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </div>

                                                        <div className="flex-grow-0 py-3 px-4 border-top">
                                                            <div className="d-flex justify-content-center">
                                                                <div>
                                                                    <img src="./img/inbox/Group 1686553947.png" onClick={togglePicker} alt="" />
                                                                </div>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0"
                                                                        placeholder="Type your message"
                                                                        value={message}
                                                                        onChange={handleInputChange}
                                                                        onClick={() => setShowPicker(false)}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="file"
                                                                        accept=".pdf,.txt,.doc,.docx,.rtf,.odt,.xls,.xlsx,.ppt,.pptx"
                                                                        style={{ display: 'none' }}
                                                                        id="fileInput"
                                                                        onChange={handleFileChange}
                                                                    />
                                                                    <label htmlFor="fileInput" className="mt-2">
                                                                        <img src="./img/inbox/Frame (1).png" alt="Upload File" style={{ cursor: 'pointer' }} />
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*,video/*"
                                                                        style={{ display: 'none' }}
                                                                        id="imageInput"
                                                                        onChange={handleImageChange}
                                                                    />
                                                                    <label htmlFor="imageInput" className="mt-2 mx-2">
                                                                        <img src="./img/inbox/Camera.png" alt="Upload Image" style={{ cursor: 'pointer' }} />
                                                                    </label>
                                                                </div>
                                                                <div className='mt-2' style={{cursor:'pointer'}} onClick={handleSend}>
                                                    <img src="./img/inbox/send.png" alt="" width="25px" height="25px" />
                                                </div>
                                                                {/* {showSend ? (
                                                <div className='mt-2' onClick={handleSend}>
                                                    <img src="./img/inbox/send.png" alt="" width="25px" height="25px" />
                                                </div>
                                            ) : (
                                                <div>
                                                    <img src="./img/inbox/Group 1686553948.png" alt="" />
                                                </div>
                                            )} */}
                                                            </div>
                                                            <div className="">
                                                                {showPicker && (
                                                                    <div className="emoji-picker-wrapper">
                                                                        <Picker
                                                                            onEmojiSelect={handleEmojiClick}
                                                                            autoFocus={true}
                                                                            emojiSize={24}
                                                                            emojiButtonSize={36}
                                                                            emojiButtonRadius={'100%'}
                                                                            emojiButtonColors={['#f00', 'pink', 'rgba(155,223,88,.7)']}
                                                                            emojiVersion={14}
                                                                            icons={'auto'}
                                                                            locale={'en'}
                                                                            maxFrequentRows={4}
                                                                            navPosition={'top'}
                                                                            perLine={perLine}
                                                                            previewEmoji={'point_up'}
                                                                            previewPosition={'none'}
                                                                            searchPosition={'sticky'}
                                                                            set={'native'}
                                                                            skin={1}
                                                                            theme={'auto'}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InstructorInbox;
