import React, { useEffect, useState } from 'react';
import AuthenticateImage from '../User/AuthenticateImage';
import { useLocation, useNavigate } from 'react-router-dom';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const TrainerSignUpProfile2 = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [expertise, setExpertise] = useState([]);
    const [selectedexpertise, setSelectedExpertise] = useState([]);
    const location = useLocation();
    const { detail } = location.state || {};
    const [isloading, setIsLoading] = useState(false);

    const getExpertise = () => {
        let getRes = (res) => {
            setExpertise(res.data.docs);
        };
    
        callApi("GET", routes.expertise, null, setIsLoading, getRes, (error) => {
          console.log("error", error);
        });
      };
      const handleAccount = () => {
    
        let getRes = (res) => {
          if (res.statusCode == 200) {
    
            setShowModal(true);
              
            
          } else {
            toast.error(res.message, {
                  autoClose: 1500
                  })
          }
        };
        // const fileUrl = avatar;
        let body = {
          fullName: detail.fullName,
          avatar: detail.avatar,
          location: {
            type: "Point",
            coordinates: [72.343361, 31.04668],
            address: detail.location,
            // description: "Burewala"
          },
          description: detail.description,
          expertise: selectedexpertise,
        };
        console.log(body)
        callApi("POST", routes.completeProfile, body, setIsLoading, getRes, (error) => {
            console.log("error", error);
          });
    
    
      };
    const handleAccountSuccessful = (e) => {
        e.preventDefault();
        
        // Check if at least one link is provided
        const hasAtLeastOneExpertise = selectedexpertise.some(expertise => expertise.trim() !== "");
        
        if (hasAtLeastOneExpertise) {
            console.log('Expertise:', selectedexpertise);
            handleAccount();
        } else {
            alert('Please provide at least one social media link.');
            
        }
    };
    useEffect(() => {
        getExpertise();
      }, [])
      const handleExpertiseChange = (id) => {
        setSelectedExpertise((prevSelectedExpertise) => {
          if (prevSelectedExpertise.includes(id)) {
            
            return prevSelectedExpertise.filter((expertiseId) => expertiseId !== id);
          } else {
            
            return [...prevSelectedExpertise, id];
          }
        });
        
      };
    //   useEffect(() => {
    //     console.log("Selected Expertise IDs:", selectedexpertise);
    //   }, [selectedexpertise]);
    useEffect(() => {
        let timer;
        if (showModal) {
            timer = setTimeout(() => {
                setShowModal(false);
                navigate('/trainerhome');
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [showModal, navigate]);

    // const accountTypes = [
    //     'Affective Exercise',
    //     'Fitness Testing',
    //     'Flexibility',
    //     'Fitness',
    //     'Fitness Testing',
    //     'Flexibility',
    //     'Arij',
    //     'Amish',
    //     'Fitness Testing'
    // ];

    return (
        <>
        <ToastContainer/>
            <section className="overflow-hidden">
                <div className="">
                    <div className="row">
                        <div className="col-md-6 text-center text-sm-start" style={{ color: '#1D191C' }}>
                            <div className="container-fluid d-flex justify-content-center align-items-center">
                                <div className="w-100">
                                    <div className="px-xl-5 ms-xl-4 mt-4 mb-5">
                                        <img className='mb-2 img-fluid' src="./img/user/login/logo 1 (1).png" alt=""  height='28' width='66'/>
                                        <span className="fw-bold eighteenfont mx-2">Powering Your Platform</span>
                                    </div>
                                    <div className="d-flex flex-column align-items-center align-items-sm-start h-custom-2 px-xl-5 ms-xl-4" style={{ marginTop: '25%' }}>
                                        <form style={{ maxWidth: '100%', width: '100%' }}>
                                            <h4 className="fw-bold mb-2 pb-2">Select Expertise</h4>
                                            <div className='col-md-9'>
                                                <p className='text-secondary eighteenfont'>Check your email inbox for the OTP code we sent you. Please enter it below to proceed.</p>
                                            </div>
                                            <div className='container'>
                                                {expertise && expertise.map((expertise, index) => (
                                                    <div key={index} className="d-flex justify-content-between my-1">
                                                        <div>
                                                            <p className='fw-bold'>{expertise?.name}</p>
                                                        </div>
                                                        <div>
                                                            <input className="form-check-input" style={{ height: '24px', width: '24px', borderRadius: '50%' }} type="checkbox" value="" onChange={() => handleExpertiseChange(expertise._id)} id={`defaultCheck${index + 1}`} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="">
                                                <div className="text-center align-items-center">
                                                    <div className='mb-5 mt-3 mx-auto'>
                                                        <button className='btn rounded-pill px-5 py-3 shadow w-100' onClick={handleAccountSuccessful} style={{ backgroundColor: '#1D191C', color: 'white' }}>Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        {showModal && (
                                            <div className="modal show" id="paymentModal" tabIndex="-1" aria-labelledby="paymentModalLabel" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                                <div className="modal-dialog " style={{ marginTop: '5%' }}>
                                                    <div className="modal-content my-1">
                                                        <div className="modal-body text-center">
                                                            <img src="./img/courses/coursepayment/coursepaymentsuccessfull.png" alt="" height='100' width='100' />
                                                            <h4 className='my-3'><b>Account Setup Successfully!</b></h4>
                                                            <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vulputate placerat porta. Ut commodo nulla vitae eleifend sodales. Fusce ornare.</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AuthenticateImage />
                    </div>
                </div>
            </section>
        </>
    );
}

export default TrainerSignUpProfile2;
