import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { callApi } from '../../Api/ApiCaller';
import routes from '../../Api/routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { S3 } from 'aws-sdk';

const ProgressTrackingAssignments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ongoingCourses, setOngoingCourses] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const courseId = queryParams.get("courseId");
  const fileInputRef = useRef(null);

  const getCourses = () => {
    let getRes = (res) => {
      setOngoingCourses(res.courseProgress);
    };

    callApi("GET", `${routes.getOneOngoingCourses}/${courseId}`, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    if (courseId) {
      getCourses();
    }
  }, [courseId]);

  const handleBackButton = () => {
    navigate(-1);
  };

  const uploadImageOnS3 = async (file) => {
    const s3bucket = new S3({
      region: 'us-east-2',
      accessKeyId: 'AKIA3FLDYE3EDGM6EQUB',
      secretAccessKey: '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',
      signatureVersion: 'v4',
    });

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const params = {
          Bucket: 's3echobucket',
          Key: file.name,
          Body: arrayBuffer,
          ContentDisposition: `inline;filename="${file.name}"`,
          ContentType: file.type,
        };

        s3bucket.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);

      try {
        const fileUrl = await uploadImageOnS3(file);

        const requestBody = {
          assignment: fileUrl,
        };

        const response = await fetch(`${routes.uploadCourseAssignment}/${ongoingCourses?.course?._id}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken') ?? ''}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const res = await response.json();

        if (res.statusCode === 200) {
          toast.success(res.message, {
            autoClose: 1500,
          });
        } else {
          toast.error(res.message, {
            autoClose: 1500,
          });
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error('Error uploading file', {
          autoClose: 1500,
        });
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid" style={{ minHeight: '488px' }}>
        <div className='mt-5'>
          <h1 className="how-it-works-title">Progress Tracking</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto text-center my-4">
                <p className="text-subheading">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, consectetur adipiscing elit, consectetur adipiscing elit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className="col-md-9 mx-auto mb-5">
            <span className='eighteenfont fw-bold'>Course Content</span>
            {ongoingCourses?.course?.assignment && ongoingCourses?.course?.assignment.map((assignment, index) => (
              <div key={index} className="d-flex justify-content-start">
                <div className="my-3 py-2 shadow rounded-3 w-100" style={{ marginLeft: '15px' }}>
                  <div className="d-flex justify-content-between align-items-center px-3 py-1">
                    <div className="d-flex flex-start">
                      <p className="m-0"><b>Assignment {index + 1}</b></p>
                    </div>
                    <div>
                      <i className="fa-solid fa-cloud-arrow-up fa-2xl mx-2" style={{ cursor: 'pointer' }} onClick={handleFileSelect}></i>
                      <a href={assignment.assignmentUrl} download={`Assignment_${index + 1}`}>
                        <i className="fa-solid fa-cloud-arrow-down fa-2xl mx-2" style={{ cursor: 'pointer' }}></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept=".pdf,.doc,.docx,.txt"
              onChange={handleFileChange}
            />
            <div className="container">
              <div className="row mt-5 mb-5">
                <div className="col-12 col-md-4 d-flex justify-content-center mb-3">
                  <button className="btn rounded-pill px-5 py-3 shadow w-100 mx-2" onClick={handleBackButton} style={{ backgroundColor: '#1D191C', color: 'white' }}>
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgressTrackingAssignments;
